var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { Fragment, useState } from "react";
var stringToColor = function (str) {
    // Generate hash code from string
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    // Generate color code from hash code
    var color = Math.floor(Math.abs(hash) % 16777215).toString(16);
    return '#' + '0'.repeat(6 - color.length) + color;
};
function MenuAvatar(props) {
    var items = props.items, label = props.label, avatarClassName = props.avatarClassName;
    var _a = useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var open = Boolean(anchorEl);
    return (_jsxs(Fragment, { children: [_jsx(Avatar, __assign({ className: avatarClassName, style: { backgroundColor: stringToColor(label || "User") }, onClick: function (e) { return setAnchorEl(e.currentTarget); } }, { children: label ? label[0].toUpperCase() : null })), _jsx(Menu, __assign({ anchorEl: anchorEl, open: open, onClose: function () { return setAnchorEl(null); } }, { children: items.map(function (item, index) { return (_jsxs(MenuItem, __assign({ onClick: item.func }, { children: [(item === null || item === void 0 ? void 0 : item.iconSrc) && _jsx(ListItemIcon, { children: item.iconSrc }), _jsx(ListItemText, { primary: item.name })] }), index)); }) }))] }));
}
export default MenuAvatar;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography, Box, Button } from "@mui/material";
var ToReview = function (props) {
    var _a;
    var handleClickOpen = props.handleClickOpen, reviewData = props.reviewData, setExtraFormData = props.setExtraFormData;
    var filteredKeys = (_a = Object.entries(reviewData)) === null || _a === void 0 ? void 0 : _a.filter(function (_a) {
        var key = _a[0], value = _a[1];
        return (value === null || value === void 0 ? void 0 : value.length) > 0 &&
            (value === null || value === void 0 ? void 0 : value.some(function (item) { return (item === null || item === void 0 ? void 0 : item.is_reviewed) !== true; }));
    });
    console.log(filteredKeys);
    return (_jsx("div", { children: filteredKeys === null || filteredKeys === void 0 ? void 0 : filteredKeys.map(function (_a) {
            var key = _a[0], value = _a[1];
            return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: " flex justify-between" }, { children: _jsxs("section", __assign({ className: " flex flex-col" }, { children: [_jsxs(Typography, __assign({ className: "text-lg" }, { children: ["Order ", key, " "] })), _jsx(Typography, __assign({ className: "text-sm text-violet-500 " }, { children: "Delivered" }))] })) })), _jsx("div", { children: _jsx(Box, __assign({ sx: {
                                width: "100%",
                                bgcolor: "#EBE9E9",
                                marginTop: "1rem",
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.25rem",
                                marginBottom: "1rem",
                                borderRadius: "0.375rem",
                            } }, { children: value === null || value === void 0 ? void 0 : value.map(function (item, index) {
                                var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                                return (item === null || item === void 0 ? void 0 : item.is_reviewed) !== true && (_jsxs("section", __assign({ className: "flex flex-col md:flex-row  justify-between" }, { children: [_jsx(Typography, __assign({ variant: "subtitle1", className: " font-medium py-2 px-2" }, { children: "".concat((_a = item === null || item === void 0 ? void 0 : item.product) === null || _a === void 0 ? void 0 : _a.name, " (").concat((_c = (_b = item === null || item === void 0 ? void 0 : item.product) === null || _b === void 0 ? void 0 : _b.price) === null || _c === void 0 ? void 0 : _c.currency, " ").concat((_e = (_d = item === null || item === void 0 ? void 0 : item.product) === null || _d === void 0 ? void 0 : _d.price) === null || _e === void 0 ? void 0 : _e.value, " x ").concat(item === null || item === void 0 ? void 0 : item.quantity, ") = ").concat((_g = (_f = item === null || item === void 0 ? void 0 : item.product) === null || _f === void 0 ? void 0 : _f.price) === null || _g === void 0 ? void 0 : _g.currency, " ").concat(((_j = (_h = item === null || item === void 0 ? void 0 : item.product) === null || _h === void 0 ? void 0 : _h.price) === null || _j === void 0 ? void 0 : _j.value) * (item === null || item === void 0 ? void 0 : item.quantity)) })), _jsx(Button, __assign({ variant: "outlined", className: " mt-2 mb-4 mx-2", onClick: function () {
                                                var _a;
                                                setExtraFormData({
                                                    order_id: item === null || item === void 0 ? void 0 : item.orderId,
                                                    product_id: (_a = item === null || item === void 0 ? void 0 : item.product) === null || _a === void 0 ? void 0 : _a.id,
                                                });
                                                handleClickOpen();
                                            } }, { children: "Give Review" }))] })));
                            }) })) })] }));
        }) }));
};
export default ToReview;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { refreahAccessToken } from '../../../../../ApiGateways/user';
import { CircularProgress, Typography } from '@mui/material';
import { setIsALoggedIn } from '../../../../../Redux/features/singleStateSlice';
import { useDispatch, useSelector } from 'react-redux';
var SocialLogin = function () {
    var slug = useParams().slug;
    var user = useSelector(function (state) { return state.userState; });
    var dispatch = useDispatch();
    var _a = useState(false), token = _a[0], setToken = _a[1];
    useEffect(function () {
        var decodedToken = atob(String(slug));
        console.log(decodedToken);
        localStorage.setItem('access-token', decodedToken);
        localStorage.setItem('refresh-token', decodedToken);
        refreahAccessToken(function (data) {
            var _a;
            localStorage.setItem('refresh-token', (_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.refresh_token);
            dispatch(setIsALoggedIn(true));
            setToken(true);
        }, function (res) { return console.log(res); });
    }, [slug]);
    return (_jsx("div", __assign({ className: " pt-5 pb-[25%] px-4 text-gray-900 text-center text-lg" }, { children: token ?
            _jsx(_Fragment, { children: _jsx(Navigate, { to: localStorage.getItem("social_redirect") || '/user_profile' }) })
            :
                _jsxs(_Fragment, { children: [_jsx(Typography, __assign({ className: 'text-2xl text-blue-400 mb-2' }, { children: "Please Wait ...." })), _jsx(CircularProgress, {})] }) })));
};
export default SocialLogin;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Autocomplete, InputAdornment, Button, Menu, MenuItem, Typography, createFilterOptions, Dialog, DialogContent, DialogTitle, } from "@mui/material";
import { useEffect, useState } from "react";
import StyledTextField from "../../../../Components/StyledTextField";
import FileUploader from "../../../../Components/FileUploader";
import { insertProduct, updateProductInfo, } from "../../../../ApiGateways/product";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
function ProductForm(props) {
    var _a;
    var open = props.open, onClose = props.onClose, updateProduct = props.updateProduct, createProduct = props.createProduct, units = props.units, subunits = props.subunits, categories = props.categories, filters = props.filters, sx = props.sx, contentSx = props.contentSx, formData = props.formData, setFormData = props.setFormData, initialFormState = props.initialFormState, reset = props.reset, setReset = props.setReset;
    var filter = createFilterOptions();
    var _b = useState([]), images = _b[0], setImages = _b[1];
    var _c = useState([]), videos = _c[0], setVideos = _c[1];
    var _d = useState(""), newAttributeName = _d[0], setNewAttributeName = _d[1];
    var _e = useState(""), newDescAttribute = _e[0], setNewDescAttribute = _e[1];
    var _f = useState(null), priceAnchorEl = _f[0], setPriceAnchorEl = _f[1];
    var _g = useState(null), currentInventoryAnchorEl = _g[0], setCurrentInventoryAnchorEl = _g[1];
    var _h = useState(null), restockAlertAnchorEl = _h[0], setRestockAlertAnchorEl = _h[1];
    var priceOpen = Boolean(priceAnchorEl);
    var currentInventoryOpen = Boolean(currentInventoryAnchorEl);
    var restockAlertOpen = Boolean(restockAlertAnchorEl);
    var toolbarOptions = [
        ["bold", "italic", "underline", "strike"],
        ["blockquote", "code-block"],
        [{ header: 1 }, { header: 2 }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ direction: "rtl" }],
        [{ size: ["small", false, "large", "huge"] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ align: [] }],
        ["link", "image"],
        ["clean"],
    ];
    var quillModule = {
        toolbar: toolbarOptions,
    };
    useEffect(function () {
        if (Object.keys(formData === null || formData === void 0 ? void 0 : formData.filters).length < 1 || Object.keys(formData === null || formData === void 0 ? void 0 : formData.description).length < 1) {
            setFormData(function (prevFormData) {
                var _a;
                return (__assign(__assign({}, prevFormData), { filters: __assign(__assign({}, prevFormData.filters), (_a = {}, _a[''] = [], _a)), description: __assign(__assign({}, prevFormData.description), { "": "" }) }));
            });
        }
    }, [formData === null || formData === void 0 ? void 0 : formData.filters, formData === null || formData === void 0 ? void 0 : formData.description]);
    // console.log(JSON.stringify(formData?.description));
    var sanitizeDescriptionValues = function (descriptionObject) {
        var sanitizedDescription = {};
        for (var _i = 0, _a = Object.entries(descriptionObject); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            var sanitizedValue = value.replace(/(<p><br><\/p>\s*)+$/, "");
            sanitizedDescription[key] = sanitizedValue;
        }
        return sanitizedDescription;
    };
    // console.log(Object.keys(filters));
    var handleAttributeName = function (inputValue, attrName) {
        var _a;
        var filters = formData.filters;
        if (inputValue) {
            if (filters.hasOwnProperty(attrName)) {
                var newFilters = Object.fromEntries(Object.entries(filters).map(function (_a) {
                    var key = _a[0], value = _a[1];
                    return key === attrName ? [inputValue, value] : [key, value];
                }));
                setFormData(__assign(__assign({}, formData), { filters: newFilters }));
                setNewAttributeName(inputValue);
            }
            else {
                setFormData(__assign(__assign({}, formData), { filters: __assign(__assign({}, filters), (_a = {}, _a[inputValue] = [], _a)) }));
                setNewAttributeName(inputValue);
            }
        }
        else {
            setFormData(function (prevFormData) {
                var updatedFilters = __assign({}, prevFormData.filters);
                delete updatedFilters[newAttributeName];
                return __assign(__assign({}, prevFormData), { filters: updatedFilters });
            });
        }
    };
    var handleDescAttribute = function (event, attrName) {
        var _a;
        var description = formData.description;
        if (description.hasOwnProperty(attrName)) {
            var newDescription = Object.fromEntries(Object.entries(description).map(function (_a) {
                var key = _a[0], value = _a[1];
                return key === attrName ? [event.target.value, value] : [key, value];
            }));
            setFormData(__assign(__assign({}, formData), { description: newDescription }));
            setNewDescAttribute(event.target.value);
        }
        else {
            setFormData(__assign(__assign({}, formData), { description: __assign(__assign({}, description), (_a = {}, _a[event.target.value] = "", _a)) }));
            setNewDescAttribute(event.target.value);
        }
    };
    var handleSubmitProduct = function (event) {
        event.preventDefault();
        var submitImages = __spreadArray([], images, true);
        var submitVideos = __spreadArray([], videos, true);
        var _a = formData.filters, removedKey = _a[""], updatedFilters = __rest(_a, [""]), formWithoutEmptyKey = __rest(formData, ["filters"]);
        var _b = formWithoutEmptyKey.description, removedDescKey = _b[""], updatedDescription = __rest(_b, [""]), descFormWithoutEmptyKey = __rest(formWithoutEmptyKey, ["description"]);
        var sanitizedDescription = sanitizeDescriptionValues(updatedDescription);
        var updatedFormData = __assign(__assign({}, descFormWithoutEmptyKey), { filters: updatedFilters, description: sanitizedDescription });
        var id = updatedFormData.id, product_id = updatedFormData.product_id, form = __rest(updatedFormData, ["id", "product_id"]);
        var submitFormData = new FormData();
        submitFormData.append("form", JSON.stringify(form));
        submitImages.map(function (image, idx) { return submitFormData.append("images", image); });
        submitVideos.map(function (video, idx) { return submitFormData.append("videos", video); });
        // console.log(JSON.stringify(updatedFormData));
        if (formData === null || formData === void 0 ? void 0 : formData.id) {
            updateProductInfo(formData === null || formData === void 0 ? void 0 : formData.id, submitFormData, function (data) {
                console.log(data);
                if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                    updateProduct(data.data);
                    formClose();
                }
            }, function (res) { return console.log(res); });
        }
        else {
            insertProduct(submitFormData, function (data) {
                createProduct(data.data);
                formClose();
            }, function (res) { return console.log(res); });
        }
    };
    var formClose = function () {
        setFormData(initialFormState);
        setImages([]);
        setVideos([]);
        setReset(!reset);
        onClose();
    };
    var noNegative = function (value) {
        if (value > 0)
            return Number(value);
        else
            return 0;
    };
    return (_jsxs(Dialog, __assign({ open: open, onClose: formClose, sx: sx }, { children: [_jsx(DialogTitle, { children: _jsx(Typography, __assign({ variant: "h4" }, { children: (formData === null || formData === void 0 ? void 0 : formData.id) ? "Update Product" : "Insert Product" })) }), _jsx(DialogContent, __assign({ sx: contentSx }, { children: _jsxs("form", __assign({ onSubmit: handleSubmitProduct }, { children: [_jsx(StyledTextField, { value: formData.name, onChange: function (event) {
                                return setFormData(__assign(__assign({}, formData), { name: event.target.value }));
                            }, required: true, fullWidth: true, label: "Title", sx: { margin: "0.5rem 0" } }), _jsxs("section", __assign({ style: { display: "flex", gap: "0 1rem", alignItems: "center" } }, { children: [_jsx(Autocomplete, { options: __spreadArray([], units, true), value: formData.unit, onChange: function (event, newValue) {
                                        return setFormData(__assign(__assign({}, formData), { unit: newValue ? newValue : "pc" }));
                                    }, onInputChange: function (event, newValue) {
                                        return setFormData(__assign(__assign({}, formData), { unit: newValue ? newValue : "pc" }));
                                    }, freeSolo: true, fullWidth: true, selectOnFocus: true, clearOnBlur: true, handleHomeEndKeys: true, filterOptions: function (options, params) {
                                        var filtered = filter(options, params);
                                        var inputValue = params.inputValue;
                                        var isExisting = options.some(function (option) { return inputValue === option; });
                                        if (inputValue !== "" && !isExisting) {
                                            filtered.push(inputValue);
                                        }
                                        return filtered;
                                    }, renderInput: function (params) { return (_jsx(StyledTextField, __assign({}, params, { required: true, label: "Unit", sx: { margin: "0.5rem 0" } }))); } }), _jsx(StyledTextField, { value: formData.multiplier || 0, onChange: function (event) {
                                        return setFormData(__assign(__assign({}, formData), { multiplier: noNegative(Number(event.target.value)) }));
                                    }, required: true, fullWidth: true, type: "number", label: "Multiplier", sx: { margin: "0.5rem 0" } }), _jsx(Autocomplete, { options: __spreadArray([], subunits, true), value: formData.subunit, onChange: function (event, newValue) {
                                        return setFormData(__assign(__assign({}, formData), { subunit: newValue ? newValue : "pc" }));
                                    }, onInputChange: function (event, newValue) {
                                        return setFormData(__assign(__assign({}, formData), { subunit: newValue ? newValue : "pc" }));
                                    }, freeSolo: true, fullWidth: true, selectOnFocus: true, clearOnBlur: true, handleHomeEndKeys: true, filterOptions: function (options, params) {
                                        var filtered = filter(options, params);
                                        var inputValue = params.inputValue;
                                        var isExisting = options.some(function (option) { return inputValue === option; });
                                        if (inputValue !== "" && !isExisting) {
                                            filtered.push(inputValue);
                                        }
                                        return filtered;
                                    }, renderInput: function (params) { return (_jsx(StyledTextField, __assign({}, params, { required: true, label: "Sub Unit", sx: { margin: "0.5rem 0" } }))); } })] })), _jsxs("section", __assign({ style: { display: "flex", gap: "0 1rem", alignItems: "center" } }, { children: [_jsx(StyledTextField, { value: formData.price.value, onChange: function (event) {
                                        return setFormData(__assign(__assign({}, formData), { price: __assign(__assign({}, formData.price), { value: noNegative(Number(event.target.value)) }) }));
                                    }, required: true, fullWidth: true, label: "Price", sx: { margin: "0.5rem 0" }, type: "number", InputProps: {
                                        startAdornment: (_jsx(InputAdornment, __assign({ position: "start" }, { children: formData.price.currency }))),
                                        endAdornment: (_jsxs(InputAdornment, __assign({ position: "end" }, { children: [_jsx(Button, __assign({ onClick: function (event) {
                                                        return setPriceAnchorEl(event.currentTarget);
                                                    } }, { children: formData.price.unit })), _jsxs(Menu, __assign({ open: priceOpen, anchorEl: priceAnchorEl, onClose: function () { return setPriceAnchorEl(null); } }, { children: [_jsx(MenuItem, __assign({ onClick: function (event) {
                                                                return setFormData(__assign(__assign({}, formData), { price: __assign(__assign({}, formData.price), { unit: formData.unit }) }));
                                                            } }, { children: formData.unit })), _jsx(MenuItem, __assign({ onClick: function (event) {
                                                                return setFormData(__assign(__assign({}, formData), { price: __assign(__assign({}, formData.price), { unit: formData.subunit }) }));
                                                            } }, { children: formData.subunit }))] }))] }))),
                                    } }), _jsx(StyledTextField, { value: formData.quantity.value, onChange: function (event) {
                                        return setFormData(__assign(__assign({}, formData), { quantity: __assign(__assign({}, formData.quantity), { value: noNegative(Number(event.target.value)) }) }));
                                    }, required: true, fullWidth: true, label: "Current Inventory", sx: { margin: "0.5rem 0" }, type: "number", InputProps: {
                                        endAdornment: (_jsxs(InputAdornment, __assign({ position: "end" }, { children: [_jsx(Button, __assign({ onClick: function (event) {
                                                        return setCurrentInventoryAnchorEl(event.currentTarget);
                                                    } }, { children: formData.quantity.unit })), _jsxs(Menu, __assign({ open: currentInventoryOpen, anchorEl: currentInventoryAnchorEl, onClose: function () { return setCurrentInventoryAnchorEl(null); } }, { children: [_jsx(MenuItem, __assign({ onClick: function (event) {
                                                                return setFormData(__assign(__assign({}, formData), { quantity: __assign(__assign({}, formData.quantity), { unit: formData.unit }) }));
                                                            } }, { children: formData.unit })), _jsx(MenuItem, __assign({ onClick: function (event) {
                                                                return setFormData(__assign(__assign({}, formData), { quantity: __assign(__assign({}, formData.quantity), { unit: formData.subunit }) }));
                                                            } }, { children: formData.subunit }))] }))] }))),
                                    } }), _jsx(StyledTextField, { value: formData.restock_alert.value, onChange: function (event) {
                                        return setFormData(__assign(__assign({}, formData), { restock_alert: __assign(__assign({}, formData.restock_alert), { value: noNegative(Number(event.target.value)) }) }));
                                    }, required: true, fullWidth: true, label: "Restock Alert", sx: { margin: "0.5rem 0" }, type: "number", InputProps: {
                                        endAdornment: (_jsxs(InputAdornment, __assign({ position: "end" }, { children: [_jsx(Button, __assign({ onClick: function (event) {
                                                        return setRestockAlertAnchorEl(event.currentTarget);
                                                    } }, { children: formData.restock_alert.unit })), _jsxs(Menu, __assign({ open: restockAlertOpen, anchorEl: restockAlertAnchorEl, onClose: function () { return setRestockAlertAnchorEl(null); } }, { children: [_jsx(MenuItem, __assign({ onClick: function (event) {
                                                                return setFormData(__assign(__assign({}, formData), { restock_alert: __assign(__assign({}, formData.restock_alert), { unit: formData.unit }) }));
                                                            } }, { children: formData.unit })), _jsx(MenuItem, __assign({ onClick: function (event) {
                                                                return setFormData(__assign(__assign({}, formData), { restock_alert: __assign(__assign({}, formData.restock_alert), { unit: formData.subunit }) }));
                                                            } }, { children: formData.subunit }))] }))] }))),
                                    } })] })), _jsx(Autocomplete, { options: __spreadArray([], categories, true), value: formData.categories, onChange: function (event, newValue) {
                                return setFormData(__assign(__assign({}, formData), { categories: __spreadArray([], newValue, true) }));
                            }, freeSolo: true, fullWidth: true, selectOnFocus: true, clearOnBlur: true, handleHomeEndKeys: true, multiple: true, getOptionLabel: function (option) { return option; }, filterOptions: function (options, params) {
                                var filtered = filter(options, params);
                                var inputValue = params.inputValue;
                                var isExisting = options.some(function (option) { return inputValue === option; });
                                if (inputValue !== "" && !isExisting) {
                                    filtered.push(inputValue);
                                }
                                return filtered;
                            }, renderInput: function (params) { return (_jsx(StyledTextField, __assign({}, params, { label: "Categories", fullWidth: true, sx: { margin: "0.5rem 0" } }))); } }), _jsx(StyledTextField, { value: formData.summary, onChange: function (event) {
                                return setFormData(__assign(__assign({}, formData), { summary: event.target.value }));
                            }, required: true, fullWidth: true, multiline: true, rows: 2, label: "Summary", sx: { margin: "0.5rem 0" } }), _jsxs("section", __assign({ style: {
                                display: "flex",
                                gap: "0 1rem",
                                alignItems: "center",
                                marginTop: "2rem",
                                marginBottom: "1rem",
                            } }, { children: [_jsx(Typography, __assign({ variant: "h6" }, { children: "Section" })), _jsx(Button, __assign({ variant: "outlined", onClick: function () { return [
                                        setFormData(__assign(__assign({}, formData), { description: __assign(__assign({}, formData.description), { "": "" }) })),
                                        setNewDescAttribute(""),
                                    ]; } }, { children: "Add New" }))] })), _jsx("div", { children: Object.keys(formData === null || formData === void 0 ? void 0 : formData.description).length > 0 &&
                                ((_a = Object.entries(formData === null || formData === void 0 ? void 0 : formData.description)) === null || _a === void 0 ? void 0 : _a.map(function (_a, index) {
                                    var key = _a[0], value = _a[1];
                                    return (_jsxs("section", __assign({ style: { alignItems: "center", marginBottom: "10px" } }, { children: [_jsx(StyledTextField, { value: key, onChange: function (event) { return handleDescAttribute(event, key); }, fullWidth: true, label: "Section Label", sx: { margin: "0.5rem 0" } }), _jsx(ReactQuill, { modules: quillModule, theme: "snow", value: value, onChange: function (value) {
                                                    var _a;
                                                    return setFormData(__assign(__assign({}, formData), { description: __assign(__assign({}, formData.description), (_a = {}, _a[key] = value, _a)) }));
                                                }, style: { minWidth: "20rem" } })] }), index));
                                })) }), _jsx(FileUploader, { style: {
                                display: "flex",
                                gap: "0 1rem",
                                alignItems: "center",
                                margin: "0.5rem 0",
                            }, fileType: "images", names: formData.images, removeName: function (name) {
                                return setFormData(__assign(__assign({}, formData), { images: formData.images.filter(function (image) { return image !== name; }) }));
                            }, onFileSelect: function (files) { return setImages(files); } }), _jsx(FileUploader, { style: {
                                display: "flex",
                                gap: "0 1rem",
                                alignItems: "center",
                                margin: "0.5rem 0",
                            }, fileType: "videos", names: formData.videos, removeName: function (name) {
                                return setFormData(__assign(__assign({}, formData), { videos: formData.videos.filter(function (video) { return video !== name; }) }));
                            }, onFileSelect: function (files) { return setVideos(files); } }), _jsxs("section", __assign({ style: {
                                display: "flex",
                                gap: "0 1rem",
                                alignItems: "center",
                                marginTop: "2rem",
                                marginBottom: "1rem",
                            } }, { children: [_jsx(Typography, __assign({ variant: "h6" }, { children: "Attributes" })), _jsx(Button, __assign({ variant: "outlined", onClick: function () { return [
                                        setFormData(__assign(__assign({}, formData), { filters: __assign(__assign({}, formData.filters), { "": [] }) })),
                                        setNewAttributeName(""),
                                    ]; } }, { children: "Add New" }))] })), _jsx("div", { children: Object.keys(formData.filters).length > 0 &&
                                Object.entries(formData.filters).map(function (_a, index) {
                                    var key = _a[0], value = _a[1];
                                    return (_jsxs("section", __assign({ style: {
                                            display: "flex",
                                            gap: "0 1rem",
                                            alignItems: "center",
                                            margin: "1rem 0",
                                        } }, { children: [_jsx(Autocomplete, { options: __spreadArray([], Object.keys(filters), true), getOptionLabel: function (option) { return option; }, value: key, onChange: function (event, newValue) {
                                                    handleAttributeName(newValue, key);
                                                }, onInputChange: function (event, newValue) {
                                                    handleAttributeName(newValue, key);
                                                }, onMouseEnter: function (event) {
                                                    setNewAttributeName(key);
                                                }, filterOptions: function (options, params) {
                                                    var filtered = filter(options, params);
                                                    var inputValue = params.inputValue;
                                                    var isExisting = options.some(function (option) { return inputValue === option; });
                                                    if (inputValue !== "" && !isExisting) {
                                                        filtered.push(inputValue);
                                                    }
                                                    return filtered;
                                                }, freeSolo: true, selectOnFocus: true, clearOnBlur: true, handleHomeEndKeys: true, sx: { width: "100%" }, renderInput: function (params) { return (_jsx(StyledTextField, __assign({}, params, { label: "Attribute Name", sx: { margin: "0.5rem 0" } }))); } }), _jsx(Autocomplete, { value: value, options: filters[key] || [], onChange: function (event, newValue) {
                                                    var _a;
                                                    return setFormData(__assign(__assign({}, formData), { filters: __assign(__assign({}, formData.filters), (_a = {}, _a[key] = __spreadArray([], newValue, true), _a)) }));
                                                }, freeSolo: true, fullWidth: true, selectOnFocus: true, clearOnBlur: true, handleHomeEndKeys: true, multiple: true, getOptionLabel: function (option) { return option; }, filterOptions: function (options, params) {
                                                    var filtered = filter(options, params);
                                                    var inputValue = params.inputValue;
                                                    var isExisting = options.some(function (option) { return inputValue === option; });
                                                    if (inputValue !== "" && !isExisting) {
                                                        filtered.push(inputValue);
                                                    }
                                                    return filtered;
                                                }, renderInput: function (params) { return (_jsx(StyledTextField, __assign({}, params, { fullWidth: true, label: "Attribute Value", sx: { margin: "0.5rem 0" } }))); } })] }), index));
                                }) }), _jsxs("section", __assign({ style: {
                                display: "flex",
                                gap: "0 1rem",
                                alignItems: "center",
                                width: "100%",
                                justifyContent: "space-between",
                            } }, { children: [_jsxs("article", __assign({ style: { display: "flex", gap: "0 1rem", alignItems: "center" } }, { children: [_jsxs(Button, __assign({ type: "submit", variant: "contained", color: "info" }, { children: [(formData === null || formData === void 0 ? void 0 : formData.id) ? "Update Product" : "Save Product", " "] })), _jsx(Button, __assign({ variant: "outlined", color: "info", type: "reset" }, { children: "Clear" }))] })), _jsx(Button, __assign({ variant: "contained", onClick: formClose }, { children: "Close" }))] }))] })) }))] })));
}
export default ProductForm;

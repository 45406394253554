var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { countries } from "../../Data/country";
import { Autocomplete, Box, Typography, InputAdornment } from "@mui/material";
import StyledTextField from "../StyledTextField";
import { validateAll, validateAllAllowSpace, } from "../../Utils";
import "./index.scss";
export var NewAddressContext = React.createContext({
    shipping: {
        addressLine1: "",
        city: "",
        country: {
            code: "",
            label: "",
            phone: "",
        },
        landmark: "",
        postcode: "",
        state: "",
        suburb: "",
    },
    setShipping: function (address) { },
});
var NewAddress = function () {
    var _a = React.useContext(NewAddressContext), shipping = _a.shipping, setShipping = _a.setShipping;
    var _b = useState("bd"), countryCode = _b[0], setCountryCode = _b[1];
    useEffect(function () {
        var country = countries.find(function (country) { return country.code.toLowerCase() === countryCode; });
        if (country) {
            setShipping(__assign(__assign({}, shipping), { country: country }));
            setCountryCode(country.code.toLowerCase());
        }
    }, [countryCode]);
    return (_jsxs("div", __assign({ className: "new-address__parent" }, { children: [_jsx(StyledTextField, { value: shipping.addressLine1, required: true, fullWidth: true, label: "Address Line 1", placeholder: "House, Apartment or Building Number", InputLabelProps: {
                    shrink: true,
                }, onChange: function (e) {
                    return setShipping(__assign(__assign({}, shipping), { addressLine1: validateAllAllowSpace(e.target.value) }));
                }, onBlur: function (e) {
                    return setShipping(__assign(__assign({}, shipping), { addressLine1: validateAll(e.target.value) }));
                } }), _jsx(StyledTextField, { value: shipping.addressLine2, fullWidth: true, label: "Address Line 2", placeholder: "Street or Road Details", InputLabelProps: {
                    shrink: true,
                }, onChange: function (e) {
                    return setShipping(__assign(__assign({}, shipping), { addressLine2: validateAllAllowSpace(e.target.value) }));
                }, onBlur: function (e) {
                    return setShipping(__assign(__assign({}, shipping), { addressLine2: validateAll(e.target.value) }));
                } }), _jsxs("section", __assign({ className: "new-address__compoenets" }, { children: [_jsx(Autocomplete, { fullWidth: true, options: countries, getOptionLabel: function (option) { return option.label; }, value: shipping.country, onChange: function (_, value) {
                            setShipping(__assign(__assign({}, shipping), { country: value }));
                            setCountryCode(value === null || value === void 0 ? void 0 : value.code.toLowerCase());
                        }, renderOption: function (props, option) { return (_jsxs(Box, __assign({ component: "li", sx: { "& > img": { mr: 2, flexShrink: 0 } } }, props, { children: [_jsx("img", { loading: "lazy", width: "20", src: "https://flagcdn.com/w20/".concat(option.code.toLowerCase(), ".png"), srcSet: "https://flagcdn.com/w40/".concat(option.code.toLowerCase(), ".png 2x"), alt: "" }), _jsx(Typography, { children: option.label })] }))); }, renderInput: function (params) { return (_jsx(StyledTextField, __assign({ required: true }, params, { label: "Country", InputProps: __assign(__assign({}, params.InputProps), { startAdornment: (_jsx(InputAdornment, __assign({ position: "start" }, { children: _jsx("img", { loading: "lazy", width: "30", src: "https://flagcdn.com/w20/".concat(countryCode, ".png"), srcSet: "https://flagcdn.com/w40/".concat(countryCode, ".png 2x"), alt: "", style: { paddingLeft: "0.5rem" } }) }))), classes: {
                                    notchedOutline: "New_Address__rounded__field",
                                } }) }))); } }), _jsx(StyledTextField, { value: shipping.landmark, fullWidth: true, label: "Landmark", placeholder: "E.g. beside train station", onChange: function (e) {
                            return setShipping(__assign(__assign({}, shipping), { landmark: validateAllAllowSpace(e.target.value) }));
                        }, onBlur: function (e) {
                            return setShipping(__assign(__assign({}, shipping), { landmark: validateAll(e.target.value) }));
                        }, InputLabelProps: {
                            shrink: true,
                        } })] })), _jsxs("section", __assign({ className: 'new-address__compoenets' }, { children: [_jsx(StyledTextField, { value: shipping.state, label: "Division", required: true, fullWidth: true, onChange: function (e) { return setShipping(__assign(__assign({}, shipping), { state: e.target.value })); } }), _jsx(StyledTextField, { value: shipping.city, required: true, fullWidth: true, label: "Disrict", onChange: function (e) { return setShipping(__assign(__assign({}, shipping), { city: e.target.value })); } })] })), _jsxs("section", __assign({ className: 'new-address__compoenets' }, { children: [_jsx(StyledTextField, { value: shipping.suburb, fullWidth: true, required: true, label: "Thana", onChange: function (e) { return setShipping(__assign(__assign({}, shipping), { suburb: e.target.value })); } }), _jsx(StyledTextField, { value: shipping.postcode, fullWidth: true, type: "number", label: "Postcode", onChange: function (e) { return setShipping(__assign(__assign({}, shipping), { postcode: e.target.value })); } })] }))] })));
};
export default NewAddress;

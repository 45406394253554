var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { checkUser } from '../../../ApiGateways/user';
import StyledTextField from '../../../Components/StyledTextField';
import { FaEye, FaEyeSlash } from 'react-icons/fa6';
import { resetPassword } from '../../../ApiGateways/mail_sending';
import GlobalSnackbar from '../../../Components/GlobalSnackbar';
var PasswordReset = function () {
    var token = useParams().token;
    var _a = useState(false), isValidUser = _a[0], setIsValidUser = _a[1];
    var _b = useState(false), reset = _b[0], setReset = _b[1];
    var _c = useState(''), errMsg = _c[0], setErrMsg = _c[1];
    var _d = useState({
        isActive: false,
        verticalPosition: "top",
        horizontalPosition: "center",
        message: "",
        alertType: "success",
    }), snackbarState = _d[0], setSnackbarState = _d[1];
    var _e = useState({
        newPass: "password",
        confirmPass: "password",
    }), passwordState = _e[0], setPasswordState = _e[1];
    var _f = useState({
        newPass: "",
        confirmPass: "",
    }), pass = _f[0], setPass = _f[1];
    var decodeToken = function (acces_token) {
        var modified_token = atob(String(token));
        return modified_token;
    };
    useEffect(function () {
        var obtained_token = decodeToken(String(token));
        checkUser(obtained_token, function (data) {
            if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                localStorage.setItem('access-token', obtained_token);
                setIsValidUser(true);
            }
        }, function (res) { return console.log(res); });
    }, [token]);
    var passwordReset = function (event) {
        event.preventDefault();
        setErrMsg('');
        if ((pass === null || pass === void 0 ? void 0 : pass.newPass) !== (pass === null || pass === void 0 ? void 0 : pass.confirmPass)) {
            setErrMsg("Password Not Matched");
        }
        else if ((pass === null || pass === void 0 ? void 0 : pass.confirmPass.length) < 8) {
            setErrMsg("Password must contain atleast 8 characters");
        }
        else {
            var body = {
                password: pass === null || pass === void 0 ? void 0 : pass.confirmPass
            };
            resetPassword(JSON.stringify(body), function (data) {
                if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                    setSnackbarState({
                        isActive: true,
                        verticalPosition: "top",
                        horizontalPosition: "center",
                        message: "Password Reset Successful.\n You'll be redirected to Login page shortly !",
                        alertType: "success"
                    });
                    setTimeout(function () {
                        setReset(true);
                    }, 2000);
                }
            }, function (res) { return console.log(res); });
        }
    };
    return (_jsxs("div", __assign({ className: 'grid text-center bg-rose-100 py-14 pb-8 px-8 shadow-lg rounded-md md:my-[10%] md:mx-[25%]' }, { children: [(snackbarState === null || snackbarState === void 0 ? void 0 : snackbarState.isActive) && (_jsx(GlobalSnackbar, { verticalPosition: snackbarState === null || snackbarState === void 0 ? void 0 : snackbarState.verticalPosition, horizontalPosition: snackbarState === null || snackbarState === void 0 ? void 0 : snackbarState.horizontalPosition, message: snackbarState === null || snackbarState === void 0 ? void 0 : snackbarState.message, alertType: snackbarState === null || snackbarState === void 0 ? void 0 : snackbarState.alertType, onfinish: function () {
                    setSnackbarState(__assign(__assign({}, snackbarState), { isActive: false }));
                } })), isValidUser && !reset ?
                _jsx("form", __assign({ onSubmit: passwordReset }, { children: _jsxs("section", { children: [_jsx(StyledTextField, { type: passwordState === null || passwordState === void 0 ? void 0 : passwordState.newPass, fullWidth: true, required: true, value: pass === null || pass === void 0 ? void 0 : pass.newPass, borderRadius: '10px', label: "New Password", className: 'mb-3', onChange: function (e) { return setPass(__assign(__assign({}, pass), { newPass: e.target.value.replace(/\s/g, '') })); }, InputProps: {
                                    endAdornment: (_jsx(IconButton, { children: passwordState.newPass === 'password' ?
                                            _jsx(Typography, __assign({ onClick: function () { return setPasswordState(__assign(__assign({}, passwordState), { newPass: "text" })); } }, { children: _jsx(FaEyeSlash, {}) }))
                                            :
                                                _jsx(Typography, __assign({ onClick: function () { return setPasswordState(__assign(__assign({}, passwordState), { newPass: "password" })); } }, { children: _jsx(FaEye, {}) })) })),
                                } }), _jsx(StyledTextField, { type: passwordState === null || passwordState === void 0 ? void 0 : passwordState.confirmPass, fullWidth: true, required: true, value: pass === null || pass === void 0 ? void 0 : pass.confirmPass, borderRadius: '10px', label: "Confirm New Password", onChange: function (e) { return setPass(__assign(__assign({}, pass), { confirmPass: e.target.value.replace(/\s/g, '') })); }, InputProps: {
                                    endAdornment: (_jsx(IconButton, { children: passwordState.confirmPass === 'password' ?
                                            _jsx(Typography, __assign({ onClick: function () { return setPasswordState(__assign(__assign({}, passwordState), { confirmPass: "text" })); } }, { children: _jsx(FaEyeSlash, {}) }))
                                            :
                                                _jsx(Typography, __assign({ onClick: function () { return setPasswordState(__assign(__assign({}, passwordState), { confirmPass: "password" })); } }, { children: _jsx(FaEye, {}) })) })),
                                } }), errMsg !== '' &&
                                _jsx(Typography, __assign({ className: ' text-red-500 mt-2 text-start' }, { children: errMsg })), _jsx(Button, __assign({ type: "submit", className: "bg-rose-900 text-lg my-4", variant: "contained", size: 'large' }, { children: "Reset Password" }))] }) }))
                :
                    _jsxs(_Fragment, { children: [reset &&
                                _jsx(Navigate, { to: '/signin' }), !reset && !isValidUser &&
                                _jsxs("div", { children: [_jsx(Typography, __assign({ className: 'text-[4rem] font-bold' }, { children: "404" })), _jsx(Typography, __assign({ className: 'tex-3xl font-semibold' }, { children: "Page Not Found" }))] })] })] })));
};
export default PasswordReset;

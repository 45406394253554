var _a;
import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    isCategory: false,
    isLoggedIn: false,
    isAdminLoggedIn: false
};
export var singleStateSlice = createSlice({
    name: 'categoryState',
    initialState: initialState,
    reducers: {
        setIsCategory: function (state) {
            state.isCategory = !state.isCategory;
        },
        setIsALoggedIn: function (state, action) {
            state.isLoggedIn = action.payload;
        },
        setIsAdminLoggedIn: function (state, action) {
            state.isAdminLoggedIn = action.payload;
        },
    }
});
export var setIsCategory = (_a = singleStateSlice.actions, _a.setIsCategory), setIsALoggedIn = _a.setIsALoggedIn, setIsAdminLoggedIn = _a.setIsAdminLoggedIn;
export default singleStateSlice.reducer;

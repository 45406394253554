import { createSlice } from "@reduxjs/toolkit";
var initialState = [];
var productsSlice = createSlice({
    name: "products",
    initialState: initialState,
    reducers: {
        setProducts: function (state, action) {
            return action.payload;
        }
    },
});
export var setProducts = productsSlice.actions.setProducts;
export default productsSlice.reducer;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StyledTextField from '../../../Components/StyledTextField';
import { InputAdornment, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Context } from "../../../State/Provider";
var AdminGlobalSearch = function (props) {
    var _a;
    var path = props.path;
    var navigate = useNavigate();
    var _b = useContext(Context), searchStr = _b.searchStr, setSearchStr = _b.setSearchStr;
    var _c = useState(''), searchOption = _c[0], setSearchOption = _c[1];
    var placeHolderText = {
        product: "title and category", customer: "name, email, phone", order: "Id, phone, delivery method, status",
        invoice: "invoice no, phone, delivery method & status, payment method", employee: "employee Id, email, role", role: "role name",
        doctor: "name, designation, speciality", appointment: "name, phone, appointed doctor", promo: "price limit, promo type, code, frequency",
    };
    var pathWithValues = {
        "/admin": "",
        "/admin/product": "product",
        "/admin/customers": "customer",
        "/admin/orders": "order",
        "/admin/invoice": "invoice",
        "/admin/employee": "employee",
        "/admin/role": "role",
        "/admin/doctors": "doctor",
        "/admin/appointments": "appointment",
        "/admin/promo_control": "promo"
    };
    useEffect(function () {
        setSearchOption(pathWithValues[String(path)]);
    }, [path]);
    var searchInitialState = {
        product: "",
        customer: "",
        order: "",
        invoice: "",
        employee: "",
        role: "",
        doctor: "",
        appointment: "",
        promo: "",
    };
    var findKeyByValue = function (object, value) {
        return Object.keys(object).find(function (key) { return object[key] === value; }) || "";
    };
    var handleChange = function (event) {
        setSearchOption(event.target.value);
        // setSearchStr(searchInitialState);
        navigate(findKeyByValue(pathWithValues, String(event.target.value)));
    };
    var manageSearchString = function (value) {
        var _a;
        setSearchStr(__assign(__assign({}, searchStr), (_a = {}, _a[searchOption] = value, _a)));
    };
    // console.log(searchStr);
    return (_jsx("div", __assign({ className: 'w-3/6' }, { children: _jsx(StyledTextField, { fullWidth: true, value: searchStr[searchOption], onChange: function (e) { manageSearchString(e.target.value); }, placeholder: searchOption ? "Search ".concat(searchOption, " using ").concat(placeHolderText[searchOption]) : "Choose a filter to search", InputProps: {
                endAdornment: (_jsx(InputAdornment, __assign({ position: 'end' }, { children: _jsxs(FormControl, __assign({ sx: { m: 1, minWidth: 120 }, size: "small" }, { children: [_jsx(InputLabel, __assign({ id: "admin-search-small-label" }, { children: "Filter By" })), _jsxs(Select, __assign({ labelId: "admin-search-small-label", value: searchOption, label: "Filter By", onChange: handleChange }, { children: [_jsx(MenuItem, __assign({ value: "" }, { children: _jsx("em", { children: "None" }) })), (_a = Object.entries(pathWithValues)) === null || _a === void 0 ? void 0 : _a.map(function (_a, index) {
                                        var key = _a[0], value = _a[1];
                                        return (_jsx(MenuItem, __assign({ value: value }, { children: value ? value[0].toUpperCase() + value.slice(1) : "" }), index));
                                    })] }))] })) })))
            } }) })));
};
export default AdminGlobalSearch;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Autocomplete, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup, Typography } from '@mui/material';
import StyledTextField from '../../../../Components/StyledTextField';
import { roleCreation, updateRoleInfo } from '../../../../ApiGateways/role';
var InsertRole = function (props) {
    var _a;
    var open = props.open, onClose = props.onClose, contentSx = props.contentSx, sx = props.sx, formData = props.formData, setFormData = props.setFormData, reset = props.reset, setReset = props.setReset, modules = props.modules, createRole = props.createRole, updateRole = props.updateRole;
    var _b = useState(''), selectedModule = _b[0], setSelectedModule = _b[1];
    var checkboxValue = ["CREATE", "UPDATE", "DELETE"];
    useEffect(function () {
        if (Object.keys(formData === null || formData === void 0 ? void 0 : formData.permissions).length < 1) {
            setFormData(function (prevFormData) {
                var _a;
                return (__assign(__assign({}, prevFormData), { permissions: __assign(__assign({}, prevFormData.permissions), (_a = {}, _a[''] = [], _a)) }));
            });
        }
    }, [formData === null || formData === void 0 ? void 0 : formData.permissions]);
    var handleModuleChange = function (event, value) {
        if (value) {
            setFormData(function (prevFormData) {
                var _a;
                var existingValues = prevFormData.permissions[value];
                var newValues = existingValues
                    ? existingValues
                    : [];
                var updatedPermissions = __assign(__assign({}, formData.permissions), (_a = {}, _a[value || ''] = newValues, _a));
                delete updatedPermissions[""];
                return __assign(__assign({}, prevFormData), { permissions: updatedPermissions });
            });
            setSelectedModule(value);
        }
        else {
            setFormData(function (prevFormData) {
                var updatedPermissions = __assign({}, prevFormData.permissions);
                delete updatedPermissions[selectedModule];
                return __assign(__assign({}, prevFormData), { permissions: updatedPermissions });
            });
        }
    };
    var handleCheckboxChange = function (value, selectedKey) {
        setFormData(function (prevFormData) {
            var _a;
            var existingValues = prevFormData.permissions[selectedKey];
            if (existingValues.includes(value) === true) {
                var indexToDelete = existingValues.indexOf(value);
                existingValues.splice(indexToDelete, 1);
            }
            else {
                existingValues.push(value);
            }
            return __assign(__assign({}, prevFormData), { permissions: __assign(__assign({}, prevFormData.permissions), (_a = {}, _a[selectedKey] = existingValues, _a)) });
        });
    };
    var formClose = function () {
        onClose();
    };
    var handleCreateRole = function (event) {
        event.preventDefault();
        var _a = formData.permissions, removedKey = _a[""], updatedFilters = __rest(_a, [""]), formWithoutEmptyKey = __rest(formData, ["permissions"]);
        var updatedFormData = __assign(__assign({}, formWithoutEmptyKey), { permissions: updatedFilters });
        if (formData === null || formData === void 0 ? void 0 : formData.id) {
            updateRoleInfo(formData === null || formData === void 0 ? void 0 : formData.id, JSON.stringify(updatedFormData), function (data) {
                if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                    updateRole(data === null || data === void 0 ? void 0 : data.data);
                    formClose();
                }
            }, function (res) { return console.log(res); });
        }
        else {
            roleCreation(JSON.stringify(updatedFormData), function (data) {
                if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                    createRole(data === null || data === void 0 ? void 0 : data.data);
                    formClose();
                }
            }, function (res) { return console.log(res); });
        }
    };
    return (_jsxs(Dialog, __assign({ open: open, onClose: formClose, sx: sx }, { children: [_jsx(DialogTitle, { children: _jsx(Typography, __assign({ variant: "h4" }, { children: (formData === null || formData === void 0 ? void 0 : formData.id) ? "Update Role" : "Insert Role" })) }), _jsx(DialogContent, __assign({ sx: contentSx }, { children: _jsxs("form", __assign({ onSubmit: handleCreateRole }, { children: [_jsx(StyledTextField, { value: formData === null || formData === void 0 ? void 0 : formData.name, onChange: function (event) {
                                return setFormData(__assign(__assign({}, formData), { name: String(event.target.value).toUpperCase() }));
                            }, required: true, fullWidth: true, label: "Name", sx: { margin: "0.5rem 0" } }), _jsx(Button, __assign({ variant: "outlined", color: "primary", onClick: function () {
                                setFormData(function (prevFormData) {
                                    var _a;
                                    return (__assign(__assign({}, prevFormData), { permissions: __assign(__assign({}, prevFormData.permissions), (_a = {}, _a[''] = [], _a)) }));
                                });
                            } }, { children: "Add New Permission" })), (_a = Object.keys(formData === null || formData === void 0 ? void 0 : formData.permissions)) === null || _a === void 0 ? void 0 : _a.map(function (moduleName) { return (_jsxs("section", __assign({ style: { display: "flex", gap: "1rem", alignItems: "center" } }, { children: [_jsx(Autocomplete, { options: __spreadArray([], modules, true), getOptionLabel: function (option) { return option; }, value: moduleName, onChange: handleModuleChange, onMouseEnter: function (event) {
                                        setSelectedModule(moduleName);
                                    }, sx: { width: "100%" }, renderInput: function (params) { return (_jsx(StyledTextField, __assign({}, params, { label: "Select Module", sx: { margin: "0.5rem 0" } }))); } }), _jsx(FormGroup, __assign({ sx: { display: "flex", flexDirection: "row", flexWrap: "nowrap" } }, { children: checkboxValue === null || checkboxValue === void 0 ? void 0 : checkboxValue.map(function (item, i) { return (_jsx(FormControlLabel, { control: _jsx(Checkbox, { color: 'primary', sx: {
                                                height: 'fit-content',
                                            }, checked: (formData === null || formData === void 0 ? void 0 : formData.permissions[moduleName].includes(item)) ? true : false, onChange: function (event) {
                                                handleCheckboxChange(item, moduleName);
                                            }, inputProps: { 'aria-label': 'controlled' } }), label: item }, i)); }) }))] }))); }), _jsx(Button, __assign({ type: 'submit', variant: 'contained' }, { children: (formData === null || formData === void 0 ? void 0 : formData.id) ? 'Update' : 'Insert' }))] })) }))] })));
};
export default InsertRole;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Button, Typography, Divider, FormControl, RadioGroup, FormControlLabel, Radio, } from "@mui/material";
import { BasicInfoContext } from "../../../../Components/BasicInfo";
import { NewAddressContext } from "../../../../Components/NewAddress";
function Shipping(props) {
    var radioValue = props.radioValue, setRadioValue = props.setRadioValue, deliveryCharges = props.deliveryCharges, stepNo = props.stepNo, setStepNo = props.setStepNo;
    var basicInfo = useContext(BasicInfoContext).basicInfo;
    var shipping = useContext(NewAddressContext).shipping;
    var handleRadioChange = function (event) {
        setRadioValue(event.target.value);
    };
    // useEffect(() => {
    //   setRadioValue(Object.keys(deliveryCharges)[0])
    // }, [])
    return (_jsxs("main", { children: [_jsxs("div", __assign({ className: "md:grid md:grid-cols-12 items-center border-[1px] border-solid border-gray-600 rounded-xl p-4" }, { children: [_jsx(Typography, __assign({ className: "col-span-2 text-gray-500" }, { children: "Contact" })), _jsxs("section", __assign({ className: "col-span-8 pt-2 md:pt-0" }, { children: [_jsxs(Typography, __assign({ sx: { padding: "0.25rem 0" } }, { children: [_jsx("b", { children: "Email: " }), basicInfo.email] })), _jsxs(Typography, __assign({ sx: { padding: "0.25rem 0" } }, { children: [_jsx("b", { children: "Phone: " }), basicInfo.phone] }))] })), _jsx(Button, __assign({ className: "col-span-2 bg-rose-900 text-white mt-4 md:mt-0", variant: "contained", onClick: function () { return setStepNo(stepNo - 1); } }, { children: "Update" })), _jsx(Divider, { className: "my-5 px-4 col-span-12" }), _jsx(Typography, __assign({ className: "col-span-2 text-gray-500" }, { children: "Ship to" })), _jsxs(Typography, __assign({ className: "col-span-8 pt-2 md:pt-0" }, { children: ["".concat(shipping.addressLine1, ","), "".concat(shipping.addressLine2 ? " " + shipping.addressLine2 + "," : ""), "".concat(shipping.suburb ? " " + shipping.suburb + "," : ""), " ".concat(shipping.city, ","), " ".concat(shipping.state, ","), " ".concat(shipping.country.label)] })), _jsx(Button, __assign({ className: "col-span-2 bg-rose-900 text-white mt-4 md:mt-0", variant: "contained", onClick: function () { return setStepNo(stepNo - 1); } }, { children: "Update" }))] })), _jsx(Typography, __assign({ variant: "h5", sx: { marginTop: "2.5rem" } }, { children: "Choose Shipping Method" })), _jsx("div", __assign({ className: "border-[1px] border-solid border-gray-600 rounded-xl my-3" }, { children: _jsx(FormControl, __assign({ fullWidth: true }, { children: _jsx(RadioGroup, __assign({ value: radioValue, onChange: handleRadioChange }, { children: Object.entries(deliveryCharges).map(function (_a, index) {
                            var key = _a[0], value = _a[1];
                            return (_jsxs("section", __assign({ className: "grid grid-cols-12 p-3 items-center" }, { children: [_jsx(FormControlLabel, { value: key, control: _jsx(Radio, {}), label: value.label, className: "col-span-9" }), _jsx(Typography, __assign({ className: "col-span-3 justify-self-end" }, { children: _jsxs("b", { children: ["\u09F3 ", value.value] }) })), Object.keys(deliveryCharges).length > index + 1 && (_jsx(Divider, { className: "col-span-12" }))] }), index));
                        }) })) })) }))] }));
}
export default Shipping;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import WishlistCard from "./WishlistCard";
import { deleteFullWishlist, getWishlistByUser } from "../../../../ApiGateways/wishlist";
import { Context } from "../../../../State/Provider";
var CustomTabPanel = function (props) {
    var children = props.children, value = props.value, index = props.index, other = __rest(props, ["children", "value", "index"]);
    return (_jsx("div", __assign({ role: "tabpanel", hidden: value !== index, id: "simple-tabpanel-".concat(index), "aria-labelledby": "simple-tab-".concat(index) }, other, { children: value === index && (_jsx(Box, __assign({ sx: { p: 3 } }, { children: _jsx(Typography, { children: children }) }))) })));
};
var a11yProps = function (index) {
    var _a = useState(0), value = _a[0], setValue = _a[1];
    return {
        id: "simple-tab-".concat(index),
        "aria-controls": "simple-tabpanel-".concat(index),
    };
};
var MyWishlist = function () {
    var _a = useState(0), value = _a[0], setValue = _a[1];
    var _b = useContext(Context), allWish = _b.allWish, setAllWish = _b.setAllWish;
    var _c = useState(false), reset = _c[0], setReset = _c[1];
    var _d = useState(''), wishListId = _d[0], setWishListId = _d[1];
    var handleChange = function (event, newValue) {
        setValue(newValue);
    };
    useEffect(function () {
        getWishlistByUser(function (data) {
            var _a, _b;
            console.log(data);
            setWishListId((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.id);
            setAllWish((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.items);
        }, function (res) {
            console.log(res);
            setAllWish([]);
        });
    }, [reset]);
    var deleteWholeWishList = function () {
        deleteFullWishlist(String(wishListId), function (data) {
            console.log(data);
            if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                setReset(!reset);
            }
        }, function (res) { return console.log(res); });
    };
    return (_jsxs("main", __assign({ className: " p-8" }, { children: [_jsx("article", { children: _jsx(Typography, __assign({ className: "text-gray-700 mb-6", variant: "h4", sx: { textAlign: "center" } }, { children: "My Wishlist" })) }), _jsx("article", __assign({ className: "mt-5 border-2" }, { children: _jsx(Box, __assign({ sx: { width: "100%", minHeight: 500 } }, { children: _jsx(Box, __assign({ sx: { borderBottom: 1, borderColor: "divider" } }, { children: _jsxs("div", __assign({ className: " mx-4 my-4" }, { children: [(allWish === null || allWish === void 0 ? void 0 : allWish.length) >= 1 &&
                                    _jsx("section", { children: _jsx(Button, __assign({ variant: "contained", className: "text-sm hover:bg-neutral-800", onClick: function () { return deleteWholeWishList(); } }, { children: "Clear Wishlist" })) }), _jsx("section", __assign({ className: "mt-5" }, { children: allWish === null || allWish === void 0 ? void 0 : allWish.map(function (item, i) { return (_jsx("div", __assign({ className: "mb-2" }, { children: _jsx(WishlistCard, { item: item, reset: reset, setReset: setReset }) }), i)); }) }))] })) })) })) }))] })));
};
export default MyWishlist;

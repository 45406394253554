var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from "react";
export var Context = createContext({
    openProfile: false,
    setOpenProfile: function () { },
    searchDrawer: false,
    setSearchDrawer: function () { },
    allWish: [],
    setAllWish: function () { },
    searchStr: {
        product: "",
        customer: "",
        order: "",
        invoice: "",
        employee: "",
        role: "",
        doctor: "",
        appointment: "",
        promo: "",
    },
    setSearchStr: function () { }
});
export var GlobalStateProvider = function (_a) {
    var children = _a.children;
    var _b = useState(false), openProfile = _b[0], setOpenProfile = _b[1];
    var _c = useState(false), searchDrawer = _c[0], setSearchDrawer = _c[1];
    var _d = useState({
        product: "",
        customer: "",
        order: "",
        invoice: "",
        employee: "",
        role: "",
        doctor: "",
        appointment: "",
        promo: "",
    }), searchStr = _d[0], setSearchStr = _d[1];
    var _e = useState([]), allWish = _e[0], setAllWish = _e[1];
    var allState = {
        openProfile: openProfile,
        setOpenProfile: setOpenProfile,
        searchDrawer: searchDrawer,
        setSearchDrawer: setSearchDrawer,
        searchStr: searchStr,
        setSearchStr: setSearchStr,
        allWish: allWish,
        setAllWish: setAllWish
    };
    return (_jsx(Context.Provider, __assign({ value: allState }, { children: children })));
};
export var useGlobalState = function () {
    var context = useContext(Context);
    if (context === undefined) {
        throw new Error("useGlobalState must be used within a GlobalStateProvider");
    }
    return context;
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect, useContext } from 'react';
import { Typography, Button, TableRow, TableCell, Menu, MenuItem } from '@mui/material';
import ManageEmployee from './Form/ManageEmployee';
import { employeeRemove, getAllEmployee, updateEmployee } from '../../../ApiGateways/employee';
import GenericTable from '../../../Components/GenericTable';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from '../../../Components/ConfirmDialog';
import { getAllRoles } from '../../../ApiGateways/role';
import { Context } from '../../../State/Provider';
function Row(props) {
    var _a, _b, _c, _d;
    var emp = props.emp, allRoles = props.allRoles, employeeDelete = props.employeeDelete, employeeUpdate = props.employeeUpdate, index = props.index;
    var _e = useState(false), deleteDialog = _e[0], setDeleteDialog = _e[1];
    var _f = React.useState(null), anchorEl = _f[0], setAnchorEl = _f[1];
    var menuopen = Boolean(anchorEl);
    var handleEmpClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleEmpClose = function () {
        setAnchorEl(null);
    };
    var statusChange = function (empId, name, index) {
        employeeUpdate(empId, name, index);
        handleEmpClose();
    };
    return (_jsxs(React.Fragment, { children: [_jsxs(TableRow, { children: [_jsxs(TableCell, __assign({ sx: { textAlign: "center" } }, { children: [_jsx(Button, __assign({ id: "emp-menu-expand-button", "aria-controls": menuopen ? 'emp-status-menu' : undefined, "aria-haspopup": "true", "aria-expanded": menuopen ? 'true' : undefined, onClick: handleEmpClick, className: "px-3 py-3 rounded-xl bg-rose-100 text-rose-900" }, { children: (_a = emp === null || emp === void 0 ? void 0 : emp.role) === null || _a === void 0 ? void 0 : _a.name })), _jsx(Menu, __assign({ id: "emp-status-menu", anchorEl: anchorEl, open: menuopen, onClose: handleEmpClose, MenuListProps: {
                                    'aria-labelledby': 'emp-menu-expand-button',
                                } }, { children: allRoles === null || allRoles === void 0 ? void 0 : allRoles.map(function (item, i) { return (_jsx(MenuItem, __assign({ onClick: function () {
                                        statusChange(emp === null || emp === void 0 ? void 0 : emp.id, item, index);
                                    } }, { children: item }), i)); }) }))] })), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: emp === null || emp === void 0 ? void 0 : emp.employee_id })), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: (_b = emp === null || emp === void 0 ? void 0 : emp.user) === null || _b === void 0 ? void 0 : _b.email })), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: "".concat(((_c = emp === null || emp === void 0 ? void 0 : emp.user) === null || _c === void 0 ? void 0 : _c.firstname) || '', " ").concat(((_d = emp === null || emp === void 0 ? void 0 : emp.user) === null || _d === void 0 ? void 0 : _d.lastname) || '') })), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: _jsx(Button, __assign({ color: 'error', onClick: function () {
                                setDeleteDialog(true);
                            } }, { children: _jsx(DeleteIcon, {}) })) }))] }), _jsx(_Fragment, { children: _jsx(ConfirmDialog, { open: deleteDialog, title: 'Do you want to delete this Employee ?', onClose: function () {
                        setDeleteDialog(false);
                    }, onConfirm: function () {
                        employeeDelete(emp === null || emp === void 0 ? void 0 : emp.id, index);
                        setDeleteDialog(false);
                    } }) })] }));
}
var Employee = function () {
    var searchStr = useContext(Context).searchStr;
    var _a = useState(), debounceTimeout = _a[0], setDebounceTimeout = _a[1];
    var _b = useState(false), showModal = _b[0], setShowModal = _b[1];
    var _c = useState(false), reset = _c[0], setReset = _c[1];
    var _d = useState(0), totalEmployee = _d[0], setTotalEmployee = _d[1];
    var _e = useState([]), allEmployee = _e[0], setAllEmployee = _e[1];
    var _f = useState(0), page = _f[0], setPage = _f[1];
    var _g = useState(10), rowsPerPage = _g[0], setRowsPerPage = _g[1];
    var _h = useState([]), allRoles = _h[0], setAllRoles = _h[1];
    var columns = {
        "Role": { width: 50 },
        "Employee Id": { width: 70 },
        "Email": { width: 100 },
        "Name": { width: 100 },
        "Action": { width: 100 },
    };
    useEffect(function () {
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        var timeoutId = setTimeout(function () {
            getAllEmployee(page + 1, rowsPerPage, searchStr === null || searchStr === void 0 ? void 0 : searchStr.employee, function (data) {
                var _a, _b;
                // console.log(data)
                setAllEmployee((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items);
                setTotalEmployee((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.total);
            }, function (res) { return console.log(res); });
        }, 1000);
        setDebounceTimeout(timeoutId);
        return function () {
            clearTimeout(timeoutId);
        };
    }, [searchStr === null || searchStr === void 0 ? void 0 : searchStr.employee]);
    useEffect(function () {
        getAllRoles(function (data) {
            var _a, _b;
            if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                var roles = (_b = ((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.roles)) === null || _b === void 0 ? void 0 : _b.map(function (item, index) { return item === null || item === void 0 ? void 0 : item.name; });
                setAllRoles(roles);
            }
        }, function (res) { return console.log(res); });
    }, [reset]);
    var handleChangePage = function (event, newPage) {
        getAllEmployee(newPage + 1, rowsPerPage, searchStr === null || searchStr === void 0 ? void 0 : searchStr.employee, function (data) {
            var _a, _b;
            setAllEmployee((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items);
            setTotalEmployee((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.total);
        }, function (res) { return console.log(res); });
        setPage(newPage);
    };
    var handleChangeRowsPerPage = function (event) {
        getAllEmployee(1, +event.target.value, searchStr === null || searchStr === void 0 ? void 0 : searchStr.employee, function (data) {
            var _a, _b;
            setAllEmployee((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items);
            setTotalEmployee((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.total);
        }, function (res) { return console.log(res); });
        setRowsPerPage(+event.target.value);
    };
    var createTableRows = function (employees) { return (employees === null || employees === void 0 ? void 0 : employees.map(function (emp, index) { return (_jsx(Row, { emp: emp, allRoles: allRoles, employeeDelete: employeeDelete, employeeUpdate: employeeUpdate, index: index }, index)); })); };
    var employeeDelete = function (empId, index) {
        employeeRemove(String(empId), function (data) {
            if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                var temp = __spreadArray([], allEmployee, true);
                temp.splice(index, 1);
                setAllEmployee(temp);
            }
        }, function (res) { return console.log(res); });
    };
    var employeeUpdate = function (empId, name, index) {
        var body = {
            name: String(name)
        };
        updateEmployee(JSON.stringify(body), String(empId), function (data) {
            if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                var temp = __spreadArray([], allEmployee, true);
                temp.splice(index, 1);
                setAllEmployee(__spreadArray([data.data], temp, true));
            }
        }, function (res) { return console.log(res); });
    };
    return (_jsxs("main", __assign({ className: "mb-24" }, { children: [_jsx(Typography, __assign({ className: "text-white text-center text-xl bg-rose-900 shadow-2xl rounded-tl-3xl py-3" }, { children: "Employees" })), _jsxs("section", __assign({ className: "grid gap-y-4 mt-5" }, { children: [_jsx("div", __assign({ className: "flex gap-4 px-4" }, { children: _jsx(Button, __assign({ onClick: function () { return setShowModal(true); }, variant: 'contained', className: "rounded-lg text-lg normal-case text-white bg-sky-800 w-fit" }, { children: "Add Employee" })) })), _jsx(ManageEmployee, { open: showModal, onClose: function () {
                            setShowModal(false);
                        }, insertEmployee: function (data) { return setAllEmployee(__spreadArray([data], allEmployee, true)); }, reset: reset, setReset: setReset, allRoles: allRoles })] })), _jsx(GenericTable, { columns: columns, data: createTableRows(allEmployee), total: totalEmployee, rowsPerPage: rowsPerPage, page: page, onChangePage: handleChangePage, onChangeRowsPerPage: handleChangeRowsPerPage })] })));
};
export default Employee;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ListItem, ListItemButton, ListItemIcon, ListItemText, List } from "@mui/material";
import { useState } from "react";
import { FaChevronDown, FaChevronRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
function SidebarItem(props) {
    var item = props.item;
    var navigate = useNavigate();
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var handleItemClick = function (url) {
        if (item.subOption.length === 0) {
            navigate(url);
        }
        else {
            setIsOpen(!isOpen);
        }
    };
    return (_jsxs("div", { children: [_jsx(ListItem, __assign({ disablePadding: true, disableGutters: true }, { children: _jsxs(ListItemButton, __assign({ onClick: function () { return handleItemClick(item.url); } }, { children: [_jsx(ListItemIcon, __assign({ className: "text-black text-2xl" }, { children: item.iconSrc })), _jsxs(ListItemText, __assign({ className: "text-2xl" }, { children: [item.name, item.subOption.length > 0 && (isOpen ?
                                    _jsx(FaChevronDown, { className: 'inline text-sm ml-2' }) :
                                    _jsx(FaChevronRight, { className: 'inline text-sm ml-2' }))] }))] })) })), isOpen && item.subOption.length > 0 && _jsx(SidebarList, { options: item.subOption })] }));
}
;
function SidebarList(props) {
    var options = props.options, className = props.className;
    return (_jsx(List, __assign({ className: className }, { children: options.map(function (item, index) { return (_jsx(SidebarItem, { item: item }, index)); }) })));
}
;
export default SidebarList;

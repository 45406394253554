var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, CircularProgress, Typography } from '@mui/material';
import { checkPaymentValidation } from '../../../ApiGateways/payment_validation';
import { clearCart } from '../../../Redux/features/productCartSlice';
var PaymentStatus = function () {
    var slug = useParams().slug;
    var user = useSelector(function (state) { return state.userState; });
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var _a = useState(), isValid = _a[0], setIsValid = _a[1];
    var _b = useState(true), showCircle = _b[0], setShowCircle = _b[1];
    var _c = useState([]), paymentData = _c[0], setPaymentData = _c[1];
    useEffect(function () {
        checkPaymentValidation(String(slug), function (data) {
            var _a, _b;
            if (((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.status) === 'VALID' || ((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.status) === 'VALIDATED') {
                dispatch(clearCart());
                setShowCircle(false);
                setPaymentData(data === null || data === void 0 ? void 0 : data.data);
                setIsValid(true);
            }
            else {
                dispatch(clearCart());
                setShowCircle(false);
                setPaymentData(data === null || data === void 0 ? void 0 : data.data);
                setIsValid(false);
            }
        }, function (res) { return console.log(res); });
    }, [slug]);
    // console.log(paymentData);
    return (_jsxs("div", __assign({ className: " pt-5 pb-[25%] px-4 text-gray-900 text-center text-lg" }, { children: [isValid && !showCircle ?
                _jsxs(_Fragment, { children: [_jsx(Typography, __assign({ className: ' font-semibold text-green-400 text-2xl' }, { children: "Your Payment is Successful" })), _jsxs(Typography, __assign({ className: 'text-xl' }, { children: ["Transaction ID: ", paymentData === null || paymentData === void 0 ? void 0 : paymentData.tran_id] })), _jsxs(Typography, { children: ["Payment Medium: ", (paymentData === null || paymentData === void 0 ? void 0 : paymentData.card_issuer) !== "" ? paymentData === null || paymentData === void 0 ? void 0 : paymentData.card_issuer : (paymentData === null || paymentData === void 0 ? void 0 : paymentData.card_type) + "(".concat(paymentData === null || paymentData === void 0 ? void 0 : paymentData.card_brand, "), ").concat(paymentData === null || paymentData === void 0 ? void 0 : paymentData.card_issuer_country)] }), _jsxs(Typography, { children: ["Total Amount: ", _jsx("span", __assign({ className: ' font-semibold' }, { children: paymentData === null || paymentData === void 0 ? void 0 : paymentData.amount }))] }), _jsxs("div", __assign({ className: ' flex justify-center gap-4 mt-4' }, { children: [(user === null || user === void 0 ? void 0 : user.is_active) &&
                                    _jsx(Button, __assign({ variant: 'contained', onClick: function () { return navigate('/user_profile/my_orders'); } }, { children: "Check Order Details" })), _jsx(Button, __assign({ variant: 'contained', onClick: function () { return navigate('/product'); } }, { children: "Shop Again" }))] }))] })
                :
                    !isValid && !showCircle &&
                        _jsxs(_Fragment, { children: [_jsx(Typography, __assign({ className: ' font-semibold text-red-400 text-2xl' }, { children: "Something Went Wrong !" })), _jsx(Typography, __assign({ className: ' font-semibold text-red-600 text-xl' }, { children: "Payment Failed !" })), _jsx(Button, __assign({ className: 'mt-2', variant: 'contained', onClick: function () { return navigate('/'); } }, { children: "Return to Homepage" }))] }), showCircle &&
                _jsxs(_Fragment, { children: [_jsx(Typography, __assign({ className: 'text-2xl text-blue-400 mb-2' }, { children: "Please Wait ...." })), _jsx(CircularProgress, {})] })] })));
};
export default PaymentStatus;

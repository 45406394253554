var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, TableRow, TableCell } from "@mui/material";
import AppointmentForm from './AppointmentForm';
import { getAllDoctors } from '../../../ApiGateways/consult';
import GenericTable from '../../../Components/GenericTable';
import { convertToLocalTime } from '../../../Utils';
import GlobalSnackbar from '../../../Components/GlobalSnackbar';
export var apptFormInitialState = {
    age: null,
    phone: '',
    whatsapp_number: null,
    description: '',
    doctor_id: '',
    schedule_taken: {
        day: "",
        time: {
            value_from: "",
            value_to: ""
        }
    },
    fee: 0,
    payment_method: '',
};
var SkincareAssist = function () {
    var _a;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var _c = useState([]), doctorData = _c[0], setDoctorData = _c[1];
    var _d = useState(0), totalDoctor = _d[0], setTotalDoctor = _d[1];
    var _e = useState(0), page = _e[0], setPage = _e[1];
    var _f = useState(10), rowsPerPage = _f[0], setRowsPerPage = _f[1];
    var _g = useState(), scheduleTime = _g[0], setScheduleTime = _g[1];
    var _h = useState(false), openAlert = _h[0], setOpenAlert = _h[1];
    var _j = useState(apptFormInitialState), apptForm = _j[0], setAptForm = _j[1];
    var columns = {
        "Name": { width: 200 },
        "Speciality": { width: 100 },
        "Degree": { width: 100 },
        "Visiting Fee": { width: 100 },
        "Action": { width: 100 },
    };
    var handleClose = function () {
        setOpen(false);
    };
    useEffect(function () {
        getAllDoctors(page + 1, rowsPerPage, "", function (data) {
            var _a, _b;
            setDoctorData((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items);
            setTotalDoctor((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.total);
        }, function (res) { return console.log(res); });
    }, []);
    var handleChangePage = function (event, newPage) {
        getAllDoctors(newPage + 1, rowsPerPage, "", function (data) {
            var _a, _b;
            setDoctorData((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items);
            setTotalDoctor((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.total);
        }, function (res) { return console.log(res); });
        setPage(newPage);
    };
    var handleChangeRowsPerPage = function (event) {
        getAllDoctors(1, +event.target.value, "", function (data) {
            var _a, _b;
            setDoctorData((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items);
            setTotalDoctor((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.total);
        }, function (res) { return console.log(res); });
        setRowsPerPage(+event.target.value);
    };
    var createTableRows = function (doctors) { return (doctors === null || doctors === void 0 ? void 0 : doctors.map(function (doctor, index) { return (_jsxs(TableRow, { children: [_jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: _jsxs(Typography, { children: [doctor === null || doctor === void 0 ? void 0 : doctor.name, " ", _jsx("span", __assign({ className: 'font-semibold' }, { children: "(".concat(doctor === null || doctor === void 0 ? void 0 : doctor.designation, ")") }))] }) })), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: doctor === null || doctor === void 0 ? void 0 : doctor.speciality })), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: doctor === null || doctor === void 0 ? void 0 : doctor.educational_background })), _jsxs(TableCell, __assign({ sx: { textAlign: "center" } }, { children: [doctor === null || doctor === void 0 ? void 0 : doctor.fee, " BDT"] })), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: _jsx(Button, __assign({ variant: "contained", onClick: function () {
                        setScheduleTime(doctor === null || doctor === void 0 ? void 0 : doctor.schedule);
                        setAptForm(__assign(__assign({}, apptForm), { doctor_id: doctor === null || doctor === void 0 ? void 0 : doctor.id, fee: doctor === null || doctor === void 0 ? void 0 : doctor.fee }));
                    } }, { children: "See Schedule" })) }))] }, index)); })); };
    var openForm = function (day) {
        var _a, _b;
        setAptForm(__assign(__assign({}, apptForm), { schedule_taken: {
                day: day,
                time: {
                    value_from: scheduleTime ? (_a = scheduleTime[day]) === null || _a === void 0 ? void 0 : _a.value_from : "",
                    value_to: scheduleTime ? (_b = scheduleTime[day]) === null || _b === void 0 ? void 0 : _b.value_to : ""
                }
            } }));
        setOpen(true);
    };
    return (
    // <div
    //   style={{
    //     backgroundImage: `url(${BannerImage})`,
    //     backgroundSize: "cover",
    //     backgroundPosition: "center",
    //     backgroundRepeat: "no-repeat",
    //   }}
    // >
    //   <section 
    //     className='flex flex-col gap-8 items-center md:items-end py-[10rem] px-[2.5rem] md:py-[15rem] md:px-[10rem]' 
    //     style={{ borderRadius: "1rem" }}
    //   >
    //     <div className="flex flex-col items-center gap-10">
    //         <Typography variant='h6' className='pt-8' >FIND A</Typography>
    //         <Typography variant='h4' className=' font-semibold' >
    //         DERMATOLOGIST
    //         </Typography>
    //         <Button
    //         onClick={() => {setOpen(true)}}
    //         variant='outlined'
    //         className='text-white bg-rose-900 mt-8 normal-case rounded-lg border-none'
    //         size='large'
    //         >Make an Appointment</Button>
    //     </div>
    //   </section>
    //   <section>
    //   <Dialog
    //             disableScrollLock
    //             fullWidth
    //             open={open}
    //             onClose={handleClose}
    //             aria-labelledby="alert-dialog-title"
    //             aria-describedby="alert-dialog-description"
    //         >
    //             <DialogTitle id="alert-dialog-title">
    //                 Consult Doctor
    //             </DialogTitle>
    //             <DialogContent >
    //                 <AppointmentForm/>
    //             </DialogContent>
    //             <DialogActions>
    //             <Button onClick={handleClose}>Close</Button>
    //             </DialogActions>
    //         </Dialog>
    //   </section>
    // </div>
    _jsxs("div", __assign({ className: 'flex flex-wrap gap-4 mt-4 px-8 mb-24' }, { children: [openAlert &&
                _jsx(GlobalSnackbar, { verticalPosition: "top", horizontalPosition: "center", message: "Appointment has been created succesfully !", alertType: "success", onfinish: function () {
                        setOpenAlert(false);
                    } }), _jsxs("section", __assign({ className: 'overflow-y-auto' }, { children: [_jsx(Typography, __assign({ className: ' text-lg text-center' }, { children: "Doctor's List" })), _jsx(GenericTable, { columns: columns, data: createTableRows(doctorData), total: totalDoctor, rowsPerPage: rowsPerPage, page: page, onChangePage: handleChangePage, onChangeRowsPerPage: handleChangeRowsPerPage })] })), _jsx("section", { children: scheduleTime &&
                    ((_a = Object.keys(scheduleTime)) === null || _a === void 0 ? void 0 : _a.map(function (day, i) {
                        var _a, _b;
                        return (_jsxs("div", __assign({ className: ' rounded-xl border-2 mb-2 px-2 py-3 cursor-pointer hover:bg-slate-100', onClick: function () { openForm(day); } }, { children: [_jsx(Typography, __assign({ className: ' text-xl text-gray-500' }, { children: day })), _jsxs(Typography, { children: ["From: ", _jsx("span", __assign({ className: ' font-semibold' }, { children: convertToLocalTime(((_a = scheduleTime[day]) === null || _a === void 0 ? void 0 : _a.value_from) || "").split(", ")[1] }))] }), _jsxs(Typography, { children: ["To: ", _jsx("span", __assign({ className: ' font-semibold' }, { children: convertToLocalTime(((_b = scheduleTime[day]) === null || _b === void 0 ? void 0 : _b.value_to) || "").split(", ")[1] }))] })] }), i));
                    })) }), _jsxs(Dialog, __assign({ disableScrollLock: true, fullWidth: true, open: open, onClose: handleClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" }, { children: [_jsx(DialogTitle, __assign({ id: "alert-dialog-title" }, { children: "Consult Doctor" })), _jsx(DialogContent, { children: _jsx(AppointmentForm, { apptForm: apptForm, setAptForm: setAptForm, setOpen: setOpen, setOpenAlert: setOpenAlert }) }), _jsx(DialogActions, { children: _jsx(Button, __assign({ onClick: handleClose }, { children: "Close" })) })] }))] })));
};
export default SkincareAssist;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Cancel, RemoveOutlined, AddOutlined } from "@mui/icons-material";
import { Typography, IconButton, ButtonGroup, Divider, Button, styled } from "@mui/material";
import { Fragment } from "react";
import { url } from "../../config";
import { useDispatch } from "react-redux";
import { increaseCartItem, decreaseCartItem, removeFromCart } from "../../Redux/features/productCartSlice";
var QuantityButton = styled(Button)(function (_a) {
    var theme = _a.theme;
    return ({
        color: theme.palette.text.primary,
        opacity: 1,
        '&:disabled': {
            backgroundColor: 'transparent',
            color: 'black',
            border: 0,
        },
        '&:hover': {
            backgroundColor: 'transparent',
            color: 'black',
        },
    });
});
function OrderSummary(props) {
    var header = props.header, cart = props.cart, disableItemUpdate = props.disableItemUpdate;
    var dispatch = useDispatch();
    var handleIncreaseQuantity = function (productId) {
        dispatch(increaseCartItem(productId));
    };
    var handleDecreaseQuantity = function (productId) {
        dispatch(decreaseCartItem(productId));
    };
    var handleRemoveFromCart = function (productId) {
        dispatch(removeFromCart(productId));
    };
    return (_jsxs("main", { children: [header, _jsx("section", __assign({ style: { backgroundColor: "rgb(249 250 251)" } }, { children: Object.entries(cart).map(function (_a, index) {
                    var _b, _c;
                    var key = _a[0], value = _a[1];
                    return (_jsxs(Fragment, { children: [_jsxs("section", __assign({ style: { display: "flex", alignItems: "center", padding: "1rem", paddingLeft: "".concat(disableItemUpdate ? "1rem" : 0) } }, { children: [!disableItemUpdate &&
                                        _jsx(IconButton, __assign({ onClick: function () { return handleRemoveFromCart(key); } }, { children: _jsx(Cancel, { fontSize: 'large', sx: { padding: 0 } }) })), _jsx("article", __assign({ style: { width: "12rem" } }, { children: _jsx("img", { src: value.product.images.length > 0 ?
                                                "".concat(url, "/product/file/").concat(value.product.images[0]) :
                                                "https://i.ibb.co/PrxWFNt/facewash.png", style: { objectFit: "contain" } }) })), _jsxs("article", __assign({ style: { width: "15rem", display: "flex", flexFlow: "column wrap", gap: "0.5rem 0", marginLeft: "1rem" } }, { children: [_jsx(Typography, { children: _jsx("b", { children: value.product.name }) }), _jsxs(Typography, { children: [_jsx("b", { children: "Price: " }), "\u09F3 ", value.product.price.value] }), disableItemUpdate ?
                                                _jsxs(Typography, { children: [_jsx("b", { children: "Qty: " }), ((_b = cart[String(key)]) === null || _b === void 0 ? void 0 : _b.quantity) || 0] }) :
                                                _jsxs("section", __assign({ style: { display: "flex", alignItems: "center", gap: "0 0.5rem" } }, { children: [_jsx(Typography, { children: _jsx("b", { children: "Qty:" }) }), _jsxs(ButtonGroup, __assign({ sx: { backgroundColor: "white", borderRadius: "1rem", width: "100%" } }, { children: [_jsx(IconButton, __assign({ onClick: function () { return handleDecreaseQuantity(key); } }, { children: _jsx(RemoveOutlined, {}) })), _jsx(QuantityButton, __assign({ fullWidth: true, disabled: true }, { children: ((_c = cart[String(key)]) === null || _c === void 0 ? void 0 : _c.quantity) || 0 })), _jsx(IconButton, __assign({ onClick: function () { return handleIncreaseQuantity(key); } }, { children: _jsx(AddOutlined, {}) }))] }))] })), _jsxs(Typography, { children: [_jsx("b", { children: "Total: " }), "\u09F3 ", value.subtotal] })] }))] })), _jsx(Divider, { sx: { borderWidth: "1px", borderColor: "#aaaaaa", margin: "0 1rem" } })] }, index));
                }) }))] }));
}
export default OrderSummary;

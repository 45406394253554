var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Button, Autocomplete, createFilterOptions, Dialog, DialogActions, DialogContent, DialogTitle, Typography, IconButton } from '@mui/material';
import StyledTextField from '../../../../Components/StyledTextField';
import { createEmployee } from '../../../../ApiGateways/employee';
import { FaEye, FaEyeSlash } from 'react-icons/fa6';
var filter = createFilterOptions();
var ManageEmployee = function (props) {
    var open = props.open, reset = props.reset, setReset = props.setReset, onClose = props.onClose, insertEmployee = props.insertEmployee, _a = props.allRoles, allRoles = _a === void 0 ? [] : _a;
    var userDataInitialState = {
        role: '',
        email: '',
        password: ''
    };
    var _b = useState(userDataInitialState), userData = _b[0], setUserData = _b[1];
    var _c = useState("password"), passwordState = _c[0], setPasswordState = _c[1];
    var handleEmpCreate = function (event) {
        event.preventDefault();
        createEmployee(JSON.stringify(userData), function (data) {
            if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                insertEmployee(data === null || data === void 0 ? void 0 : data.data);
                formClose();
            }
        }, function (res) { return console.log(res); });
    };
    var formClose = function () {
        setUserData(userDataInitialState);
        onClose();
    };
    return (_jsx("main", { children: _jsxs(Dialog, __assign({ fullWidth: true, open: open, onClose: formClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" }, { children: [_jsx(DialogTitle, __assign({ id: "alert-dialog-title" }, { children: _jsx(Typography, __assign({ variant: "h4" }, { children: "Create Employee" })) })), _jsx(DialogContent, { children: _jsx("form", __assign({ onSubmit: handleEmpCreate }, { children: _jsxs("div", __assign({ style: { marginTop: "1rem", display: "flex", flexDirection: "column", rowGap: "1.25rem" } }, { children: [_jsx(Autocomplete, { value: userData === null || userData === void 0 ? void 0 : userData.role, options: allRoles || [], onChange: function (event, newValue) {
                                        if (newValue === null || newValue === undefined) {
                                            setUserData(__assign(__assign({}, userData), { role: "" }));
                                        }
                                        else {
                                            setUserData(__assign(__assign({}, userData), { role: newValue.toUpperCase() }));
                                        }
                                    }, freeSolo: true, fullWidth: true, selectOnFocus: true, clearOnBlur: true, handleHomeEndKeys: true, getOptionLabel: function (option) { return option; }, filterOptions: function (options, params) {
                                        var filtered = filter(options, params);
                                        var inputValue = params.inputValue;
                                        var isExisting = options.some(function (option) { return inputValue === option; });
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push(inputValue);
                                        }
                                        return filtered;
                                    }, renderInput: function (params) { return (_jsx(StyledTextField, __assign({}, params, { required: true, fullWidth: true, label: "Employee Type", borderRadius: "10px" }))); } }), _jsx(StyledTextField, { required: true, type: 'email', value: userData === null || userData === void 0 ? void 0 : userData.email, onChange: function (e) { return setUserData(__assign(__assign({}, userData), { email: e.target.value })); }, fullWidth: true, label: "Email", borderRadius: "10px" }), _jsx(StyledTextField, { required: true, type: passwordState, value: userData === null || userData === void 0 ? void 0 : userData.password, onChange: function (e) { return setUserData(__assign(__assign({}, userData), { password: e.target.value })); }, fullWidth: true, label: "Password", borderRadius: "10px", InputProps: {
                                        endAdornment: (_jsx(IconButton, { children: passwordState === 'password' ?
                                                _jsx(Typography, __assign({ onClick: function () { return setPasswordState('text'); } }, { children: _jsx(FaEyeSlash, {}) }))
                                                :
                                                    _jsx(Typography, __assign({ onClick: function () { return setPasswordState('password'); } }, { children: _jsx(FaEye, {}) })) })),
                                    } }), _jsx(Button, __assign({ type: "submit", variant: "contained", style: {
                                        borderRadius: " 0.5rem",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.75rem",
                                        textTransform: "none", color: "rgb(255 255 255/var(1))", backgroundColor: "rgb(7 89 133)",
                                        width: "fit-content"
                                    } }, { children: "Add" }))] })) })) }), _jsx(DialogActions, { children: _jsx(Button, __assign({ onClick: onClose }, { children: "Close" })) })] })) }));
};
export default ManageEmployee;

export var stringToColor = function (str) {
    // Generate hash code from string
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    // Generate color code from hash code
    var color = Math.floor(Math.abs(hash) % 16777215).toString(16);
    return "#" + "0".repeat(6 - color.length) + color;
};
export var returnTimeValue = function (timeVal) {
    if (typeof timeVal === "object") {
        var finalTime = timeVal &&
            "".concat(timeVal["$H"] > 9 ? timeVal["$H"] : "0".concat(timeVal["$H"]), ":").concat(timeVal["$m"] > 9 ? timeVal["$m"] : "0".concat(timeVal["$m"]), ":").concat(timeVal["$s"] > 9 ? timeVal["$s"] : "0".concat(timeVal["$s"]));
        return finalTime;
    }
    else if (typeof timeVal === "string") {
        var inputDate = new Date(timeVal);
        var userTimeZoneOffsetMinutes = new Date().getTimezoneOffset();
        var adjustmentMinutes = userTimeZoneOffsetMinutes;
        var adjustedDate = new Date(inputDate.getTime() - adjustmentMinutes * 60000);
        var hours = adjustedDate.getUTCHours();
        var minutes = adjustedDate.getUTCMinutes();
        var seconds = adjustedDate.getUTCSeconds();
        var finalTime = "".concat(hours > 9 ? hours : "0".concat(hours), ":").concat(minutes > 9 ? minutes : "0".concat(minutes), ":").concat(seconds > 9 ? seconds : "0".concat(seconds));
        return finalTime;
    }
};
export var validateAlphabet = function (value) {
    var trimmedValue = value.trim().replace(/[^A-Za-z\s]/g, "");
    return trimmedValue;
};
export var validateAlphabetAllowSpace = function (value) {
    var allowedValue = value.replace(/[^A-Za-z\s]+/g, "");
    return allowedValue;
};
export var validateAll = function (value) {
    var trimmedValue = value
        .trim()
        .replace(/[^A-Za-z0-9\s,:.-]*[^A-Za-z0-9]$/, "");
    return trimmedValue;
};
export var validateAllAllowSpace = function (value) {
    var allowedValue = value.replace(/[^A-Za-z0-9\s,:.-]+$/g, "");
    return allowedValue;
};
export var convertToLocalTime = function (time) {
    var date = new Date();
    if (time !== "") {
        date = new Date(time);
    }
    var options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true
    };
    var localTime = new Intl.DateTimeFormat('default', options).format(date);
    return localTime;
};
export var convertTo12HourFormat = function (time) {
    var _a = time.split(":").map(Number), hours = _a[0], minutes = _a[1], seconds = _a[2];
    var period = hours >= 12 ? "PM" : "AM";
    if (hours > 12) {
        hours -= 12;
    }
    else if (hours === 0) {
        hours = 12;
    }
    var formattedTime = "".concat(hours.toString().padStart(2, "0"), ":").concat(minutes.toString().padStart(2, "0"), ":").concat(seconds.toString().padStart(2, "0"));
    return "".concat(formattedTime, " ").concat(period);
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useContext } from 'react';
import { getAllInvoice, paymentStatusChange } from '../../../ApiGateways/invoice';
import { Typography, TableRow, TableCell, Button, Menu, MenuItem } from '@mui/material';
import GenericTable from '../../../Components/GenericTable';
import { Context } from '../../../State/Provider';
function Row(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var row = props.row, reset = props.reset, setReset = props.setReset;
    var _k = React.useState(false), open = _k[0], setOpen = _k[1];
    var _l = useState(''), tempStatus = _l[0], setTempStatus = _l[1];
    var _m = React.useState(null), anchorEl = _m[0], setAnchorEl = _m[1];
    var menuopen = Boolean(anchorEl);
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var menuOptions = [
        { name: "Paid", value: "paid" },
        { name: "Unpaid", value: "unpaid" },
    ];
    var paymentStatusColor = {
        "unpaid": "bg-red-100 text-red-900",
        "paid": "bg-green-100 text-green-900"
    };
    var deliveryStatusColor = {
        "processing": "bg-red-100 text-red-900",
        "on_route": "bg-violet-100 text-violet-900",
        "delivered": "bg-green-100 text-green-900",
        "cancelled": "uppercase text-red-500 font-bold"
    };
    var statusChange = function (invoiceId, optionValue, optionName) {
        // console.log(invoiceId, optionValue, optionName)
        var body = {
            "paymentStatus": optionValue
        };
        paymentStatusChange(JSON.stringify(body), String(invoiceId), function (data) {
            if ((data === null || data === void 0 ? void 0 : data.success) === true)
                setReset(!reset);
        }, function (res) { return console.log(res); });
        handleClose();
    };
    return (_jsx(React.Fragment, { children: _jsxs(TableRow, __assign({ sx: { "& > *": { borderBottom: "unset", backgroundColor: "".concat(((_a = row === null || row === void 0 ? void 0 : row.order) === null || _a === void 0 ? void 0 : _a.deliveryStatus) === 'cancelled' ? '#c8c9c1' : '') } } }, { children: [_jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: row === null || row === void 0 ? void 0 : row.invoice_id })), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: (_c = (_b = row === null || row === void 0 ? void 0 : row.order) === null || _b === void 0 ? void 0 : _b.basic_info) === null || _c === void 0 ? void 0 : _c.phone })), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: (_d = row === null || row === void 0 ? void 0 : row.order) === null || _d === void 0 ? void 0 : _d.deliveryMethod })), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: row === null || row === void 0 ? void 0 : row.payment_method })), _jsxs(TableCell, __assign({ sx: { textAlign: "center" } }, { children: [_jsx(Button, __assign({ disabled: ((_e = row === null || row === void 0 ? void 0 : row.order) === null || _e === void 0 ? void 0 : _e.deliveryStatus) !== 'cancelled' ? false : true, id: "invoice-expand-button", "aria-controls": menuopen ? 'invoice-menu' : undefined, "aria-haspopup": "true", "aria-expanded": menuopen ? 'true' : undefined, onClick: handleClick, className: "px-3 py-3 rounded-xl ".concat(paymentStatusColor[row.paymentStatus || "unpaid"]) }, { children: row === null || row === void 0 ? void 0 : row.paymentStatus })), _jsx(Menu, __assign({ id: "invoice-menu", anchorEl: anchorEl, open: menuopen, onClose: handleClose, MenuListProps: {
                                'aria-labelledby': 'invoice-expand-button',
                            } }, { children: menuOptions === null || menuOptions === void 0 ? void 0 : menuOptions.map(function (item, i) { return (_jsx(MenuItem, __assign({ onClick: function () {
                                    statusChange(row === null || row === void 0 ? void 0 : row.id, item === null || item === void 0 ? void 0 : item.value, item === null || item === void 0 ? void 0 : item.name);
                                } }, { children: item === null || item === void 0 ? void 0 : item.name }), i)); }) }))] })), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: "".concat(((_f = row === null || row === void 0 ? void 0 : row.net_total) === null || _f === void 0 ? void 0 : _f.value).toFixed(2), " ").concat((_g = row === null || row === void 0 ? void 0 : row.net_total) === null || _g === void 0 ? void 0 : _g.unit) })), _jsx(TableCell, __assign({ sx: { textAlign: "center" }, className: " uppercase ".concat(deliveryStatusColor[((_h = row === null || row === void 0 ? void 0 : row.order) === null || _h === void 0 ? void 0 : _h.deliveryStatus) || "processing"]) }, { children: "".concat((_j = row === null || row === void 0 ? void 0 : row.order) === null || _j === void 0 ? void 0 : _j.deliveryStatus).replace("_", " ") }))] })) }));
}
var Invoice = function () {
    var searchStr = useContext(Context).searchStr;
    var _a = useState(), debounceTimeout = _a[0], setDebounceTimeout = _a[1];
    var _b = useState(0), totalInvoice = _b[0], setTotalInvoice = _b[1];
    var _c = useState([]), allInvoice = _c[0], setAllInvoice = _c[1];
    var _d = useState(0), page = _d[0], setPage = _d[1];
    var _e = useState(10), rowsPerPage = _e[0], setRowsPerPage = _e[1];
    var _f = useState(false), reset = _f[0], setReset = _f[1];
    var columns = {
        "Invoice No": { width: 50 },
        "Customer Phone": { width: 100 },
        "Delivery Method": { width: 100 },
        "Payment Method": { width: 150 },
        "Payment Status": { width: 150 },
        "Net Total": { width: 100 },
        "Delivery Status": { width: 150 },
    };
    var handleChangePage = function (event, newPage) {
        getAllInvoice(newPage + 1, rowsPerPage, searchStr === null || searchStr === void 0 ? void 0 : searchStr.invoice, function (data) {
            var _a, _b;
            setAllInvoice((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items);
            setTotalInvoice((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.total);
        }, function (res) { return console.log(res); });
        setPage(newPage);
    };
    var handleChangeRowsPerPage = function (event) {
        getAllInvoice(1, +event.target.value, searchStr === null || searchStr === void 0 ? void 0 : searchStr.invoice, function (data) {
            var _a, _b;
            setAllInvoice((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items);
            setTotalInvoice((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.total);
        }, function (res) { return console.log(res); });
        setRowsPerPage(+event.target.value);
    };
    useEffect(function () {
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        var timeoutId = setTimeout(function () {
            getAllInvoice(page + 1, rowsPerPage, searchStr === null || searchStr === void 0 ? void 0 : searchStr.invoice, function (data) {
                var _a, _b, _c;
                console.log((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items);
                setAllInvoice((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.items);
                setTotalInvoice((_c = data === null || data === void 0 ? void 0 : data.data) === null || _c === void 0 ? void 0 : _c.total);
            }, function (res) { return console.log(res); });
        }, 1000);
        setDebounceTimeout(timeoutId);
        return function () {
            clearTimeout(timeoutId);
        };
        getAllInvoice(page + 1, rowsPerPage, searchStr === null || searchStr === void 0 ? void 0 : searchStr.invoice, function (data) {
            var _a, _b, _c;
            console.log((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items);
            setAllInvoice((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.items);
            setTotalInvoice((_c = data === null || data === void 0 ? void 0 : data.data) === null || _c === void 0 ? void 0 : _c.total);
        }, function (res) { return console.log(res); });
    }, [reset, searchStr === null || searchStr === void 0 ? void 0 : searchStr.invoice]);
    var createTableRows = function (invoices) { return (invoices === null || invoices === void 0 ? void 0 : invoices.map(function (invoice, index) { return (_jsx(Row, { row: invoice, reset: reset, setReset: setReset }, index)); })); };
    return (_jsxs("main", __assign({ className: 'mb-24' }, { children: [_jsx("section", { children: _jsx(Typography, __assign({ className: "text-white text-center text-xl bg-rose-900 shadow-2xl rounded-tl-3xl py-3" }, { children: "Invoice" })) }), _jsx("section", __assign({ className: "my-5 px-4" }, { children: _jsx(GenericTable, { columns: columns, data: createTableRows(allInvoice), total: totalInvoice, rowsPerPage: rowsPerPage, page: page, onChangePage: handleChangePage, onChangeRowsPerPage: handleChangeRowsPerPage }) }))] })));
};
export default Invoice;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Button, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
var FeaturedInfo = function (props) {
    var items = props.items;
    var navigate = useNavigate();
    return (_jsxs("div", __assign({ className: "flex flex-1 p-2 justify-between shadow-xl border-solid border border-stone-300  h-40 rounded-lg" }, { children: [_jsxs("section", __assign({ className: ' flex flex-col justify-between' }, { children: [_jsxs(Typography, __assign({ variant: 'h5', className: ' text-gray-500' }, { children: [items === null || items === void 0 ? void 0 : items.name, " "] })), _jsxs(Typography, __assign({ variant: 'h4' }, { children: [items === null || items === void 0 ? void 0 : items.value, " "] })), _jsxs(Button, __assign({ onClick: function () { return navigate(items === null || items === void 0 ? void 0 : items.link); }, size: 'small', variant: 'outlined', className: 'border-solid border-rose-900' }, { children: [" ", items === null || items === void 0 ? void 0 : items.buttonTag] }))] })), _jsx("section", __assign({ className: 'flex flex-col justify-between' }, { children: _jsx(IconButton, __assign({ size: 'medium', className: ' bg-teal-100' }, { children: items === null || items === void 0 ? void 0 : items.icon })) }))] })));
};
export default FeaturedInfo;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { Card, CardActions, CardContent, CardMedia, Typography, IconButton, ButtonGroup, Avatar, Tooltip, } from "@mui/material";
import { url } from "../../config";
import { useNavigate } from "react-router-dom";
import { Rating } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, decreaseCartItem, increaseCartItem } from "../../Redux/features/productCartSlice";
import AddCartButton from "../AddCartButton";
import { deleteWishListByProduct, wishlistCreation } from "../../ApiGateways/wishlist";
import GlobalSnackbar from "../GlobalSnackbar";
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
var ProductCard = function (props) {
    var _a, _b, _c;
    var item = props.item, horizontal = props.horizontal, inWishlist = props.inWishlist, reset = props.reset, setReset = props.setReset;
    var myCart = useSelector(function (state) { return state.cartState; });
    var user = useSelector(function (state) { return state.userState; });
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var _d = useState(false), isInWishlist = _d[0], setIsInWishlist = _d[1];
    var _e = useState(true), inStock = _e[0], setInstock = _e[1];
    var _f = useState(false), alert = _f[0], setAlert = _f[1];
    var _g = useState({
        isActive: false,
        verticalPosition: 'top',
        horizontalPosition: 'center',
        message: '',
        alertType: 'success',
    }), snackbarState = _g[0], setSnackbarState = _g[1];
    useEffect(function () {
        if ((item === null || item === void 0 ? void 0 : item.quantity) &&
            (item === null || item === void 0 ? void 0 : item.restock_alert) &&
            (item === null || item === void 0 ? void 0 : item.unit) &&
            (item === null || item === void 0 ? void 0 : item.subunit) &&
            (item === null || item === void 0 ? void 0 : item.multiplier)) {
            if ((item === null || item === void 0 ? void 0 : item.quantity.unit) === (item === null || item === void 0 ? void 0 : item.restock_alert.unit)) {
                setInstock(((item === null || item === void 0 ? void 0 : item.quantity.value) || 0) > ((item === null || item === void 0 ? void 0 : item.restock_alert.value) || 0));
            }
            else if ((item === null || item === void 0 ? void 0 : item.quantity.unit) === (item === null || item === void 0 ? void 0 : item.unit) &&
                (item === null || item === void 0 ? void 0 : item.restock_alert.unit) === (item === null || item === void 0 ? void 0 : item.subunit)) {
                setInstock(((item === null || item === void 0 ? void 0 : item.quantity.value) || 0) * ((item === null || item === void 0 ? void 0 : item.multiplier) || 0) >
                    ((item === null || item === void 0 ? void 0 : item.restock_alert.value) || 0));
            }
            else if ((item === null || item === void 0 ? void 0 : item.quantity.unit) === (item === null || item === void 0 ? void 0 : item.subunit) &&
                (item === null || item === void 0 ? void 0 : item.restock_alert.unit) === (item === null || item === void 0 ? void 0 : item.unit)) {
                setInstock(((item === null || item === void 0 ? void 0 : item.quantity.value) || 0) >
                    ((item === null || item === void 0 ? void 0 : item.restock_alert.value) || 0) * ((item === null || item === void 0 ? void 0 : item.multiplier) || 0));
            }
        }
    }, [
        item === null || item === void 0 ? void 0 : item.quantity,
        item === null || item === void 0 ? void 0 : item.restock_alert,
        item === null || item === void 0 ? void 0 : item.unit,
        item === null || item === void 0 ? void 0 : item.subunit,
        item === null || item === void 0 ? void 0 : item.multiplier,
    ]);
    var handleAddToCart = function (product, quantity) {
        var _a, _b;
        if (((_a = myCart[String(product._id || product.id)]) === null || _a === void 0 ? void 0 : _a.quantity) === undefined || ((_b = myCart[String(product._id || product.id)]) === null || _b === void 0 ? void 0 : _b.quantity) < 1) {
            dispatch(addToCart({ product: product, quantity: quantity }));
            setSnackbarState({
                isActive: true,
                verticalPosition: 'top',
                horizontalPosition: 'center',
                message: 'Product has been added to cart!',
                alertType: 'success',
            });
        }
        else {
            dispatch(increaseCartItem(product._id || product.id));
            setSnackbarState({
                isActive: true,
                verticalPosition: 'top',
                horizontalPosition: 'center',
                message: 'Product quantity increased in cart!',
                alertType: 'success',
            });
        }
    };
    var handleDecreaseQuantity = function (productId) {
        dispatch(decreaseCartItem(productId));
    };
    var buyNow = function (product, quantity) {
        dispatch(addToCart({ product: product, quantity: quantity }));
        navigate("/order");
    };
    var addToWishList = function (id) {
        var body = {
            product_id: id
        };
        wishlistCreation(JSON.stringify(body), function (data) {
            if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                setSnackbarState && setSnackbarState({
                    isActive: true,
                    verticalPosition: "top",
                    horizontalPosition: "center",
                    message: "Product Has been added to WishList !",
                    alertType: "success"
                });
                setReset && setReset(!reset);
            }
        }, function (res) {
            console.log(res);
        });
    };
    var deleteWishListProduct = function () {
        deleteWishListByProduct(String((item === null || item === void 0 ? void 0 : item._id) || (item === null || item === void 0 ? void 0 : item.id)), function (data) {
            if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                setReset && setReset(!reset);
            }
        }, function (res) { return console.log(res); });
    };
    return (_jsxs(_Fragment, { children: [_jsx("section", __assign({ style: { position: 'absolute' } }, { children: snackbarState.isActive && (_jsx(GlobalSnackbar, { verticalPosition: snackbarState.verticalPosition, horizontalPosition: snackbarState.horizontalPosition, message: snackbarState.message, alertType: snackbarState.alertType, onfinish: function () { return setSnackbarState(__assign(__assign({}, snackbarState), { isActive: false })); } })) })), _jsx(React.Fragment, { children: _jsxs(Card, __assign({ sx: {
                        cursor: "pointer",
                        padding: "0.1rem",
                        width: {
                            xs: "16rem",
                            md: Boolean(horizontal) ? "fit-content" : "16rem",
                        },
                        display: {
                            xs: "flex",
                            md: Boolean(horizontal) ? "grid" : "flex",
                        },
                        gridTemplateColumns: {
                            md: Boolean(horizontal) ? "16rem auto" : "16rem",
                        },
                        gridTemplateRows: {
                            md: Boolean(horizontal) ? "auto auto" : "15rem auto auto",
                        },
                        flexDirection: { xs: "column", md: Boolean(horizontal) ? "row" : "column" },
                        "&:hover #imgbtn": {
                            display: "block"
                        }
                    }, raised: true, className: " shadow-none bg-transparent" }, { children: [_jsxs(CardMedia, __assign({ component: "div", sx: {
                                position: "relative",
                                gridColumnEnd: "span 1",
                                gridRowEnd: { md: Boolean(horizontal) ? "span 2" : "span 1" },
                            } }, { children: [_jsx("img", { src: item.images.length > 0
                                        ? "".concat(url, "/product/file/").concat(item.images[0])
                                        : "https://i.ibb.co/PrxWFNt/facewash.png", alt: item.name, style: {
                                        objectFit: "contain",
                                        maxHeight: "15rem",
                                        maxWidth: "16rem",
                                        margin: Boolean(horizontal) ? "" : "auto",
                                    }, onClick: function () { return navigate("/product_details/".concat((item === null || item === void 0 ? void 0 : item._id) || item.id)); } }), ((_a = Object.keys(item === null || item === void 0 ? void 0 : item.promos)) === null || _a === void 0 ? void 0 : _a.length) > 0 && (
                                // <Typography
                                //   className="font-bold"
                                //   sx={{
                                //     position: "absolute",
                                //     right: 0,
                                //     top: 0,
                                //     textAlign: "center",
                                //     backgroundColor: "rgb(204, 204, 255)",
                                //     padding: "0.5rem",
                                //     whiteSpace: "nowrap",
                                //     overflow: "hidden",
                                //     textOverflow: "ellipsis",
                                //   }}
                                // >
                                //   {item?.promos[0]?.discount_percentage}% off
                                // </Typography>
                                _jsxs(Avatar, __assign({ className: "absolute w-16 h-16 text-center top-0 right-0 bg-black text-white" }, { children: [(_b = item === null || item === void 0 ? void 0 : item.promos[0]) === null || _b === void 0 ? void 0 : _b.discount_percentage, "% off"] }))), !Boolean(horizontal) && inStock ?
                                    _jsx("div", __assign({ id: "imgbtn", className: "block lg:hidden absolute bottom-0 w-full" }, { children: _jsxs("div", __assign({ className: "flex w-full" }, { children: [_jsx("button", __assign({ onClick: function () { return buyNow(item, 1); }, className: "bg-red-500 text-white hover:bg-red-700 border-black bg-opacity-80 py-3 px-3 w-full" }, { children: "BUY NOW" })), _jsx("button", __assign({ onClick: function () { return handleAddToCart(item, 1); }, className: " bg-yellow-500 text-white hover:bg-yellow-700 border-black bg-opacity-80 py-3 px-3 w-full" }, { children: "ADD TO CART" }))] })) }))
                                    :
                                        !inStock &&
                                            _jsx("div", __assign({ className: "absolute top-1/2 w-full" }, { children: _jsx("div", __assign({ className: "w-full bg-red-600 opacity-80 text-white py-3" }, { children: _jsx(Typography, __assign({ className: " text-center capitalize" }, { children: "Out of Stock" })) })) }))] })), _jsxs(CardContent, __assign({ className: "py-4 pb-0 flex flex-col gap-y-2" }, { children: [_jsxs("section", __assign({ className: "flex gap-x-1 items-center justify-self-end" }, { children: [_jsxs("div", __assign({ className: "flex gap-x-1 items-center", onClick: function () { return navigate("/product_details/".concat((item === null || item === void 0 ? void 0 : item._id) || item.id)); } }, { children: [_jsx(Rating, { size: "large", precision: 0.5, value: item === null || item === void 0 ? void 0 : item.ratings, readOnly: true }), _jsxs(Typography, __assign({ sx: { display: "inline" } }, { children: ["(", item === null || item === void 0 ? void 0 : item.ratings_given, ")"] }))] })), (user === null || user === void 0 ? void 0 : user.is_active) &&
                                            _jsx(Tooltip, __assign({ title: " Add to wishlist" }, { children: _jsx(IconButton, __assign({ onClick: function () {
                                                        inWishlist !== true ? addToWishList((item === null || item === void 0 ? void 0 : item._id) || item.id) : deleteWishListProduct();
                                                    } }, { children: _jsx(FavoriteTwoToneIcon, { style: { fill: inWishlist || isInWishlist ? 'red' : 'gray' } }) })) }))] })), _jsx("section", __assign({ className: "flex gap-x-4 items-center justify-self-end", onClick: function () { return navigate("/product_details/".concat((item === null || item === void 0 ? void 0 : item._id) || item.id)); } }, { children: _jsxs(Typography, __assign({ className: "text-lg" }, { children: ["\u09F3 ", item.price.value] })) })), _jsx(Typography, __assign({ className: "font-bold flex-wrap", onClick: function () { return navigate("/product_details/".concat((item === null || item === void 0 ? void 0 : item._id) || item.id)); } }, { children: item.name }))] })), _jsxs(CardActions, __assign({ sx: {
                                justifyContent: Boolean(horizontal) ? "flex-start" : "space-between",
                                gridColumnEnd: "span 1",
                                gridRowEnd: "span 1",
                                padding: "1rem",
                                alignContent: "flex-end"
                            }, className: "flex items-center gap-x-2" }, { children: [Boolean(horizontal) &&
                                    // <ButtonGroup variant="outlined" aria-label="Basic button group" fullWidth
                                    //   sx={{
                                    //     display: { xs: "flex", lg: "none" },
                                    //   }}
                                    // >
                                    //   <Button onClick={() => handleDecreaseQuantity(String(item._id || item.id))}>-</Button>
                                    //   <Button disabled={true} className=" border-rose-900 font-semibold text-black">
                                    //     {myCart[String(item._id || item.id)]?.quantity || 0}
                                    //   </Button>
                                    //   <Button onClick={() => handleAddToCart(item, 1)}>+</Button>
                                    // </ButtonGroup>
                                    _jsxs(ButtonGroup, __assign({ fullWidth: true, sx: {
                                            display: { xs: "flex", lg: "none" },
                                        } }, { children: [_jsx("button", __assign({ onClick: function () { return handleDecreaseQuantity(String(item._id || item.id)); }, className: "bg-red-500 text-white hover:bg-red-700 border-black bg-opacity-80 py-2 px-3 w-full" }, { children: "-" })), _jsx("span", __assign({ className: "py-2 w-full text-center" }, { children: ((_c = myCart[String(item._id || item.id)]) === null || _c === void 0 ? void 0 : _c.quantity) || 0 })), _jsx("button", __assign({ onClick: function () { return handleAddToCart(item, 1); }, className: " bg-green-500 text-white hover:bg-green-700 border-black bg-opacity-80 py-2 px-3 w-full" }, { children: "+" }))] })), Boolean(horizontal) && (_jsx(AddCartButton, { data: item, sx: {
                                        display: { xs: "none", lg: "block" },
                                    } }))] }))] })) })] }));
};
export default ProductCard;

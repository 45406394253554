var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import History from "../Review/ReviewSections/History";
import ToReview from "../Review/ReviewSections/ToReview";
import EditReview from "./ReviewSections/EditReview";
import { getReviewByUser } from "../../../../ApiGateways/review";
var CustomTabPanel = function (props) {
    var children = props.children, value = props.value, index = props.index, other = __rest(props, ["children", "value", "index"]);
    return (_jsx("div", __assign({ role: "tabpanel", hidden: value !== index, id: "simple-tabpanel-".concat(index), "aria-labelledby": "simple-tab-".concat(index) }, other, { children: value === index && (_jsx(Box, __assign({ sx: { p: 3 } }, { children: _jsx(Typography, { children: children }) }))) })));
};
var a11yProps = function (index) {
    var _a = useState(0), value = _a[0], setValue = _a[1];
    return {
        id: "simple-tab-".concat(index),
        "aria-controls": "simple-tabpanel-".concat(index),
    };
};
var Review = function () {
    var initialFormState = {
        rating: 5,
        review_msg: {
            title: "",
            summary: "",
            skin_concern: "",
            gender: "",
            age: 0,
            shade_group: "",
            skin_type: "",
        },
        images: [],
    };
    var _a = useState(initialFormState), formData = _a[0], setFormData = _a[1];
    var _b = useState(false), reset = _b[0], setReset = _b[1];
    var _c = useState([]), reviewData = _c[0], setReviewData = _c[1];
    var _d = useState({
        order_id: "",
        product_id: "",
        review_id: "",
    }), extraFormData = _d[0], setExtraFormData = _d[1];
    var _e = useState(0), value = _e[0], setValue = _e[1];
    var handleChange = function (event, newValue) {
        setValue(newValue);
    };
    var _f = React.useState(false), open = _f[0], setOpen = _f[1];
    var theme = useTheme();
    var fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    var handleClickOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setReset(!reset);
        setFormData(initialFormState);
        setOpen(false);
    };
    useEffect(function () {
        getReviewByUser(function (data) {
            var _a, _b;
            setReviewData((_b = (_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.review);
        }, function (res) { return console.log(res); });
    }, [reset]);
    return (_jsxs("main", __assign({ className: " p-8" }, { children: [_jsx("article", { children: _jsx(Typography, __assign({ className: "text-gray-700 mb-6", variant: "h4", sx: { textAlign: "center" } }, { children: "My Reviews" })) }), _jsx("article", __assign({ className: "mt-5 border-2" }, { children: _jsxs(Box, __assign({ sx: { width: "100%", minHeight: 500 } }, { children: [_jsx(Box, __assign({ sx: { borderBottom: 1, borderColor: "divider" } }, { children: _jsxs(Tabs, __assign({ value: value, onChange: handleChange, "aria-label": "basic tabs example", TabIndicatorProps: {
                                    style: {
                                        background: "rgb(10 10 10)",
                                        height: "5px",
                                    },
                                } }, { children: [_jsx(Tab, __assign({ className: " text-gray-500 normal-case text-lg", label: "To Review" }, a11yProps(0))), _jsx(Tab, __assign({ className: " text-gray-500 normal-case text-lg", label: "History" }, a11yProps(1)))] })) })), _jsx(CustomTabPanel, __assign({ value: value, index: 0 }, { children: _jsx(ToReview, { handleClickOpen: function () { return handleClickOpen(); }, reviewData: reviewData, extraFormData: extraFormData, setExtraFormData: setExtraFormData }) })), _jsx(CustomTabPanel, __assign({ value: value, index: 1 }, { children: _jsx(History, { handleClickOpen: function () { return handleClickOpen(); }, reviewData: reviewData, setExtraFormData: setExtraFormData, updateReview: function (review) { return setFormData(review); } }) }))] })) })), _jsx(EditReview, { open: open, setOpen: setOpen, formData: formData, setFormData: setFormData, extraFormData: extraFormData, handleClose: handleClose })] })));
};
export default Review;

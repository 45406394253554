var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Autocomplete, Button, Typography, Dialog, DialogContent, DialogTitle, } from "@mui/material";
import StyledTextField from '../../../../Components/StyledTextField';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { insertDoctor, updateDoctorInfo } from '../../../../ApiGateways/consult';
var InsertDoctor = function (props) {
    var open = props.open, onClose = props.onClose, contentSx = props.contentSx, sx = props.sx, formData = props.formData, setFormData = props.setFormData, reset = props.reset, setReset = props.setReset;
    var _a = useState({}), schedule = _a[0], setSchedule = _a[1];
    var initialWeekDays = [
        { id: 'sunday', label: 'Sunday' },
        { id: 'monday', label: 'Monday' },
        { id: 'tuesday', label: 'Tuesday' },
        { id: 'wednesday', label: 'Wednesday' },
        { id: 'thursday', label: 'Thursday' },
        { id: 'friday', label: 'Friday' },
        { id: 'saturday', label: 'Saturday' },
    ];
    var _b = useState(initialWeekDays), weekDays = _b[0], setWeeDays = _b[1];
    var _c = useState(), selectedWeekday = _c[0], setSelectedWeekday = _c[1];
    var _d = useState(''), identifiedKey = _d[0], setIdentifiedKey = _d[1];
    useEffect(function () {
        setFormData(function (prevFormData) {
            var _a;
            return (__assign(__assign({}, prevFormData), { schedule: __assign(__assign({}, prevFormData.schedule), (_a = {}, _a[''] = { value_from: '', value_to: '' }, _a)) }));
        });
    }, []);
    var noNegative = function (value) {
        if (value > 0)
            return Number(value);
        else
            return 0;
    };
    var formClose = function () {
        onClose();
    };
    var handleWeekdayChange = function (event, value) {
        if (value) {
            setFormData(function (prevFormData) {
                var _a;
                var existingValues = prevFormData.schedule[value.id];
                var newValues = existingValues
                    ? existingValues
                    : { value_from: '', value_to: '' };
                var updatedSchedule = __assign(__assign({}, prevFormData.schedule), (_a = {}, _a[value.id] = newValues, _a));
                delete updatedSchedule[""];
                return __assign(__assign({}, prevFormData), { schedule: updatedSchedule });
            });
        }
        else {
            setFormData(function (prevFormData) {
                var updatedSchedule = __assign({}, prevFormData.schedule);
                delete updatedSchedule[identifiedKey];
                return __assign(__assign({}, prevFormData), { schedule: updatedSchedule });
            });
        }
        setSelectedWeekday(value);
    };
    var handleTimeChange = function (field, value, selectedDay) {
        setFormData(function (prevFormData) {
            var _a, _b;
            return (__assign(__assign({}, prevFormData), { schedule: __assign(__assign({}, prevFormData.schedule), (_a = {}, _a[selectedDay] = __assign(__assign({}, prevFormData.schedule[selectedDay]), (_b = {}, _b[field] = (value === null || value === void 0 ? void 0 : value.toISOString()) || dayjs().toISOString(), _b)), _a)) }));
        });
    };
    var convertToMuiTime = function (timeStr) {
        var result = timeStr ? dayjs("1970-01-01T".concat(timeStr)) : null;
        return result;
    };
    var handleAddSchedule = function () {
        setFormData(function (prevFormData) {
            var _a;
            return (__assign(__assign({}, prevFormData), { schedule: __assign(__assign({}, prevFormData.schedule), (_a = {}, _a[''] = { value_from: '', value_to: '' }, _a)) }));
        });
    };
    var handleSubmitDoctor = function (event) {
        event.preventDefault();
        if (formData === null || formData === void 0 ? void 0 : formData.id) {
            updateDoctorInfo(JSON.stringify(formData), String(formData === null || formData === void 0 ? void 0 : formData.id), function (data) {
                if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                    setReset(!reset);
                    formClose();
                }
                ;
            }, function (res) { return console.log(res); });
        }
        else {
            insertDoctor(JSON.stringify(formData), function (data) {
                if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                    setReset(!reset);
                    formClose();
                }
                ;
            }, function (res) { return console.log(res); });
        }
    };
    return (_jsxs(Dialog, __assign({ open: open, onClose: formClose, sx: sx }, { children: [_jsx(DialogTitle, { children: _jsx(Typography, __assign({ variant: "h4" }, { children: (formData === null || formData === void 0 ? void 0 : formData.id) ? "Update Doctor" : "Insert Doctor" })) }), _jsx(DialogContent, __assign({ sx: contentSx }, { children: _jsxs("form", __assign({ onSubmit: handleSubmitDoctor }, { children: [_jsx(StyledTextField, { value: formData === null || formData === void 0 ? void 0 : formData.name, onChange: function (event) {
                                return setFormData(__assign(__assign({}, formData), { name: event.target.value }));
                            }, required: true, fullWidth: true, label: "Name", sx: { margin: "0.5rem 0" } }), _jsx(StyledTextField, { value: formData === null || formData === void 0 ? void 0 : formData.designation, onChange: function (event) {
                                return setFormData(__assign(__assign({}, formData), { designation: event.target.value }));
                            }, required: true, fullWidth: true, label: "Designation", sx: { margin: "0.5rem 0" } }), _jsx(StyledTextField, { value: formData === null || formData === void 0 ? void 0 : formData.speciality, onChange: function (event) {
                                return setFormData(__assign(__assign({}, formData), { speciality: event.target.value }));
                            }, required: true, fullWidth: true, label: "Speciality", sx: { margin: "0.5rem 0" } }), _jsx(StyledTextField, { value: formData === null || formData === void 0 ? void 0 : formData.educational_background, onChange: function (event) {
                                return setFormData(__assign(__assign({}, formData), { educational_background: event.target.value }));
                            }, required: true, fullWidth: true, label: "Educational Background", sx: { margin: "0.5rem 0" } }), _jsx(StyledTextField, { value: formData.fee, onChange: function (event) {
                                return setFormData(__assign(__assign({}, formData), { fee: noNegative(Number(event.target.value)) }));
                            }, required: true, fullWidth: true, type: "number", label: "Fee", sx: { margin: "0.5rem 0" } }), _jsx(Button, __assign({ variant: "outlined", color: "primary", onClick: handleAddSchedule }, { children: "Add New Schedule" })), Object.keys(formData === null || formData === void 0 ? void 0 : formData.schedule).map(function (day) {
                            var _a, _b, _c, _d;
                            return (_jsxs("section", __assign({ style: { display: "flex", gap: "1rem", alignItems: "center" } }, { children: [_jsx(Autocomplete, { options: weekDays, getOptionLabel: function (option) { return option.label; }, value: weekDays.find(function (wd) { return wd.id === day; }), onChange: function (_, value) { return handleWeekdayChange(_, value); }, onMouseEnter: function (event) {
                                            setIdentifiedKey(day);
                                        }, sx: { width: "100%" }, renderInput: function (params) { return (_jsx(StyledTextField, __assign({}, params, { fullWidth: true, label: "Select WeekDay", sx: { margin: "0.5rem 0" } }))); } }), _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsx(TimePicker, { label: "Start Time", onChange: function (newValue) {
                                                return handleTimeChange("value_from", newValue, day);
                                            }, value: ((_a = formData.schedule[day]) === null || _a === void 0 ? void 0 : _a.value_from) ? dayjs((_b = formData.schedule[day]) === null || _b === void 0 ? void 0 : _b.value_from) : undefined, sx: { width: "100%" } }) })), _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsx(TimePicker, { label: "End Time", onChange: function (newValue) {
                                                return handleTimeChange("value_to", newValue, day);
                                            }, value: ((_c = formData.schedule[day]) === null || _c === void 0 ? void 0 : _c.value_to) ? dayjs((_d = formData.schedule[day]) === null || _d === void 0 ? void 0 : _d.value_to) : undefined, sx: { width: "100%" } }) }))] }), day));
                        }), _jsx(Button, __assign({ type: 'submit', variant: 'contained' }, { children: (formData === null || formData === void 0 ? void 0 : formData.id) ? 'Update' : 'Insert' }))] })) }))] })));
};
export default InsertDoctor;

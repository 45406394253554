import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
var StyledTextField = styled(TextField)(function (props) { return ({
    // Common styles for both variants
    "& .MuiInputBase-root": {
        backgroundColor: props.backgroundColor || "white",
        height: props.height || "auto",
        borderRadius: props.borderRadius || "5px",
        '& input': {
            padding: props.inputPadding || "1rem",
            fontSize: props.fontSize || 'inherit',
            color: props.textColor || 'inherit',
            '&::placeholder': {
                color: props.placeholderColor || 'gray',
                opacity: 1,
            },
        },
        '& label': {
            fontSize: props.fontSize || 'inherit',
        },
    },
    // Styles specific to the outlined variant
    "& .MuiOutlinedInput-root": {
        '& fieldset': {
            borderColor: props.borderColor,
        },
        '&:hover fieldset': {
            borderColor: props.borderColor,
        },
        '&.Mui-focused fieldset': {
            borderColor: props.borderColorFocus || "#881337",
        },
    },
    // Styles specific to the standard variant
    "& .MuiInput-underline:after": {
        borderBottomColor: props.borderColorFocus || "#881337",
    },
    "& .MuiInput-underline:before": {
        borderBottomColor: props.borderColor,
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottomColor: props.borderColor,
    },
    // Label color for focused state
    "& .MuiInputLabel-outlined.Mui-focused, & .MuiInputLabel-root.Mui-focused": {
        color: props.labelColor || "#881337",
    },
    // Text alignment
    '& .MuiInput-input': {
        textAlign: props.textAlign || 'inherit',
    },
}); });
export default StyledTextField;

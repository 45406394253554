var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";
import StyledTextField from "../StyledTextField";
import { validateAlphabet, validateAlphabetAllowSpace } from "../../Utils";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import "./index.scss";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FormControl } from "@mui/base";
export var BasicInfoContext = React.createContext({
    basicInfo: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
    },
    setBasicInfo: function (info) { }
});
function BasicInfo(props) {
    var showDob = props.showDob, showGender = props.showGender;
    var _a = React.useContext(BasicInfoContext), basicInfo = _a.basicInfo, setBasicInfo = _a.setBasicInfo;
    var _b = React.useState(false), phoneError = _b[0], setPhoneError = _b[1];
    return (_jsxs("div", __assign({ className: 'flex flex-col gap-y-4' }, { children: [_jsxs("section", __assign({ className: 'flex flex-col lg:flex-row justify-between gap-y-4 lg:gap-x-4' }, { children: [_jsx(StyledTextField, { value: basicInfo.firstname, required: true, fullWidth: true, label: "First Name", onChange: function (e) { return setBasicInfo(__assign(__assign({}, basicInfo), { firstname: validateAlphabetAllowSpace(e.target.value) })); }, onBlur: function (e) { return setBasicInfo(__assign(__assign({}, basicInfo), { firstname: validateAlphabet(e.target.value) })); } }), _jsx(StyledTextField, { value: basicInfo.lastname, required: true, fullWidth: true, label: "Last Name", onChange: function (e) { return setBasicInfo(__assign(__assign({}, basicInfo), { lastname: validateAlphabetAllowSpace(e.target.value) })); }, onBlur: function (e) { return setBasicInfo(__assign(__assign({}, basicInfo), { lastname: validateAlphabet(e.target.value) })); } })] })), _jsxs("section", __assign({ className: 'flex flex-col lg:flex-row justify-between gap-y-4 lg:gap-x-4' }, { children: [_jsx(StyledTextField, { value: basicInfo.email, fullWidth: true, required: true, type: 'email', label: "Email", onChange: function (e) { return setBasicInfo(__assign(__assign({}, basicInfo), { email: e.target.value })); } }), _jsx(StyledTextField, { fullWidth: true, required: true, variant: 'outlined', InputProps: {
                            inputComponent: PhoneInput,
                            inputProps: {
                                value: basicInfo.phone,
                                defaultCountry: 'BD',
                                className: 'BasicInfo__phone',
                                onChange: function (value) { return setBasicInfo(__assign(__assign({}, basicInfo), { phone: value ? value : '' })); }
                            }
                        }, onBlur: function () { return setPhoneError(!isValidPhoneNumber(basicInfo.phone)); }, error: phoneError, label: 'Phone Number', helperText: phoneError ? 'Phone number is invalid' : '', InputLabelProps: {
                            shrink: true,
                        } })] })), (showDob || showGender) &&
                _jsxs("section", __assign({ className: 'grid grid-cols-2 justify-start gap-y-4 lg:gap-x-4' }, { children: [showDob &&
                            _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsx(DatePicker, { label: "Date of Birth", value: basicInfo.dob, onChange: function (date) { return setBasicInfo(__assign(__assign({}, basicInfo), { dob: date || '' })); }, sx: { width: '100%' }, className: showGender ? "col-span-1" : "col-span-2" }) })), showGender &&
                            _jsxs(FormControl, { children: [_jsx(FormLabel, __assign({ id: "gender-label" }, { children: "Gender" })), _jsxs(RadioGroup, __assign({ "aria-labelledby": "gender-label", row: true, value: basicInfo.gender, onChange: function (event) { return setBasicInfo(__assign(__assign({}, basicInfo), { gender: event.target.value })); }, className: showDob ? "col-span-1" : "col-span-2" }, { children: [_jsx(FormControlLabel, { value: "female", control: _jsx(Radio, {}), label: "Female" }), _jsx(FormControlLabel, { value: "male", control: _jsx(Radio, {}), label: "Male" }), _jsx(FormControlLabel, { value: "other", control: _jsx(Radio, {}), label: "Others" })] }))] })] }))] })));
}
export default BasicInfo;

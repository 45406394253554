var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from 'react';
import { Badge } from '@mui/material';
import { Home, Favorite, ShoppingCart, ShoppingBasket } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Context } from '../../State/Provider';
import FloatingCart from '../FloatingCart';
import { getWishlistByUser } from '../../ApiGateways/wishlist';
var BottomNavigator = function () {
    var navigate = useNavigate();
    var _a = useContext(Context), setSearchDrawer = _a.setSearchDrawer, allWish = _a.allWish, setOpenProfile = _a.setOpenProfile;
    var myCart = useSelector(function (state) { return state.cartState; });
    var user = useSelector(function (state) { return state.userState; });
    var isLoggedIn = useSelector(function (state) { return state.singleState.isLoggedIn; });
    var _b = useState(0), totalWishList = _b[0], setTotalWishList = _b[1];
    var _c = useState(null), cartAnchorEl = _c[0], setCartAnchorEl = _c[1];
    var cartOpen = Boolean(cartAnchorEl);
    var totalQuantity = Object.keys(myCart).reduce(function (total, key) {
        var _a;
        var quantity = ((_a = myCart[key]) === null || _a === void 0 ? void 0 : _a.quantity) || 0;
        return total + quantity;
    }, 0);
    var handleOpenCart = function (event) {
        if (Object.keys(myCart).length > 0 &&
            window.location.pathname !== "/order" &&
            window.location.pathname !== "/shopping_cart") {
            setCartAnchorEl(event.currentTarget);
        }
    };
    var menuControl = function (event) {
        if (isLoggedIn) {
            if (window.innerWidth < 768) {
                setOpenProfile(true);
                event.stopPropagation();
            }
        }
        else {
            navigate("/signin");
        }
    };
    useEffect(function () {
        getWishlistByUser(function (data) {
            var _a, _b;
            setTotalWishList((_b = (_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.length);
        }, function (res) {
            setTotalWishList(0);
            console.log(res);
        });
    }, [user, allWish]);
    return (_jsxs(React.Fragment, { children: [_jsx(FloatingCart, { open: cartOpen, anchorEl: cartAnchorEl, handleClose: function () { return setCartAnchorEl(null); } }), _jsx("nav", __assign({ className: "flex md:hidden h-16 bg-orange-100 text-gray-600 text-sm py-4 bottom-0 w-full sticky" }, { children: _jsxs("div", __assign({ className: "container mx-auto flex justify-around" }, { children: [_jsxs("button", __assign({ onClick: function () { navigate('/'); }, className: "flex flex-col items-center hover:text-orange-500" }, { children: [_jsx(Home, { className: "h-6 w-6" }), "Home"] })), _jsxs("div", __assign({ onClick: function () { navigate('/product'); }, className: "flex flex-col items-center hover:text-orange-500" }, { children: [_jsx(ShoppingBasket, { className: "h-6 w-6" }), "Shop"] })), _jsxs("button", __assign({ onClick: function () { return navigate('/user_profile/my_wishlist'); }, className: "flex flex-col items-center hover:text-orange-500" }, { children: [_jsx(Badge, __assign({ badgeContent: totalWishList, color: "secondary", showZero: true }, { children: _jsx(Favorite, { className: "h-6 w-6" }) })), "WishList"] })), _jsxs("button", __assign({ className: "flex flex-col items-center hover:text-orange-500" }, { children: [_jsx(Badge, __assign({ badgeContent: totalQuantity, color: "secondary", showZero: true }, { children: _jsx(ShoppingCart, { className: "h-6 w-6", onClick: handleOpenCart }) })), "Cart"] })), _jsxs("button", __assign({ onClick: function (event) {
                                menuControl(event);
                                event.stopPropagation();
                            }, className: "flex flex-col items-center hover:text-orange-500" }, { children: [_jsx(PersonIcon, { className: ' w-8 h-8 mt-[-10px]' }), "Account"] }))] })) }))] }));
};
export default BottomNavigator;

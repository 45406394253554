import { jsx as _jsx } from "react/jsx-runtime";
import { createBrowserRouter } from "react-router-dom";
import UserLayout from "../Layout/UserLayout";
import Home from "../Pages/NonAuthPages/Home";
import Register from "../Pages/NonAuthPages/Authentication/User/Register";
import UserRoute from "./UserRoute";
import Product from "../Pages/NonAuthPages/Product";
import ManageAccount from "../Pages/UserAuthPages/UserProfile/ManageAccount";
import AdminRoute from "./AdminRoute";
import Dashboard from "../Pages/AdminAuthPages/Dashboard/Dashboard";
import AdminLayout from "../Layout/AdminLayout";
import AdminProduct from "../Pages/AdminAuthPages/Product";
import AdminLogin from "../Pages/NonAuthPages/Authentication/Admin";
import SignIn from "../Pages/NonAuthPages/Authentication/User/Login";
import ProductDetails from "../Pages/NonAuthPages/ProductDetails";
import MainOrder from "../Pages/NonAuthPages/Order";
import PromoControl from "../Pages/AdminAuthPages/PromoControl";
import Customers from "../Pages/AdminAuthPages/Customers";
import MyOrders from "../Pages/UserAuthPages/UserProfile/MyOrders";
import ShoppingCart from "../Pages/NonAuthPages/ShoppingCart";
import ViewOrders from "../Pages/AdminAuthPages/ViewOrders";
import Invoice from "../Pages/AdminAuthPages/Invoice";
import SkincareAssist from "../Pages/NonAuthPages/SkincareAssist";
import Employee from "../Pages/AdminAuthPages/Employee";
import GenericLayout from "../Layout/GenericLayout";
import Review from "../Pages/UserAuthPages/UserProfile/Review";
import MyWishlist from "../Pages/UserAuthPages/UserProfile/MyWishlist";
import Doctors from "../Pages/AdminAuthPages/Doctors";
import MyAppointment from "../Pages/UserAuthPages/UserProfile/MyAppointment";
import Appointments from "../Pages/AdminAuthPages/Appointments";
import Roles from "../Pages/AdminAuthPages/Roles";
import ManualPermissionRoute from "./ManualPermissionRoute";
import PaymentStatus from "../Pages/NonAuthPages/PaymentStatus";
import PasswordReset from "../Pages/NonAuthPages/PasswordReset";
import ForgotPassword from "../Pages/NonAuthPages/Authentication/ForgotPassword";
import SocialLogin from "../Pages/NonAuthPages/Authentication/User/SocialLogin";
import AboutUs from "../Pages/NonAuthPages/AboutUs";
import PrivacyPolicy from "../Pages/NonAuthPages/PrivacyPolicy";
import ScrollToTop from "./ScrollToTop";
import Blog from "../Pages/NonAuthPages/Blog";
var router = createBrowserRouter([
    {
        path: "/admin_login",
        element: _jsx(AdminLogin, {}),
    },
    {
        path: "/admin",
        element: (_jsx(AdminRoute, { children: _jsx(AdminLayout, {}) })),
        children: [
            {
                path: "/admin",
                element: _jsx(Dashboard, {}),
            },
            {
                path: "/admin/product",
                element: _jsx(AdminProduct, {}),
            },
            {
                path: "/admin/promo_control",
                element: _jsx(PromoControl, {}),
            },
            {
                path: "/admin/customers",
                element: _jsx(Customers, {}),
            },
            {
                path: "/admin/orders",
                element: _jsx(ViewOrders, {}),
            },
            {
                path: "/admin/invoice",
                element: _jsx(Invoice, {}),
            },
            {
                path: "/admin/employee",
                element: _jsx(Employee, {}),
            },
            {
                path: "/admin/doctors",
                element: _jsx(Doctors, {}),
            },
            {
                path: "/admin/appointments",
                element: _jsx(Appointments, {}),
            },
            {
                path: "/admin/role",
                element: _jsx(Roles, {})
            },
        ],
    },
    {
        path: "/",
        element: _jsx(ScrollToTop, { children: _jsx(GenericLayout, {}) }),
        children: [
            {
                path: "/",
                element: _jsx(Home, {}),
            },
            {
                path: "/signin",
                element: _jsx(SignIn, {}),
            },
            {
                path: "/register",
                element: _jsx(Register, {}),
            },
            {
                path: "/social_login/:slug",
                element: _jsx(SocialLogin, {}),
            },
            {
                path: "/forgot_password",
                element: _jsx(ForgotPassword, {}),
            },
            {
                path: "/signin/:slug",
                element: _jsx(SignIn, {}),
            },
            {
                path: "/payment_status/:slug",
                element: _jsx(PaymentStatus, {}),
            },
            {
                path: "/register/:slug",
                element: _jsx(Register, {}),
            },
            {
                path: "/product",
                element: _jsx(Product, {}),
            },
            {
                path: "/product_details/:id",
                element: _jsx(ProductDetails, {}),
            },
            {
                path: "/order",
                element: _jsx(ManualPermissionRoute, { children: _jsx(MainOrder, {}) }),
            },
            {
                path: "/shopping_cart",
                element: _jsx(ShoppingCart, {}),
            },
            {
                path: "/scincare",
                element: (_jsx(UserRoute, { children: _jsx(SkincareAssist, {}) })),
            },
            {
                path: "/password_reset/:token",
                element: _jsx(PasswordReset, {})
            },
            {
                path: "/about_us",
                element: _jsx(AboutUs, {})
            },
            {
                path: "/privacy_policy",
                element: _jsx(PrivacyPolicy, {})
            },
            {
                path: "/blog",
                element: _jsx(Blog, {})
            },
            {
                path: "/user_profile",
                element: (_jsx(UserRoute, { children: _jsx(UserLayout, {}) })),
                children: [
                    {
                        path: "/user_profile",
                        element: _jsx(ManageAccount, {}),
                    },
                    {
                        path: "/user_profile/my_orders",
                        element: _jsx(MyOrders, {}),
                    },
                    {
                        path: "/user_profile/to_review",
                        element: _jsx(Review, {}),
                    },
                    {
                        path: "/user_profile/my_wishlist",
                        element: _jsx(MyWishlist, {}),
                    },
                    {
                        path: "/user_profile/my_appiontment",
                        element: _jsx(MyAppointment, {}),
                    },
                ],
            },
        ],
    },
]);
export default router;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import StyledTextField from '../../../Components/StyledTextField';
import './index.scss';
import { Typography, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Button } from '@mui/material';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { createAppointment } from '../../../ApiGateways/consult';
import sslcommerz_logo from '../../../Static/sslcommerz_logo.png';
var AppointmentForm = function (props) {
    var apptForm = props.apptForm, setAptForm = props.setAptForm, setOpen = props.setOpen, setOpenAlert = props.setOpenAlert;
    var paymentMethods = {
        online: {
            label: "",
            image: sslcommerz_logo,
        },
    };
    var _a = useState('online'), radioValue = _a[0], setRadioValue = _a[1];
    var _b = useState(false), phoneError = _b[0], setPhoneError = _b[1];
    var _c = useState(false), whatsappError = _c[0], setWhatsappError = _c[1];
    var handleRadioChange = function (event) {
        setRadioValue(event.target.value);
        setAptForm(__assign(__assign({}, apptForm), { payment_method: event.target.value }));
    };
    var handleSubmit = function (e) {
        e.preventDefault();
        createAppointment(JSON.stringify(apptForm), function (data) {
            if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                window.location.replace(data === null || data === void 0 ? void 0 : data.gateWayUrl);
                // setOpenAlert(true)
                // setOpen(false);
                // setAptForm(apptFormInitialState)
            }
        }, function (res) { return console.log(res); });
    };
    return (_jsx("form", __assign({ onSubmit: handleSubmit }, { children: _jsxs("div", __assign({ className: "skincare__TopMargin scincare__Parent" }, { children: [_jsx(StyledTextField, { required: true, type: 'number', value: apptForm.age, onChange: function (e) { setAptForm(__assign(__assign({}, apptForm), { age: Number(e.target.value) })); }, fullWidth: true, label: "Age", borderRadius: "10px" }), _jsx(StyledTextField, { fullWidth: true, required: true, borderRadius: "10px", InputProps: {
                        inputComponent: PhoneInput,
                        inputProps: {
                            value: apptForm.phone,
                            defaultCountry: 'BD',
                            className: 'BasicInfo__phone',
                            onChange: function (value) { return setAptForm(__assign(__assign({}, apptForm), { phone: value ? value : '' })); }
                        },
                        classes: {
                            notchedOutline: 'rounded-xl',
                        },
                    }, onBlur: function () { return setPhoneError(!isValidPhoneNumber(apptForm.phone)); }, error: phoneError, label: 'Phone Number', helperText: phoneError ? 'Phone number is invalid' : '', InputLabelProps: {
                        shrink: true,
                    } }), _jsx(StyledTextField, { fullWidth: true, borderRadius: "10px", InputProps: {
                        inputComponent: PhoneInput,
                        inputProps: {
                            value: apptForm.whatsapp_number,
                            defaultCountry: 'BD',
                            className: 'BasicInfo__phone',
                            onChange: function (value) { return setAptForm(__assign(__assign({}, apptForm), { whatsapp_number: value ? value : '' })); }
                        },
                        classes: {
                            notchedOutline: 'rounded-xl',
                        },
                    }, onBlur: function () {
                        var phoneNumber = apptForm.whatsapp_number || '';
                        setWhatsappError(!isValidPhoneNumber(phoneNumber));
                    }, error: whatsappError, label: 'Whatsapp Number', helperText: phoneError ? 'Whatsapp number is invalid' : '', InputLabelProps: {
                        shrink: true,
                    } }), _jsx(StyledTextField, { type: 'text', multiline: true, rows: 4, value: apptForm.description, onChange: function (e) { setAptForm(__assign(__assign({}, apptForm), { description: e.target.value })); }, fullWidth: true, label: "Describe Your Problem", borderRadius: "10px" }), _jsxs(Typography, __assign({ sx: { color: 'red' } }, { children: ["Appointment Fee ", apptForm === null || apptForm === void 0 ? void 0 : apptForm.fee, " BDT"] })), _jsx("div", __assign({ className: "skincare__Radio-Parent" }, { children: _jsx(FormControl, __assign({ fullWidth: true }, { children: _jsx(RadioGroup, __assign({ value: radioValue, onChange: handleRadioChange }, { children: Object.entries(paymentMethods).map(function (_a, index) {
                                var key = _a[0], value = _a[1];
                                return (_jsxs("section", __assign({ className: "skincare__Radio-Formcontrol" }, { children: [_jsx(FormControlLabel, { value: key, control: _jsx(Radio, {}), label: _jsxs("div", __assign({ className: "skincare__Radio-Label" }, { children: [_jsx("img", { style: { height: "2rem", width: "100%" }, src: value.image, alt: "payment_logo" }), _jsx(Typography, { children: value.label })] })), sx: { padding: "0.5rem 1rem" } }), Object.keys(paymentMethods).length > index + 1 && _jsx(Divider, {})] }), index));
                            }) })) })) })), _jsx(Button, __assign({ type: 'submit', className: "skincare__Submit-Btn" }, { children: "Pay Now" }))] })) })));
};
export default AppointmentForm;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Typography, Card, CardMedia, CardContent, IconButton, Divider, } from "@mui/material";
import { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { url } from "../../../../config";
import { useDispatch } from "react-redux";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { addToCart } from "../../../../Redux/features/productCartSlice";
import { deleteWishListByProduct } from "../../../../ApiGateways/wishlist";
var WishlistCard = function (props) {
    var _a;
    var item = props.item, reset = props.reset, setReset = props.setReset;
    var dispatch = useDispatch();
    var _b = useState(true), inStock = _b[0], setInstock = _b[1];
    var wishListToCart = function (product, quantity) {
        deleteWishListByProduct(String((product === null || product === void 0 ? void 0 : product._id) || (product === null || product === void 0 ? void 0 : product.id)), function (data) {
            if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                setReset(!reset);
                dispatch(addToCart({ product: product, quantity: quantity }));
            }
        }, function (res) { return console.log(res); });
    };
    var deleteWishListProduct = function (product) {
        deleteWishListByProduct(String((product === null || product === void 0 ? void 0 : product._id) || (product === null || product === void 0 ? void 0 : product.id)), function (data) {
            if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                setReset(!reset);
            }
        }, function (res) { return console.log(res); });
    };
    useEffect(function () {
        if ((item === null || item === void 0 ? void 0 : item.quantity) &&
            (item === null || item === void 0 ? void 0 : item.restock_alert) &&
            (item === null || item === void 0 ? void 0 : item.unit) &&
            (item === null || item === void 0 ? void 0 : item.subunit) &&
            (item === null || item === void 0 ? void 0 : item.multiplier)) {
            if ((item === null || item === void 0 ? void 0 : item.quantity.unit) === (item === null || item === void 0 ? void 0 : item.restock_alert.unit)) {
                setInstock(((item === null || item === void 0 ? void 0 : item.quantity.value) || 0) > ((item === null || item === void 0 ? void 0 : item.restock_alert.value) || 0));
            }
            else if ((item === null || item === void 0 ? void 0 : item.quantity.unit) === (item === null || item === void 0 ? void 0 : item.unit) &&
                (item === null || item === void 0 ? void 0 : item.restock_alert.unit) === (item === null || item === void 0 ? void 0 : item.subunit)) {
                setInstock(((item === null || item === void 0 ? void 0 : item.quantity.value) || 0) * ((item === null || item === void 0 ? void 0 : item.multiplier) || 0) >
                    ((item === null || item === void 0 ? void 0 : item.restock_alert.value) || 0));
            }
            else if ((item === null || item === void 0 ? void 0 : item.quantity.unit) === (item === null || item === void 0 ? void 0 : item.subunit) &&
                (item === null || item === void 0 ? void 0 : item.restock_alert.unit) === (item === null || item === void 0 ? void 0 : item.unit)) {
                setInstock(((item === null || item === void 0 ? void 0 : item.quantity.value) || 0) >
                    ((item === null || item === void 0 ? void 0 : item.restock_alert.value) || 0) * ((item === null || item === void 0 ? void 0 : item.multiplier) || 0));
            }
        }
    }, [
        item === null || item === void 0 ? void 0 : item.quantity,
        item === null || item === void 0 ? void 0 : item.restock_alert,
        item === null || item === void 0 ? void 0 : item.unit,
        item === null || item === void 0 ? void 0 : item.subunit,
        item === null || item === void 0 ? void 0 : item.multiplier,
    ]);
    return (_jsx("div", { children: _jsxs(Card, __assign({ className: " shadow-2xl rounded-xl flex flex-col md:flex-row gap:6 " }, { children: [_jsx(CardMedia, { component: "img", sx: {
                        padding: "1em 1em 1em 1em",
                        objectFit: "contain",
                        height: "12rem",
                        width: 200,
                    }, image: item.images.length > 0
                        ? "".concat(url, "/product/file/").concat(item.images[0])
                        : "https://i.ibb.co/PrxWFNt/facewash.png", title: "", 
                    // onClick={() => navigate(`/product_details/${productKey}`)}
                    alt: "type-2 card" }), _jsx(Box, __assign({ sx: { display: "flex", flexDirection: "column" }, className: " w-full" }, { children: _jsxs(CardContent, __assign({ className: "flex flex-col lg:flex-row lg:gap-6 gap:6 items-start" }, { children: [_jsxs("div", __assign({ style: { flex: "1 0 auto" } }, { children: [_jsx(Typography, __assign({ variant: "h6", className: "medium" }, { children: item === null || item === void 0 ? void 0 : item.name })), _jsxs(Typography, __assign({ variant: "h6" }, { children: ["\u09F3 ", (_a = item === null || item === void 0 ? void 0 : item.price) === null || _a === void 0 ? void 0 : _a.value] })), _jsx("section", __assign({ style: {
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "0 0.5rem",
                                            marginTop: "10px",
                                        } }, { children: _jsx(Typography, __assign({ className: " " }, { children: (item === null || item === void 0 ? void 0 : item.filters) &&
                                                Object.entries(item === null || item === void 0 ? void 0 : item.filters).map(function (_a, index) {
                                                    var key = _a[0], values = _a[1];
                                                    return (_jsxs("div", __assign({ className: "sm:grid sm:grid-cols-12 flex gap-2 items-center" }, { children: [_jsx(Typography, __assign({ className: "col-span-4" }, { children: _jsx("b", { children: Boolean(key)
                                                                        ? key
                                                                            .split(" ")
                                                                            .map(function (k) {
                                                                            return k ? k[0].toUpperCase() + k.slice(1) : "";
                                                                        })
                                                                            .join(" ") + ": "
                                                                        : "" }) })), _jsx(Typography, __assign({ className: "col-span-7" }, { children: values
                                                                    .map(function (v) {
                                                                    return v
                                                                        .split(" ")
                                                                        .map(function (k) {
                                                                        return k ? k[0].toUpperCase() + k.slice(1) : "";
                                                                    })
                                                                        .join(" ");
                                                                })
                                                                    .join(", ") }))] }), index));
                                                }) })) }))] })), _jsxs(Box, __assign({ sx: {
                                    display: "flex",
                                    alignItems: "center",
                                    border: "1px solid",
                                    borderColor: "divider",
                                    borderRadius: 2,
                                    bgcolor: "background.paper",
                                    color: "text.secondary",
                                    "& svg": {
                                        m: 1,
                                        marginTop: "1rem",
                                    },
                                } }, { children: [inStock &&
                                        _jsxs(_Fragment, { children: [_jsx(IconButton, __assign({ onClick: function () { return wishListToCart(item, 1); } }, { children: _jsx(ShoppingCartIcon, { className: " text-yellow-600" }) })), _jsx(Divider, { orientation: "vertical", variant: "middle", flexItem: true })] }), _jsx(IconButton, __assign({ onClick: function () { return deleteWishListProduct(item); } }, { children: _jsx(AiFillDelete, { className: " text-red-500" }) }))] }))] })) }))] })) }));
};
export default WishlistCard;

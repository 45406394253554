var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FeaturedInfo from "../../../Components/FeaturedInfo/FeaturedInfo";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CategoryIcon from '@mui/icons-material/Category';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Featured from '../../../Components/featured/Featured';
import WidgetSm from '../../../Components/widgetSm/WidgetSm';
import WidgetLg from '../../../Components/widgetLg/WidgetLg';
var Dashboard = function () {
    var data = [
        {
            name: "Customers", value: 213, buttonTag: "See all customers", link: "/admin/customers", icon: _jsx(PersonOutlineIcon, { className: ' text-rose-600' })
        },
        {
            name: "Orders", value: 3146, buttonTag: "View all orders", link: "/admin/orders", icon: _jsx(AssessmentIcon, { className: 'text-cyan-600' })
        },
        {
            name: "Employees", value: 12, buttonTag: "View all Employees", link: "/admin/employee", icon: _jsx(CategoryIcon, { className: ' text-yellow-500' })
        },
        {
            name: "Products", value: 560, buttonTag: "View all Products", link: "/admin/product", icon: _jsx(AddShoppingCartIcon, { className: ' text-violet-800' })
        },
    ];
    return (_jsxs("div", __assign({ className: ' mb-24' }, { children: [_jsx("div", __assign({ className: "flex p-5 gap-3 " }, { children: data === null || data === void 0 ? void 0 : data.map(function (item, id) { return (_jsx(FeaturedInfo, { items: item }, id)); }) })), _jsx("div", __assign({ className: " px-5 py-4 " }, { children: _jsx(Featured, {}) })), _jsxs("div", __assign({ className: 'flex gap-4 px-5 py-3' }, { children: [_jsx(WidgetSm, {}), _jsx(WidgetLg, {})] }))] })));
};
export default Dashboard;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
var GlobalSnackbar = function (props) {
    var _a = props.verticalPosition, verticalPosition = _a === void 0 ? 'top' : _a, _b = props.horizontalPosition, horizontalPosition = _b === void 0 ? 'center' : _b, message = props.message, alertType = props.alertType, onfinish = props.onfinish;
    var _c = useState(true), open = _c[0], setOpen = _c[1];
    var _d = React.useState({
        vertical: verticalPosition,
        horizontal: horizontalPosition,
    }), snackState = _d[0], setSnackState = _d[1];
    var vertical = snackState.vertical, horizontal = snackState.horizontal;
    // const handleClick = (newState: SnackbarOrigin) => () => {
    //     setSnackState({ ...newState, open: true });
    // };
    var handleClose = function () {
        console.log("Clicked Close");
        setOpen(false);
        setSnackState(__assign({}, snackState));
        onfinish();
    };
    return (_jsx("div", { children: _jsx(Snackbar, __assign({ anchorOrigin: { vertical: vertical, horizontal: horizontal }, open: open, onClose: handleClose, autoHideDuration: 2000 }, { children: _jsx(Alert, __assign({ onClose: handleClose, severity: alertType, sx: { width: '100%' } }, { children: message })) }), vertical + horizontal) }));
};
export default GlobalSnackbar;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import StyledTextField from '../../../../Components/StyledTextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from "@mui/x-date-pickers";
import { Autocomplete, createFilterOptions, Button, FormControl, InputLabel, MenuItem, DialogTitle, Dialog, DialogContent, Typography, InputAdornment } from '@mui/material';
import Select from '@mui/material/Select';
import { promoCreation, updatePromo } from '../../../../ApiGateways/promo';
import { searchProduct } from '../../../../ApiGateways/product';
import { url } from '../../../../config';
import dayjs from 'dayjs';
var ManagePromoForm = function (props) {
    var _a, _b, _c, _d, _e, _f;
    var open = props.open, onClose = props.onClose, editValue = props.editValue, updatePromoList = props.updatePromoList, sx = props.sx, contentSx = props.contentSx;
    var filter = createFilterOptions();
    var _g = useState([]), searchResults = _g[0], setSearchResults = _g[1];
    var _h = useState([]), retrieved = _h[0], setRetrieved = _h[1];
    var _j = useState(null), singleRetrieved = _j[0], setSingleRetrieved = _j[1];
    var debounceTimeout;
    var _k = useState({
        id: '',
        type: 'discount',
        code: '',
        product_ids: [],
        product_info: [],
        day: [],
        frequency: '',
        price_limit: {
            value: 0,
            unit: 'BDT'
        },
        validation_date_range: {
            value_from: "",
            value_to: ""
        },
    }), promoData = _k[0], setPromoData = _k[1];
    var searchWithDelay = function (key) {
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(function () {
            searchProduct(String(key), function (data) {
                setSearchResults(data === null || data === void 0 ? void 0 : data.data);
            }, function (res) { return console.log(res); });
        }, 500);
    };
    var weekdays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    var frequencyTypes = ["daily", "weekly", "biweekly", "monthly"];
    var formClose = function () {
        setPromoData({
            id: '',
            type: 'discount',
            product_info: [],
            day: [],
            frequency: '',
            price_limit: {
                value: 0,
                unit: 'BDT'
            },
            validation_date_range: {
                value_from: "",
                value_to: ""
            },
        });
        setRetrieved([]);
        onClose();
    };
    var submitPromo = function (event) {
        var _a, _b, _c, _d;
        event.preventDefault();
        var body = {
            type: promoData === null || promoData === void 0 ? void 0 : promoData.type,
            code: promoData === null || promoData === void 0 ? void 0 : promoData.code,
            product_ids: promoData === null || promoData === void 0 ? void 0 : promoData.product_ids,
            discount_percentage: promoData === null || promoData === void 0 ? void 0 : promoData.discount_percentage,
            discount_value: promoData === null || promoData === void 0 ? void 0 : promoData.discount_value,
            day: promoData === null || promoData === void 0 ? void 0 : promoData.day,
            frequency: promoData === null || promoData === void 0 ? void 0 : promoData.frequency,
            price_limit: promoData === null || promoData === void 0 ? void 0 : promoData.price_limit,
            validation_date_range: {
                value_from: (_a = promoData.validation_date_range) === null || _a === void 0 ? void 0 : _a.value_from,
                value_to: (_b = promoData.validation_date_range) === null || _b === void 0 ? void 0 : _b.value_to
            },
            validation_time_range: {
                value_from: (_c = promoData === null || promoData === void 0 ? void 0 : promoData.validation_time_range) === null || _c === void 0 ? void 0 : _c.value_from,
                value_to: (_d = promoData === null || promoData === void 0 ? void 0 : promoData.validation_time_range) === null || _d === void 0 ? void 0 : _d.value_to
            }
        };
        if (editValue && (editValue === null || editValue === void 0 ? void 0 : editValue.id)) {
            updatePromo(JSON.stringify(body), editValue === null || editValue === void 0 ? void 0 : editValue.id, function (data) {
                if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                    updatePromoList(data === null || data === void 0 ? void 0 : data.data);
                    formClose();
                }
            }, function (res) { return console.log(res); });
        }
        else {
            promoCreation(JSON.stringify(body), function (data) {
                if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                    updatePromoList(data === null || data === void 0 ? void 0 : data.data);
                    formClose();
                }
            }, function (res) { return console.log(res); });
        }
    };
    useEffect(function () {
        var _a;
        if (editValue) {
            setPromoData(__assign({}, editValue));
            var product = (_a = editValue === null || editValue === void 0 ? void 0 : editValue.product_info) === null || _a === void 0 ? void 0 : _a.map(function (item, i) { return (item); });
            setRetrieved(product);
            setSingleRetrieved(product[0]);
            setSearchResults(product);
        }
    }, [editValue]);
    console.log(singleRetrieved);
    var noNegative = function (value) {
        if (value > 0)
            return Number(value);
        else
            return 0;
    };
    return (_jsxs(Dialog, __assign({ open: open, onClose: formClose, sx: sx }, { children: [_jsx(DialogTitle, { children: _jsx(Typography, __assign({ variant: "h4" }, { children: editValue ? "Update Promo" : "Insert Promo" })) }), _jsx(DialogContent, __assign({ sx: contentSx }, { children: _jsxs("form", __assign({ onSubmit: submitPromo, style: { display: "grid", gridTemplateColumns: "auto", gap: "0.75rem 0", margin: "0.75rem 0" } }, { children: [_jsxs(FormControl, __assign({ fullWidth: true }, { children: [_jsx(InputLabel, __assign({ id: "demo-simple-select-label" }, { children: "Promo Type" })), _jsxs(Select, __assign({ required: true, labelId: "demo-simple-select-label", id: "demo-simple-select", value: promoData.type, label: "Promo Type", onChange: function (e) { return setPromoData(__assign(__assign({}, promoData), { type: e.target.value })); } }, { children: [_jsx(MenuItem, __assign({ value: "product" }, { children: "Product" })), _jsx(MenuItem, __assign({ value: "discount" }, { children: "Discount" })), _jsx(MenuItem, __assign({ value: "product_base" }, { children: "Product Base" }))] }))] })), _jsxs("section", __assign({ style: { display: "flex", gap: "0 1rem", alignItems: "center" } }, { children: [_jsx(StyledTextField, { value: promoData === null || promoData === void 0 ? void 0 : promoData.code, onChange: function (e) { return setPromoData(__assign(__assign({}, promoData), { code: e.target.value })); }, fullWidth: true, label: "Promo Code" }), _jsx(StyledTextField, { required: true, type: 'number', value: (_a = promoData.price_limit) === null || _a === void 0 ? void 0 : _a.value, onChange: function (e) { return setPromoData(__assign(__assign({}, promoData), { price_limit: { value: noNegative(Number(e.target.value)), unit: 'BDT' } })); }, fullWidth: true, label: "Price Limit", InputProps: {
                                        startAdornment: (_jsx(InputAdornment, __assign({ position: 'start' }, { children: "BDT" })))
                                    } })] })), (promoData === null || promoData === void 0 ? void 0 : promoData.type) && ((promoData === null || promoData === void 0 ? void 0 : promoData.type) === 'product') &&
                            _jsx(Autocomplete, { value: retrieved || [], options: searchResults, fullWidth: true, freeSolo: true, multiple: true, onChange: function (event, newValue) {
                                    var productIdArray = newValue === null || newValue === void 0 ? void 0 : newValue.map(function (item) { return (item === null || item === void 0 ? void 0 : item._id) || (item === null || item === void 0 ? void 0 : item.id); }).filter(function (id) { return id !== undefined; });
                                    var productNameArray = newValue === null || newValue === void 0 ? void 0 : newValue.map(function (item) { return item; }).filter(function (item) { return (item === null || item === void 0 ? void 0 : item._id) !== undefined || (item === null || item === void 0 ? void 0 : item.id) !== undefined; });
                                    setRetrieved(productNameArray);
                                    setPromoData(__assign(__assign({}, promoData), { product_ids: productIdArray }));
                                }, autoHighlight: true, getOptionLabel: function (option) { return option === null || option === void 0 ? void 0 : option.name; }, filterOptions: function (options, _a) {
                                    var inputValue = _a.inputValue;
                                    return options;
                                }, renderOption: function (props, option) { return (_jsx("li", __assign({}, props, { children: _jsxs("span", __assign({ style: { display: "flex", gap: "2rem", alignItems: "center" } }, { children: [_jsx("img", { style: { width: "1.5rem", height: "1.5rem" }, src: (option === null || option === void 0 ? void 0 : option.images.length) > 0 ? "".concat(url, "/product/file/").concat(option.images[0]) : "", alt: "" }), option === null || option === void 0 ? void 0 : option.name] })) }))); }, renderInput: function (params) { return (_jsx(StyledTextField, __assign({}, params, { onChange: function (e) { searchWithDelay(e.target.value); }, label: "Product", fullWidth: true, sx: { margin: "0.75rem 0" } }))); } }), (promoData === null || promoData === void 0 ? void 0 : promoData.type) && ((promoData === null || promoData === void 0 ? void 0 : promoData.type) === 'product_base') &&
                            _jsx(Autocomplete, { value: singleRetrieved, options: searchResults, fullWidth: true, freeSolo: true, onChange: function (event, newValue) {
                                    setSingleRetrieved(newValue);
                                    setPromoData(__assign(__assign({}, promoData), { product_ids: [newValue === null || newValue === void 0 ? void 0 : newValue.id] }));
                                }, autoHighlight: true, getOptionLabel: function (option) { return option === null || option === void 0 ? void 0 : option.name; }, filterOptions: function (options, _a) {
                                    var inputValue = _a.inputValue;
                                    return options;
                                }, renderOption: function (props, option) { return (_jsx("li", __assign({}, props, { children: _jsxs("span", __assign({ style: { display: "flex", gap: "2rem", alignItems: "center" } }, { children: [_jsx("img", { style: { width: "1.5rem", height: "1.5rem" }, src: (option === null || option === void 0 ? void 0 : option.images.length) > 0 ? "".concat(url, "/product/file/").concat(option.images[0]) : "", alt: "" }), option === null || option === void 0 ? void 0 : option.name] })) }))); }, renderInput: function (params) { return (_jsx(StyledTextField, __assign({}, params, { onChange: function (e) { searchWithDelay(e.target.value); }, label: "Product", fullWidth: true, sx: { margin: "0.75rem 0" } }))); } }), _jsxs("section", __assign({ style: { display: "flex", gap: "0 0.75rem", alignItems: "center" } }, { children: [_jsx(StyledTextField, { required: true, type: 'number', value: promoData.discount_percentage, onChange: function (e) { return setPromoData(__assign(__assign({}, promoData), { discount_percentage: noNegative(Number(e.target.value)) })); }, fullWidth: true, label: "Discount Percentage", InputProps: {
                                        endAdornment: (_jsx(InputAdornment, __assign({ position: 'end' }, { children: "%" })))
                                    } }), _jsx(StyledTextField, { required: true, type: 'number', value: (_b = promoData.discount_value) === null || _b === void 0 ? void 0 : _b.value, onChange: function (e) { return setPromoData(__assign(__assign({}, promoData), { discount_value: { value: noNegative(Number(e.target.value)), unit: 'BDT' } })); }, fullWidth: true, label: "Discount Value", InputProps: {
                                        startAdornment: (_jsx(InputAdornment, __assign({ position: 'start' }, { children: "BDT" })))
                                    } })] })), _jsxs("section", __assign({ style: { display: "flex", gap: "0 0.75rem", alignItems: "center" } }, { children: [_jsx(Autocomplete, { options: __spreadArray([], weekdays, true), value: promoData.day, onChange: function (event, newValue) { return setPromoData(__assign(__assign({}, promoData), { day: __spreadArray([], newValue, true) })); }, fullWidth: true, selectOnFocus: true, clearOnBlur: true, handleHomeEndKeys: true, multiple: true, getOptionLabel: function (option) { return option; }, filterOptions: function (options, params) {
                                        var filtered = filter(options, params);
                                        var inputValue = params.inputValue;
                                        var isExisting = options.some(function (option) { return inputValue === option; });
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push(inputValue);
                                        }
                                        return filtered;
                                    }, renderInput: function (params) { return (_jsx(StyledTextField, __assign({}, params, { label: "Days", fullWidth: true, sx: { margin: "0.5rem 0" } }))); } }), _jsx(Autocomplete, { value: promoData.frequency, options: frequencyTypes || [], onChange: function (event, newValue) { return setPromoData(__assign(__assign({}, promoData), { frequency: newValue || undefined })); }, fullWidth: true, selectOnFocus: true, clearOnBlur: true, handleHomeEndKeys: true, getOptionLabel: function (option) { return option; }, filterOptions: function (options, params) {
                                        var filtered = filter(options, params);
                                        var inputValue = params.inputValue;
                                        var isExisting = options.some(function (option) { return inputValue === option; });
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push(inputValue);
                                        }
                                        return filtered;
                                    }, renderInput: function (params) { return (_jsx(StyledTextField, __assign({}, params, { fullWidth: true, label: "Frequency" }))); } })] })), _jsxs("section", __assign({ style: { display: "flex", gap: "0 1rem", alignItems: "center" } }, { children: [_jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsx(DatePicker, { label: "Start Date", value: promoData.validation_date_range.value_from ? dayjs(promoData.validation_date_range.value_from) : undefined, onChange: function (value) { return setPromoData(__assign(__assign({}, promoData), { validation_date_range: __assign(__assign({}, promoData.validation_date_range), { value_from: (value === null || value === void 0 ? void 0 : value.toISOString()) || dayjs().toISOString() }) })); }, sx: { width: "100%" } }) })), _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsx(DatePicker, { label: "End Date", value: promoData.validation_date_range.value_to ? dayjs(promoData.validation_date_range.value_to) : undefined, onChange: function (value) { return setPromoData(__assign(__assign({}, promoData), { validation_date_range: __assign(__assign({}, promoData.validation_date_range), { value_to: (value === null || value === void 0 ? void 0 : value.toISOString()) || dayjs().toISOString() }) })); }, sx: { width: "100%" } }) }))] })), _jsxs("section", __assign({ style: { display: "flex", gap: "0 1rem", alignItems: "center" } }, { children: [_jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsx(TimePicker, { label: "Validation Start", onChange: function (newValue) {
                                            setPromoData(__assign(__assign({}, promoData), { validation_time_range: __assign(__assign({}, (promoData === null || promoData === void 0 ? void 0 : promoData.validation_time_range) || {
                                                    value_from: "",
                                                    value_to: ""
                                                }), { value_from: (newValue === null || newValue === void 0 ? void 0 : newValue.toISOString()) || dayjs().toISOString() }) }));
                                        }, value: ((_c = promoData === null || promoData === void 0 ? void 0 : promoData.validation_time_range) === null || _c === void 0 ? void 0 : _c.value_from) ? dayjs((_d = promoData === null || promoData === void 0 ? void 0 : promoData.validation_time_range) === null || _d === void 0 ? void 0 : _d.value_from) : undefined, sx: { width: "100%" } }) })), _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsx(TimePicker, { label: "Validation End", onChange: function (newValue) {
                                            setPromoData(__assign(__assign({}, promoData), { validation_time_range: __assign(__assign({}, (promoData === null || promoData === void 0 ? void 0 : promoData.validation_time_range) || {
                                                    value_from: "",
                                                    value_to: ""
                                                }), { value_to: (newValue === null || newValue === void 0 ? void 0 : newValue.toISOString()) || dayjs().toISOString() }) }));
                                        }, value: ((_e = promoData === null || promoData === void 0 ? void 0 : promoData.validation_time_range) === null || _e === void 0 ? void 0 : _e.value_to) ? dayjs((_f = promoData === null || promoData === void 0 ? void 0 : promoData.validation_time_range) === null || _f === void 0 ? void 0 : _f.value_to) : undefined, sx: { width: "100%" } }) }))] })), _jsxs("section", __assign({ style: { display: "flex", gap: "0 1rem", alignItems: "center", width: "100%", justifyContent: "space-between" } }, { children: [_jsxs("article", __assign({ style: { display: "flex", gap: "0 1rem", alignItems: "center" } }, { children: [_jsxs(Button, __assign({ type: "submit", variant: "contained", color: 'info' }, { children: [editValue ? "Update Promo" : "Save Promo", " "] })), _jsx(Button, __assign({ variant: "outlined", color: 'info', type: 'reset' }, { children: "Clear" }))] })), _jsx(Button, __assign({ variant: 'contained', onClick: formClose }, { children: "Close" }))] }))] })) }))] })));
};
export default ManagePromoForm;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import './App.css';
import { RouterProvider } from "react-router-dom";
import router from "./Routes/Routes";
import { getCurrentOrganization } from './ApiGateways/organization';
import 'react-phone-number-input/style.css';
import { ThemeProvider, createTheme } from '@mui/material';
var theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#881337',
        },
        secondary: {
            main: '#f50057',
        },
    },
});
function App() {
    useEffect(function () {
        getCurrentOrganization(function (data) {
            var _a;
            localStorage.setItem('organization', (_a = data.data) === null || _a === void 0 ? void 0 : _a.id);
        }, function (res) { return console.log(res); });
    }, []);
    return (_jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsx(RouterProvider, { router: router }) })));
}
export default App;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useRef, useContext } from "react";
import ProductCard from "../../../Components/ProductCard";
import { Checkbox, FormGroup, FormControlLabel, Typography, Button, Drawer, Pagination, Slider, Rating, TextField } from "@mui/material";
import { getFilteredProduct, getProductView } from "../../../ApiGateways/product";
import { ChevronRightRounded, ExpandMore } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setProducts } from "../../../Redux/features/productsSlice";
import { getWishlistByUser } from "../../../ApiGateways/wishlist";
import GlobalSnackbar from "../../../Components/GlobalSnackbar";
import { Context } from "../../../State/Provider";
var MAX_PRICE_LIMIT = 10000;
var ITEM_PER_PAGE = 12;
function FilterTab(props) {
    var filters = props.filters, expandedKeys = props.expandedKeys, setExpandedKeys = props.setExpandedKeys, reset = props.reset, setReset = props.setReset, setSelectedFilters = props.setSelectedFilters, setPage = props.setPage, setTotalPage = props.setTotalPage, checkboxStatus = props.checkboxStatus, setCheckboxStatus = props.setCheckboxStatus, range = props.range, setRange = props.setRange, rating = props.rating, setRating = props.setRating, categoryName = props.categoryName, page = props.page;
    // const [checkboxStatus, setCheckboxStatus] = useState<Record<string, Record<string, boolean>>>({});
    // const [priceRange, setPriceRange] = useState<[number, number]>([0, 100000]);
    // const [range, setRange] = useState({
    //   minPrice:0,
    //   maxPrice:100000
    // })
    // const [rating, setRating] = useState<number | null>(null); 
    var dispatch = useDispatch();
    var clearFilters = function () {
        setRange({ minPrice: 0, maxPrice: MAX_PRICE_LIMIT });
        setRating(null);
        setCheckboxStatus({});
        setSelectedFilters(null);
        setReset(!reset);
    };
    var handleRatingChange = function (event, newRating) {
        setRating(newRating);
    };
    var handlePriceRangeChange = function (event, newValue) {
        // console.log(newValue)
        if (Array.isArray(newValue)) {
            setRange({
                minPrice: newValue[0],
                maxPrice: newValue[1]
            });
        }
    };
    var noNegative = function (value) {
        if (value > 0)
            return Number(value);
        else
            return 0;
    };
    var handleMinPriceInputChange = function (value) {
        if (value > MAX_PRICE_LIMIT) {
            value = MAX_PRICE_LIMIT;
        }
        setRange(__assign(__assign({}, range), { minPrice: value }));
    };
    var handleMaxPriceInputChange = function (value) {
        if (value > MAX_PRICE_LIMIT) {
            value = MAX_PRICE_LIMIT;
        }
        else if (value <= 0) {
            value = 1;
        }
        setRange(__assign(__assign({}, range), { maxPrice: value }));
    };
    var handleChange = function (sectionKey, checkboxValue) {
        // setCheckboxStatus((prev) => ({
        //   ...prev,
        //   [sectionKey]: {
        //     ...prev[sectionKey],
        //     [checkboxValue]: !prev[sectionKey]?.[checkboxValue],
        //   },
        // }));
        setCheckboxStatus(function (prev) {
            var _a, _b;
            var _c;
            var updatedCheckboxStatus = __assign(__assign({}, prev), (_a = {}, _a[sectionKey] = __assign(__assign({}, prev[sectionKey]), (_b = {}, _b[checkboxValue] = !((_c = prev[sectionKey]) === null || _c === void 0 ? void 0 : _c[checkboxValue]), _b)), _a));
            var selectedValues = Object.fromEntries(Object.entries(updatedCheckboxStatus)
                .map(function (_a) {
                var key = _a[0], values = _a[1];
                return [key, values && typeof values === 'object' ? Object.keys(values).filter(function (value) { return values[value]; }) : null];
            })
                .filter(function (_a) {
                var key = _a[0], values = _a[1];
                return values && values.length > 0;
            }));
            var result = Object.keys(selectedValues).length > 0 ? selectedValues : null;
            // console.log(result);
            setSelectedFilters(result);
            getFilteredProduct(result, categoryName, page, ITEM_PER_PAGE, String(range.minPrice), String(range.maxPrice), rating, function (data) {
                var _a, _b, _c;
                console.log(data);
                setPage((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.page);
                setTotalPage((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.total_page);
                dispatch(setProducts((_c = data === null || data === void 0 ? void 0 : data.data) === null || _c === void 0 ? void 0 : _c.products));
            }, function (res) { return console.log(res); });
            return updatedCheckboxStatus;
        });
    };
    return (_jsxs("section", __assign({ className: "rounded-2xl shadow-xl", style: { minWidth: "250px", backgroundColor: "#FFFFFF", padding: "0.5rem", border: "1px solid #9E9E9E" } }, { children: [_jsx("div", __assign({ style: { display: 'flex', justifyContent: 'flex-end' } }, { children: _jsx(Button, __assign({ onClick: clearFilters, variant: "outlined", size: "small", sx: {
                        marginTop: '1rem',
                        textTransform: "none",
                        color: "black",
                        border: "1px solid black"
                    } }, { children: "Clear Filters" })) })), _jsxs("article", __assign({ style: { padding: "0.5rem 0 0.5rem 1rem" } }, { children: [_jsx(Typography, __assign({ variant: "h5", sx: { textTransform: "none" } }, { children: "Rating" })), _jsx(Rating, { value: rating, onChange: handleRatingChange, sx: { marginTop: "1rem" } })] })), _jsxs("article", __assign({ style: { padding: "0.5rem 0 0.5rem 1rem", display: "flex", flexDirection: "column", gap: "10px" } }, { children: [_jsx(Typography, __assign({ variant: "h5" }, { children: "Price Range" })), _jsx(Slider, { value: [range.minPrice, range.maxPrice], onChange: handlePriceRangeChange, valueLabelDisplay: "auto", "aria-labelledby": "range-slider", min: 0, max: MAX_PRICE_LIMIT, step: 100, style: { maxWidth: "90%", marginLeft: "0.5rem", color: "black" } }), _jsxs("section", __assign({ style: { display: "flex", justifyContent: "space-between", gap: "3rem" } }, { children: [_jsx(TextField, { type: "number", value: range === null || range === void 0 ? void 0 : range.minPrice, onChange: function (e) { return handleMinPriceInputChange(noNegative(Number(e.target.value))); }, label: "Min Price", variant: "outlined", size: "small", style: { marginTop: '1rem' } }), _jsx(TextField, { type: "number", value: range === null || range === void 0 ? void 0 : range.maxPrice, onChange: function (e) { return handleMaxPriceInputChange(noNegative(Number(e.target.value))); }, label: "Max Price", variant: "outlined", size: "small", style: { marginTop: '1rem' } })] }))] })), Object.entries(filters).map(function (_a, index) {
                var key = _a[0], values = _a[1];
                return (_jsxs("article", __assign({ style: { padding: "0.5rem 0 0.5rem 1rem" } }, { children: [_jsxs("section", __assign({ onClick: function () { return setExpandedKeys(function (prev) {
                                var _a;
                                return (__assign(__assign({}, prev), (_a = {}, _a[key] = !prev[key], _a)));
                            }); }, style: { cursor: "pointer", display: "flex", flexFlow: "row nowrap", alignItems: "center", gap: "0 0.25rem", padding: "0.25rem 0" } }, { children: [expandedKeys[key] ?
                                    _jsx(ExpandMore, { style: { marginRight: "0.25rem" } }) :
                                    _jsx(ChevronRightRounded, { style: { marginRight: "0.25rem" } }), _jsx(Typography, __assign({ variant: "h5", sx: { textTransform: "none" } }, { children: key.split(" ").map(function (k) { return k ? k[0].toUpperCase() + k.slice(1) : ""; }).join(" ") }))] })), _jsx(FormGroup, __assign({ style: { display: expandedKeys[key] ? "flex" : "none", overflowY: 'auto' } }, { children: values.map(function (value, index) {
                                var _a;
                                return (_jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: ((_a = checkboxStatus[key]) === null || _a === void 0 ? void 0 : _a[value]) || false, onChange: function () { return handleChange(key, value); } }), label: _jsx(Typography, __assign({ variant: "subtitle1", sx: { textTransform: "none" } }, { children: value.split(" ").map(function (k) { return k ? k[0].toUpperCase() + k.slice(1) : ""; }).join(" ") })), sx: { marginLeft: "0.5rem" } }, index));
                            }) }))] }), index));
            })] })));
}
var Product = function () {
    var products = useSelector(function (state) { return state.productState; });
    var dispatch = useDispatch();
    var _a = useState(), debounceTimeout = _a[0], setDebounceTimeout = _a[1];
    var setAllWish = useContext(Context).setAllWish;
    var _b = useState({}), filters = _b[0], setFilters = _b[1];
    var _c = useState(["All"]), categoryType = _c[0], setCategoryType = _c[1];
    var _d = useState("All"), categorySelect = _d[0], setCategorySelect = _d[1];
    var _e = useState({}), expandedKeys = _e[0], setExpandedKeys = _e[1];
    var _f = useState({}), checkboxStatus = _f[0], setCheckboxStatus = _f[1];
    var _g = useState(false), openFilter = _g[0], setOpenFilter = _g[1];
    var _h = useState(1), page = _h[0], setPage = _h[1];
    var _j = useState(0), totalPage = _j[0], setTotalPage = _j[1];
    var _k = useState(false), reset = _k[0], setReset = _k[1];
    var _l = useState(null), selectedFilters = _l[0], setSelectedFilters = _l[1];
    var _m = useState([]), allWishListId = _m[0], setAllWishListId = _m[1];
    var _o = useState({
        minPrice: 0,
        maxPrice: MAX_PRICE_LIMIT
    }), range = _o[0], setRange = _o[1];
    var _p = useState(''), categoryName = _p[0], setCategoryName = _p[1];
    var _q = useState(null), rating = _q[0], setRating = _q[1];
    var _r = useState({
        isActive: false,
        verticalPosition: "top",
        horizontalPosition: "center",
        message: "",
        alertType: "success",
    }), snackbarState = _r[0], setSnackbarState = _r[1];
    var pageRef = useRef(null);
    var scrollToTop = function () {
        var currentPosition = window.scrollY;
        if (currentPosition > 0) {
            window.scrollTo(0, currentPosition - 20);
            window.requestAnimationFrame(scrollToTop);
        }
    };
    useEffect(function () {
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        var timeoutId = setTimeout(function () {
            getFilteredProduct(selectedFilters, categoryName, page, ITEM_PER_PAGE, String(range.minPrice), String(range.maxPrice), rating, function (value) {
                var _a, _b, _c;
                dispatch(setProducts((_a = value === null || value === void 0 ? void 0 : value.data) === null || _a === void 0 ? void 0 : _a.products));
                setPage((_b = value === null || value === void 0 ? void 0 : value.data) === null || _b === void 0 ? void 0 : _b.page);
                setTotalPage((_c = value === null || value === void 0 ? void 0 : value.data) === null || _c === void 0 ? void 0 : _c.total_page);
            }, function (res) { return console.log(res); });
        }, 1000);
        setDebounceTimeout(timeoutId);
        return function () {
            clearTimeout(timeoutId);
        };
    }, [page, reset, rating, categoryName, range === null || range === void 0 ? void 0 : range.minPrice, range === null || range === void 0 ? void 0 : range.maxPrice]);
    useEffect(function () {
        getProductView(function (value) {
            if (value.data.filters) {
                setCategoryType(__spreadArray(__spreadArray([], categoryType, true), value.data.filters.categories, true));
                setFilters(value.data.filters.filters);
                setExpandedKeys(Object.keys(value.data.filters.filters).reduce(function (acc, key) {
                    var _a;
                    return (__assign(__assign({}, acc), (_a = {}, _a[key] = true, _a)));
                }, {}));
            }
        }, function (res) { return console.log(res); });
    }, []);
    useEffect(function () {
        getWishlistByUser(function (data) {
            var _a, _b, _c;
            setAllWish((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items);
            var allId = (_c = ((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.items)) === null || _c === void 0 ? void 0 : _c.map(function (item, i) { return item === null || item === void 0 ? void 0 : item.id; });
            setAllWishListId(allId);
        }, function (res) {
            setAllWish([]);
            console.log(res);
        });
    }, [reset]);
    var handleSelectCategory = function (category) {
        setCategorySelect(category === "All" ? "All" : category);
        if (category === "All") {
            setCategoryName("");
            getFilteredProduct(selectedFilters, "", page, ITEM_PER_PAGE, String(range.minPrice), String(range.maxPrice), rating, function (value) {
                var _a, _b, _c;
                dispatch(setProducts((_a = value === null || value === void 0 ? void 0 : value.data) === null || _a === void 0 ? void 0 : _a.products));
                setPage((_b = value === null || value === void 0 ? void 0 : value.data) === null || _b === void 0 ? void 0 : _b.page);
                setTotalPage((_c = value === null || value === void 0 ? void 0 : value.data) === null || _c === void 0 ? void 0 : _c.total_page);
            }, function (res) { return console.log(res); });
            // getAllProduct(page, 12, "",
            //   (value) => {
            //     dispatch(setProducts(value?.data?.products));
            //     setPage(value?.data?.page);
            //     setTotalPage(value?.data?.total_page);
            //   },
            //   (res) => console.log(res))
        }
        else {
            setCategoryName(category);
            getFilteredProduct(selectedFilters, category, page, ITEM_PER_PAGE, String(range.minPrice), String(range.maxPrice), rating, function (value) {
                var _a, _b, _c;
                dispatch(setProducts((_a = value === null || value === void 0 ? void 0 : value.data) === null || _a === void 0 ? void 0 : _a.products));
                setPage((_b = value === null || value === void 0 ? void 0 : value.data) === null || _b === void 0 ? void 0 : _b.page);
                setTotalPage((_c = value === null || value === void 0 ? void 0 : value.data) === null || _c === void 0 ? void 0 : _c.total_page);
            }, function (res) { return console.log(res); });
            //   searchProduct(category,
            //   (data) => {
            //     dispatch(setProducts(data?.data));
            //   },
            //   res => console.log(res)
            // );
        }
    };
    var handlePageChange = function (newPage) {
        setPage(newPage);
        scrollToTop();
    };
    return (_jsxs("main", __assign({ className: "mx-6 lg:mx-12 my-8", ref: pageRef }, { children: [_jsx(_Fragment, { children: (snackbarState === null || snackbarState === void 0 ? void 0 : snackbarState.isActive) && (_jsx(GlobalSnackbar, { verticalPosition: snackbarState === null || snackbarState === void 0 ? void 0 : snackbarState.verticalPosition, horizontalPosition: snackbarState === null || snackbarState === void 0 ? void 0 : snackbarState.horizontalPosition, message: snackbarState === null || snackbarState === void 0 ? void 0 : snackbarState.message, alertType: snackbarState === null || snackbarState === void 0 ? void 0 : snackbarState.alertType, onfinish: function () {
                        setSnackbarState(__assign(__assign({}, snackbarState), { isActive: false }));
                    } })) }), _jsxs("div", { children: [_jsx(Typography, __assign({ className: "font-alegreya hidden lg:block", variant: "h4" }, { children: "Browse All Beauty Products" })), _jsx(Typography, __assign({ className: "text-rose-900 mt-4 font-alegreya hidden lg:block", variant: "h5" }, { children: "CATEGORIES" })), _jsxs("div", __assign({ className: "flex mt-4 justify-between" }, { children: [_jsx("section", __assign({ className: "overflow-x-auto" }, { children: _jsx("article", __assign({ className: "flex gap-x-2 w-fit lg:shadow-none p-2" }, { children: categoryType.map(function (category, index) { return (_jsx(Button, __assign({ variant: category !== categorySelect ? "outlined" : "contained", className: "".concat(category !== categorySelect
                                            ? "text-black border-black"
                                            : " bg-black text-white", " line-clamp-2 flex-shrink-0 break-all"), onClick: function (event) { return handleSelectCategory(category); }, size: "small", style: { whiteSpace: "normal", maxWidth: "10rem" } }, { children: category }), index)
                                    // <div className={` text-lg w-full px-4 items-center ${category !== categorySelect ? "text-rose-900 border-rose-900" : "bg-rose-900 text-white"}`}>
                                    //   {category}
                                    // </div>
                                    ); }) })) })), _jsx(Button, __assign({ className: "whitespace-nowrap rounded-none text-rose-900 block lg:hidden", size: "small", onClick: function (e) { return setOpenFilter(true); } }, { children: "+ Filters" }))] }))] }), _jsxs("div", __assign({ className: "grid grid-cols-4 gap-x-8 my-8" }, { children: [_jsx(Drawer, __assign({ anchor: "bottom", open: openFilter, onClose: function () { return setOpenFilter(false); }, className: "block lg:hidden col-span-1 row-span-4", PaperProps: {
                            style: {
                                maxHeight: "50%",
                                borderRadius: "1rem 1rem 0 0"
                            }
                        } }, { children: _jsx(FilterTab, { filters: filters, expandedKeys: expandedKeys, setExpandedKeys: setExpandedKeys, reset: reset, setReset: setReset, setSelectedFilters: setSelectedFilters, setPage: setPage, setTotalPage: setTotalPage, checkboxStatus: checkboxStatus, setCheckboxStatus: setCheckboxStatus, rating: rating, setRating: setRating, range: range, setRange: setRange, categoryName: categoryName, page: page }) })), _jsx("article", __assign({ className: "hidden lg:block col-span-1 row-span-4" }, { children: _jsx(FilterTab, { filters: filters, expandedKeys: expandedKeys, setExpandedKeys: setExpandedKeys, reset: reset, setReset: setReset, setSelectedFilters: setSelectedFilters, setPage: setPage, setTotalPage: setTotalPage, checkboxStatus: checkboxStatus, setCheckboxStatus: setCheckboxStatus, rating: rating, setRating: setRating, range: range, setRange: setRange, categoryName: categoryName, page: page }) })), _jsxs("article", __assign({ className: "col-span-4 lg:col-span-3 flex flex-col gap-12 items-center" }, { children: [_jsx("section", __assign({ className: "flex gap-6 flex-wrap justify-center" }, { children: products === null || products === void 0 ? void 0 : products.map(function (item, id) { return (_jsx(ProductCard, { item: item, inWishlist: allWishListId.some(function (wishlistId) { return wishlistId === (item === null || item === void 0 ? void 0 : item.id); }), 
                                    // snackbarState={snackbarState}
                                    // setSnackbarState={setSnackbarState}
                                    reset: reset, setReset: setReset }, id)); }) })), _jsx(Pagination, { count: totalPage, page: page, onChange: function (event, value) { handlePageChange(value); }, sx: {
                                    "li > button": {
                                        backgroundColor: "rgb(255 228 230)"
                                    },
                                    "li > button.Mui-selected": {
                                        backgroundColor: "#881337",
                                        color: "white"
                                    }
                                } })] }))] }))] })));
};
export default Product;

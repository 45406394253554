var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { getUser, refreahAccessToken } from "../ApiGateways/user";
import { setIsALoggedIn, setIsAdminLoggedIn } from "../Redux/features/singleStateSlice";
import { setUserState } from "../Redux/features/userSlice";
import { Box, CircularProgress, Typography } from "@mui/material";
var AdminRoute = function (_a) {
    var children = _a.children;
    var dispatch = useDispatch();
    var location = useLocation();
    var _b = useState(false), userLoaded = _b[0], setUserLoaded = _b[1];
    var _c = useState(null), userData = _c[0], setUserData = _c[1];
    var _d = useState(null), fetchError = _d[0], setFetchError = _d[1];
    var _e = useState(false), reset = _e[0], setReset = _e[1];
    var _f = useState(""), token = _f[0], setToken = _f[1];
    var _g = useState(0), count = _g[0], setCount = _g[1];
    useEffect(function () {
        refreahAccessToken(function (data) {
            var _a;
            setToken((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.access_token);
        }, function (res) { return console.log(res); });
    }, [reset]);
    useEffect(function () {
        getUser(function (data) {
            var _a;
            if ((data === null || data === void 0 ? void 0 : data.success) === true && ((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.user_type) === 'admin') {
                dispatch(setIsAdminLoggedIn(true));
                dispatch(setIsALoggedIn(true));
                dispatch(setUserState(data === null || data === void 0 ? void 0 : data.data));
                setUserData(data);
            }
            setUserLoaded(true);
        }, function (error) {
            console.error(error);
            if (token !== '' && count < 5) {
                localStorage.setItem('access-token', token);
                setReset(!reset);
                setToken("");
            }
            else if (token === '' && count < 5) {
                setReset(!reset);
            }
            else {
                setFetchError('Session Timeout');
                setUserLoaded(true);
            }
            setCount(count + 1);
        });
    }, [dispatch, children, reset]);
    if (fetchError) {
        dispatch(setIsAdminLoggedIn(false));
        dispatch(setIsALoggedIn(false));
        return (_jsxs("div", { children: [_jsx(Typography, __assign({ variant: "body1", color: "error" }, { children: fetchError })), _jsx(Navigate, { to: "/admin_login", state: { from: location }, replace: true }), ";"] }));
    }
    if (!userLoaded) {
        return _jsx(Box, __assign({ sx: { display: 'flex' } }, { children: _jsx("div", __assign({ className: " w-full flex justify-center mt-[45vh]" }, { children: _jsx(CircularProgress, {}) })) }));
    }
    if ((userData === null || userData === void 0 ? void 0 : userData.success) !== true) {
        return _jsx(Navigate, { to: "/admin_login", state: { from: location }, replace: true });
    }
    return children;
};
export default AdminRoute;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useContext } from 'react';
import { Typography, TableRow, TableCell } from '@mui/material';
import { getAllCustomer } from '../../../ApiGateways/user';
import GenericTable from '../../../Components/GenericTable';
import { Context } from '../../../State/Provider';
var Customers = function () {
    var searchStr = useContext(Context).searchStr;
    var _a = useState(), debounceTimeout = _a[0], setDebounceTimeout = _a[1];
    var _b = useState(0), totalcustomers = _b[0], setTotalCustomers = _b[1];
    var _c = useState([]), allCustomer = _c[0], setAllCustomer = _c[1];
    var _d = useState(0), page = _d[0], setPage = _d[1];
    var _e = useState(10), rowsPerPage = _e[0], setRowsPerPage = _e[1];
    var columns = {
        "SN": { width: 30 },
        "Name": { width: 200 },
        "Email": { width: 100 },
        "Phone": { width: 100 },
        "Country": { width: 150 },
        // "Action": { width: 100 },
    };
    var handleChangePage = function (event, newPage) {
        console.log("newPage", newPage);
        getAllCustomer(newPage + 1, rowsPerPage, searchStr === null || searchStr === void 0 ? void 0 : searchStr.customer, function (data) {
            var _a, _b;
            setAllCustomer((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items);
            setTotalCustomers((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.total);
        }, function (res) { return console.log(res); });
        setPage(newPage);
    };
    var handleChangeRowsPerPage = function (event) {
        console.log("EVENT", +event.target.value);
        getAllCustomer(1, +event.target.value, searchStr === null || searchStr === void 0 ? void 0 : searchStr.customer, function (data) {
            var _a, _b;
            setAllCustomer((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items);
            setTotalCustomers((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.total);
        }, function (res) { return console.log(res); });
        setRowsPerPage(+event.target.value);
    };
    useEffect(function () {
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        var timeoutId = setTimeout(function () {
            getAllCustomer(page + 1, rowsPerPage, searchStr === null || searchStr === void 0 ? void 0 : searchStr.customer, function (data) {
                var _a, _b;
                console.log(data);
                setAllCustomer((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items);
                setTotalCustomers((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.total);
            }, function (res) { return console.log(res); });
        }, 1000);
        setDebounceTimeout(timeoutId);
        return function () {
            clearTimeout(timeoutId);
        };
    }, [searchStr === null || searchStr === void 0 ? void 0 : searchStr.customer]);
    var createTableRows = function (customers) { return (customers === null || customers === void 0 ? void 0 : customers.map(function (customer, index) { return (_jsxs(TableRow, { children: [_jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: index + 1 })), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: "".concat(customer === null || customer === void 0 ? void 0 : customer.firstname, " ").concat(customer === null || customer === void 0 ? void 0 : customer.lastname) })), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: "".concat(customer === null || customer === void 0 ? void 0 : customer.email) })), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: "".concat(customer.phone) })), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: " " }))] }, index)); })); };
    return (_jsxs("div", { children: [_jsx(Typography, __assign({ className: "text-white text-center text-xl bg-rose-900 shadow-2xl rounded-tl-3xl py-3" }, { children: "Customer" })), _jsx("div", __assign({ className: "my-5 px-4" }, { children: _jsx(GenericTable, { columns: columns, data: createTableRows(allCustomer), total: totalcustomers, rowsPerPage: rowsPerPage, page: page, onChangePage: handleChangePage, onChangeRowsPerPage: handleChangeRowsPerPage }) }))] }));
};
export default Customers;

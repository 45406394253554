import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    id: '',
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    username: '',
    gender: '',
    date_of_birth: '',
    user_type: '',
    is_active: false,
    certifications: null,
    country: {
        code: '',
        label: '',
        phone: '',
    },
};
export var userSlice = createSlice({
    name: 'userState',
    initialState: initialState,
    reducers: {
        setUserState: function (state, action) {
            var data = action.payload;
            state.id = data.id;
            state.firstname = data.firstname;
            state.lastname = data.lastname;
            state.phone = data.phone;
            state.email = data.email;
            state.username = data.username;
            state.gender = data.gender;
            state.date_of_birth = data.date_of_birth;
            state.user_type = data.user_type;
            state.is_active = data.is_active;
            state.certifications = data.certifications;
            state.country = data.country;
        }
    }
});
export var setUserState = userSlice.actions.setUserState;
export default userSlice.reducer;

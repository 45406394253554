var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
var ConfirmDialog = function (props) {
    var open = props.open, onClose = props.onClose, onConfirm = props.onConfirm, component = props.component, content = props.content, _a = props.title, title = _a === void 0 ? '' : _a, confirmBtnText = props.confirmBtnText, cancelBtnText = props.cancelBtnText;
    return (_jsxs(Dialog, __assign({ fullWidth: true, open: open, onClose: onClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" }, { children: [_jsx(DialogTitle, __assign({ id: "alert-dialog-title" }, { children: title })), (component || content) &&
                _jsxs(DialogContent, { children: [component ? component : '', content ? content : ''] }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ onClick: onClose, variant: 'outlined', color: 'error' }, { children: cancelBtnText ? cancelBtnText : "No" })), _jsx(Button, __assign({ onClick: onConfirm, variant: 'outlined', color: 'success' }, { children: confirmBtnText ? confirmBtnText : "Yes" }))] })] })));
};
export default ConfirmDialog;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Typography } from "@mui/material";
import expert_banner from "../../../../Static/skin-special.jpg";
import brand_image from "../../../../Static/brand_image.jpg";
import { useNavigate } from 'react-router-dom';
var ScienceExperts = function () {
    var navigate = useNavigate();
    return (_jsxs("div", { children: [_jsxs("article", __assign({ className: 'relative hidden md:flex' }, { children: [_jsxs("section", __assign({ className: " bg-slate-200 rounded-r-[2rem] flex flex-col gap-8 align-center justify-center pl-16 pr-[25%] lg:pr-[15%]", style: { width: "70%", height: "30rem" } }, { children: [_jsx(Typography, __assign({ variant: 'h4', className: "text-black font-alegreya" }, { children: "BOOK AN APPOINTMENT WITH OUR SKIN SPECIALIST" })), _jsx(Typography, __assign({ variant: 'h6', className: "text-black" }, { children: "Consult with our expert dermatologists for personalized skin care advice. Tailor-made solutions for your unique skin needs, now available at our e-commerce site." })), _jsx(Button, __assign({ variant: 'outlined', className: ' border-black w-fit bg-black text-white hover:bg-white hover:text-black', size: 'large', onClick: function () { return navigate('/scincare'); } }, { children: "Consult Doctor" }))] })), _jsx("section", __assign({ className: "absolute ml-[50%] lg:ml-[65%] my-[2.5rem] " }, { children: _jsx("img", { className: "rounded-[2rem] h-[25rem] ", src: expert_banner, alt: "lab" }) }))] })), _jsx("article", __assign({ className: 'md:hidden' }, { children: _jsxs("section", __assign({ className: "bg-slate-200 flex flex-col gap-8 align-center justify-center p-8 items-center", style: {
                        backgroundImage: "url(".concat(brand_image, ")"),
                        backgroundSize: "cover",
                        backgroundPosition: "center"
                    } }, { children: [_jsx(Typography, __assign({ variant: 'h4', className: "text-black font-alegreya", sx: { textAlign: "center" } }, { children: "BOOK AN APPOINTMENT WITH OUR SKIN SPECIALIST" })), _jsx(Typography, __assign({ variant: 'h6', className: "text-slate-700", sx: { textAlign: "center" } }, { children: "Consult with our expert dermatologists for personalized skin care advice. Tailor-made solutions for your unique skin needs, now available at our e-commerce site." })), _jsx(Button, __assign({ variant: 'outlined', className: 'border-black w-fit bg-black text-white hover:bg-white hover:text-black', size: 'large', onClick: function () { return navigate('/scincare'); } }, { children: "Consult Doctor" }))] })) }))] }));
};
export default ScienceExperts;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useCallback } from "react";
import { Typography, Box, Stepper, Step, StepLabel, Divider, InputAdornment, Button, Chip, Stack, StepContent, } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import Information from "./Information";
import StyledTextField from "../../../Components/StyledTextField";
import Shipping from "./Shipping";
import Payment from "./Payment";
import { orderCreation } from "../../../ApiGateways/order";
import { useSelector, useDispatch } from "react-redux";
import OrderSummary from "../../../Components/OrderSummary";
import { IoIosPhonePortrait, IoMdCash } from "react-icons/io";
import MainCart from "./MainCart";
import { clearCart } from "../../../Redux/features/productCartSlice";
import { checkDiscount } from "../../../ApiGateways/promo";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { BasicInfoContext } from "../../../Components/BasicInfo";
import { NewAddressContext } from "../../../Components/NewAddress";
import GlobalSnackbar from "../../../Components/GlobalSnackbar";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// function PaymentCard() {
//   return (
//     <form>
//       <StyledTextField
//         fullWidth
//         label='Card Number'
//         placeholder='0000 0000 0000 0000'
//         InputProps={{
//           startAdornment: (
//             <InputAdornment position="start">
//               <CreditCard />
//             </InputAdornment>
//           ),
//         }}
//       />
//       <StyledTextField
//         fullWidth
//         label='Card Name'
//         placeholder='John Doe'
//       />
//       <DateField
//         label='Expiry Date'
//         clearable
//         disablePast
//         fullWidth
//       />
//       <StyledTextField
//         fullWidth
//         label='CVV'
//         placeholder='000'
//       />
//     </form>
//   )
// }
function MainOrder() {
    var _a, _b;
    var user = useSelector(function (state) { return state.userState; });
    var isLoggedIn = useSelector(function (state) { return state.singleState.isLoggedIn; });
    var wholeCart = useSelector(function (state) { return state.cartState; });
    var steps = ["Cart", "Shipping Information", "Delivery Charge", "Payment"];
    // const deliveryCharges: Record<string, { label: string, value: number }> = {
    //   standard: { label: "Standard (3-5 Business Days)", value: 50 },
    //   express: { label: "Express (1-2 Business Days)", value: 100 }
    // }
    var _c = useState({
        standard: { label: "Standard (3-5 Business Days)", value: 50 },
        express: { label: "Express (1-2 Business Days)", value: 100 },
    }), deliveryCharges = _c[0], setDeliveryCharges = _c[1];
    var _d = useState(""), deliveryRadio = _d[0], setDeliveryRadio = _d[1];
    var paymentMethods = {
        // card: {
        //   label: "Credit/Debit Card",
        //   image: <CreditCard sx={{ fontSize: "2rem" }} className='text-rose-900' />,
        //   form: (
        //     <form>
        //     </form>
        //   )
        // },
        online: {
            label: "Online Payment",
            image: _jsx(IoIosPhonePortrait, { size: "2rem", className: 'text-rose-900' }),
        },
        cash: {
            label: "Cash on Delivery",
            image: _jsx(IoMdCash, { size: "2rem", className: 'text-rose-900' }),
        },
    };
    var _e = useState(false), alertMsg = _e[0], setAlertMsg = _e[1];
    var _f = useState(0), stepNo = _f[0], setStepNo = _f[1];
    var _g = useState(0), totalPrice = _g[0], setTotalPrice = _g[1];
    var _h = useState(false), openAlert = _h[0], setOpenAlert = _h[1];
    var _j = useState(""), paymentRadio = _j[0], setPaymentRadio = _j[1];
    var _k = useState([]), promoCodes = _k[0], setPromoCodes = _k[1];
    var _l = useState(""), singleCode = _l[0], setSingleCode = _l[1];
    var _m = useState({
        firstname: (user === null || user === void 0 ? void 0 : user.firstname) || "",
        lastname: (user === null || user === void 0 ? void 0 : user.lastname) || "",
        email: (user === null || user === void 0 ? void 0 : user.email) || "",
        phone: (user === null || user === void 0 ? void 0 : user.phone) || "",
    }), basicInfo = _m[0], setBasicInfo = _m[1];
    var _o = useState({
        addressLine1: "",
        addressLine2: "",
        city: "",
        country: {
            code: "",
            label: "",
            phone: "",
        },
        landmark: "",
        postcode: "",
        state: "",
        suburb: "",
    }), shipping = _o[0], setShipping = _o[1];
    var ignoredFields = ["addressLine2", "landmark", "postcode"];
    var _p = useState(false), loading = _p[0], setLoading = _p[1];
    var _q = useState({}), finalCart = _q[0], setFinalCart = _q[1];
    var _r = useState(true), showOrderSummary = _r[0], setShowOrderSummary = _r[1];
    var _s = useState(0), discount = _s[0], setDiscount = _s[1];
    var _t = useState("/"), navigateStr = _t[0], setNavigateStr = _t[1];
    var _u = useState(_jsx(MainCart, {})), stepComponent = _u[0], setStepComponent = _u[1];
    var _v = useState({
        isActive: false,
        verticalPosition: "top",
        horizontalPosition: "center",
        message: "",
        alertType: "success",
    }), snackbarState = _v[0], setSnackbarState = _v[1];
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var header = (_jsx("section", __assign({ style: {
            display: "flex",
            gap: "0 0.5rem",
            color: "black",
            padding: "0.5rem 1rem",
        } }, { children: _jsx(Typography, __assign({ variant: "h6", textAlign: "center", width: "100%", className: " font-semibold" }, { children: "Order Summary" })) })));
    // useEffect(() => {
    //   if (!isLoggedIn) {
    //     navigate("/signin/order");
    //   }
    // }, [isLoggedIn]);
    useEffect(function () {
        var newTotal = Object.keys(finalCart).reduce(function (total, key) {
            var _a;
            var price = ((_a = finalCart[key]) === null || _a === void 0 ? void 0 : _a.subtotal) || 0;
            return total + price;
        }, 0);
        setTotalPrice(newTotal);
    }, [deliveryRadio, finalCart]);
    useEffect(function () {
        var newCart = Object.entries(wholeCart).reduce(function (cart, _a) {
            var key = _a[0], value = _a[1];
            if (value.isCheckout) {
                cart[key] = value;
            }
            return cart;
        }, {});
        setFinalCart(newCart);
    }, [wholeCart]);
    useEffect(function () {
        var body = {
            cart: Object.keys(finalCart).reduce(function (obj, key) {
                var _a;
                return (__assign(__assign({}, obj), (_a = {}, _a[key] = finalCart[key].quantity, _a)));
            }, {}),
            promo_codes: promoCodes,
        };
        checkDiscount(JSON.stringify(body), function (data) {
            var _a, _b;
            setDiscount((_b = (_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.promo_discount) === null || _b === void 0 ? void 0 : _b.value);
        }, function (res) { return console.log(res); });
    }, [finalCart, promoCodes]);
    var handleBasicInfo = useCallback(function (info) {
        setBasicInfo(__assign({}, info));
    }, [basicInfo]);
    var handleShipping = useCallback(function (address) {
        setShipping(__assign({}, address));
    }, [shipping]);
    useEffect(function () {
        var _a;
        localStorage.removeItem("social_redirect");
        if (stepNo === 0) {
            setStepComponent(_jsx(MainCart, {}));
        }
        else if (stepNo === 1) {
            setStepComponent(_jsx(Information, {}));
        }
        else if (stepNo === 2) {
            setStepComponent(_jsx(Shipping, { radioValue: deliveryRadio, setRadioValue: setDeliveryRadio, deliveryCharges: deliveryCharges, stepNo: stepNo, setStepNo: setStepNo }));
        }
        else if (stepNo === 3) {
            setStepComponent(_jsx(Payment, { paymentMethods: paymentMethods, radioValue: paymentRadio, setRadioValue: setPaymentRadio, shippingMethod: (_a = deliveryCharges[deliveryRadio]) === null || _a === void 0 ? void 0 : _a.label, stepNo: stepNo, setStepNo: setStepNo }));
        }
    }, [
        stepNo,
        basicInfo,
        shipping,
        deliveryRadio,
        paymentRadio,
        deliveryCharges,
    ]);
    var handleGoNext = function () {
        if (stepNo === 1) {
            console.log(basicInfo);
            console.log(shipping);
            var validation_1 = true;
            Object.values(basicInfo).forEach(function (value) {
                if (value === "") {
                    validation_1 = false;
                }
            });
            Object.entries(shipping).forEach(function (_a) {
                var key = _a[0], value = _a[1];
                if (!ignoredFields.includes(key)) {
                    if (value === "") {
                        validation_1 = false;
                    }
                }
            });
            if (validation_1) {
                setStepNo(stepNo + 1);
            }
            else {
                setSnackbarState({
                    isActive: true,
                    verticalPosition: "top",
                    horizontalPosition: "center",
                    message: "Please fill mandatory fields *",
                    alertType: "error"
                });
            }
        }
        else if (stepNo === 2) {
            if (deliveryRadio === "") {
                setSnackbarState({
                    isActive: true,
                    verticalPosition: "top",
                    horizontalPosition: "center",
                    message: "Please choose one Delivery Method",
                    alertType: "error"
                });
            }
            else {
                setStepNo(stepNo + 1);
            }
        }
        else {
            setStepNo(stepNo + 1);
        }
    };
    var createOrder = function () {
        var addressLine1 = shipping.addressLine1, addressLine2 = shipping.addressLine2, rest = __rest(shipping, ["addressLine1", "addressLine2"]);
        var body = {
            basic_info: basicInfo,
            shipping_info: __assign(__assign({}, rest), { address_line1: addressLine1, address_line2: addressLine2, country: rest.country.label, state: "", city: "" }),
            cart: Object.keys(finalCart).reduce(function (obj, key) {
                var _a;
                return (__assign(__assign({}, obj), (_a = {}, _a[key] = finalCart[key].quantity, _a)));
            }, {}),
            delivery_method: deliveryRadio,
            payment_method: paymentRadio,
            promos: promoCodes,
        };
        setLoading(true);
        orderCreation(JSON.stringify(body), function (data) {
            console.log(data);
            if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                if ((data === null || data === void 0 ? void 0 : data.gateWayUrl) !== null) {
                    window.location.replace(data === null || data === void 0 ? void 0 : data.gateWayUrl);
                }
                else {
                    setNavigateStr('/user_profile/my_orders');
                    setSnackbarState({
                        isActive: true,
                        verticalPosition: "top",
                        horizontalPosition: "center",
                        message: "Order has been created succesfully !",
                        alertType: "success"
                    });
                    setTimeout(function () {
                        dispatch(clearCart());
                    }, 1600);
                }
            }
        }, function (res) {
            console.log(res);
            setLoading(false);
            setSnackbarState({
                isActive: true,
                verticalPosition: "top",
                horizontalPosition: "center",
                message: String(res === null || res === void 0 ? void 0 : res.message),
                alertType: "error"
            });
        });
    };
    var promoCalculation = function (promoCode) {
        setPromoCodes(__spreadArray(__spreadArray([], promoCodes, true), [promoCode], false));
        var body = {
            cart: Object.keys(finalCart).reduce(function (obj, key) {
                var _a;
                return (__assign(__assign({}, obj), (_a = {}, _a[key] = finalCart[key].quantity, _a)));
            }, {}),
            promo_codes: __spreadArray(__spreadArray([], promoCodes, true), [promoCode], false),
        };
        checkDiscount(JSON.stringify(body), function (data) {
            var _a, _b, _c, _d;
            console.log((_b = (_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.promo_discount) === null || _b === void 0 ? void 0 : _b.value);
            setDiscount((_d = (_c = data === null || data === void 0 ? void 0 : data.data) === null || _c === void 0 ? void 0 : _c.promo_discount) === null || _d === void 0 ? void 0 : _d.value);
        }, function (res) { return console.log(res); });
    };
    var deletePromoCode = function (indexToDelete) {
        var updatedPromoCodes = promoCodes.filter(function (_, index) { return index !== indexToDelete; });
        setPromoCodes(updatedPromoCodes);
    };
    return (_jsxs("main", { children: [_jsx(Backdrop, __assign({ sx: { color: "#fff", zIndex: function (theme) { return theme.zIndex.drawer + 1; } }, open: loading, onClick: function () { } }, { children: _jsxs("div", __assign({ style: { display: 'flex', gap: '2rem' } }, { children: [_jsx(CircularProgress, { color: "inherit" }), _jsx(Typography, __assign({ className: " text-center" }, { children: "Please wait for a while!!" }))] })) })), (snackbarState === null || snackbarState === void 0 ? void 0 : snackbarState.isActive) && (_jsx(GlobalSnackbar, { verticalPosition: snackbarState === null || snackbarState === void 0 ? void 0 : snackbarState.verticalPosition, horizontalPosition: snackbarState === null || snackbarState === void 0 ? void 0 : snackbarState.horizontalPosition, message: snackbarState === null || snackbarState === void 0 ? void 0 : snackbarState.message, alertType: snackbarState === null || snackbarState === void 0 ? void 0 : snackbarState.alertType, onfinish: function () {
                    setSnackbarState(__assign(__assign({}, snackbarState), { isActive: false }));
                } })), Object.keys(wholeCart).length > 0 ? (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "flex flex-col nowrap md:grid md:grid-cols-12 gap-4 md:flex-none" }, { children: [_jsxs("section", __assign({ className: "md:col-span-8 p-4" }, { children: [_jsx(Box, __assign({ sx: { width: "100%" }, className: "hidden md:block my-6" }, { children: _jsx(Stepper, __assign({ activeStep: stepNo }, { children: steps.map(function (label) { return (_jsx(Step, { children: _jsx(StepLabel, { children: label }) }, label)); }) })) })), _jsx("article", __assign({ className: "hidden md:block" }, { children: _jsx(BasicInfoContext.Provider, __assign({ value: { basicInfo: basicInfo, setBasicInfo: handleBasicInfo } }, { children: _jsx(NewAddressContext.Provider, __assign({ value: { shipping: shipping, setShipping: handleShipping } }, { children: stepComponent })) })) })), _jsx(Box, __assign({ sx: { width: "100%" }, className: "block md:hidden my-6" }, { children: _jsx(Stepper, __assign({ activeStep: stepNo, orientation: "vertical" }, { children: steps.map(function (label) { return (_jsxs(Step, { children: [_jsx(StepLabel, { children: label }), _jsx(StepContent, { children: _jsx(BasicInfoContext.Provider, __assign({ value: { basicInfo: basicInfo, setBasicInfo: handleBasicInfo } }, { children: _jsx(NewAddressContext.Provider, __assign({ value: { shipping: shipping, setShipping: handleShipping } }, { children: stepComponent })) })) })] }, label)); }) })) })), _jsxs("section", __assign({ className: "flex justify-between", style: { marginTop: "1rem" } }, { children: [stepNo > 0 ? (_jsxs(Button, __assign({ size: "small", variant: "outlined", onClick: function () { return setStepNo(stepNo - 1); } }, { children: [_jsx(ChevronLeftIcon, {}), "Go back to ", steps[stepNo - 1]] }))) : (_jsx(Button, { disabled: true })), steps.length > stepNo + 1 &&
                                            Object.keys(finalCart).length > 0 && (_jsxs(Button, __assign({ size: "small", variant: "outlined", onClick: handleGoNext }, { children: ["Move to ", steps[stepNo + 1], " ", _jsx(ChevronRightIcon, {})] }))), steps.length === stepNo + 1 && (_jsx(Button, __assign({ variant: "contained", size: "small", className: " bg-red-500 text-white hover:bg-red-700 border-black bg-opacity-80 py-3 px-3", onClick: createOrder }, { children: paymentRadio === 'cash' ? "Create Order" : "Pay Now" })))] }))] })), _jsxs("section", __assign({ className: "md:col-span-4 hidden md:block", style: { backgroundColor: "#FFEEEE" } }, { children: [_jsx(OrderSummary, { header: header, cart: finalCart, disableItemUpdate: true }), _jsxs("article", __assign({ style: { padding: "1rem" }, className: "flex flex-col gap-y-4" }, { children: [_jsx(Stack, __assign({ direction: "row", spacing: 1, className: "flex flex-wrap gap-y-1" }, { children: promoCodes === null || promoCodes === void 0 ? void 0 : promoCodes.map(function (item, id) { return (_jsx(Chip, { label: item, color: "success", onDelete: function () {
                                                    deletePromoCode(id);
                                                } }, id)); }) })), _jsx(StyledTextField, { fullWidth: true, value: singleCode, placeholder: "Gift Card and/or discount code", backgroundColor: "white", onChange: function (e) { return setSingleCode(e.target.value); }, InputProps: {
                                                endAdornment: (_jsx(InputAdornment, __assign({ position: "end" }, { children: _jsx(Button, __assign({ onClick: function (e) {
                                                            setPromoCodes(__spreadArray(__spreadArray([], promoCodes, true), [singleCode], false));
                                                            promoCalculation(singleCode);
                                                            setSingleCode("");
                                                        }, sx: { padding: "0.9rem", color: "white" }, className: "bg-rose-900 rounded-l-none rounded-r-l mr-[-0.9rem]" }, { children: "Apply" })) }))),
                                            } }), _jsxs("div", __assign({ className: "flex justify-between" }, { children: [_jsx(Typography, __assign({ variant: "h6" }, { children: _jsx("b", { children: "Total:" }) })), _jsxs(Typography, __assign({ variant: "h6" }, { children: ["\u09F3 ", totalPrice] }))] })), _jsxs("div", __assign({ className: "flex justify-between" }, { children: [_jsx(Typography, __assign({ variant: "h6" }, { children: _jsx("b", { children: "Discount:" }) })), _jsxs(Typography, __assign({ variant: "h6" }, { children: ["\u09F3 ", discount.toFixed(2)] }))] })), _jsxs("div", __assign({ className: "flex justify-between" }, { children: [_jsx(Typography, __assign({ variant: "h6" }, { children: _jsx("b", { children: "Shipping:" }) })), _jsx(Typography, __assign({ variant: "h6" }, { children: ((_a = deliveryCharges[deliveryRadio]) === null || _a === void 0 ? void 0 : _a.value)
                                                        ? "\u09F3 ".concat(deliveryCharges[deliveryRadio].value)
                                                        : "To be  Calculated" }))] })), _jsx(Divider, { sx: { borderWidth: "1px", borderColor: "#aaaaaa" } })] })), _jsxs("article", __assign({ className: "flex justify-between", style: { padding: "1rem", paddingTop: 0 } }, { children: [_jsx(Typography, __assign({ variant: "h5" }, { children: _jsx("b", { children: "Grand Total:" }) })), _jsxs(Typography, __assign({ variant: "h5" }, { children: ["\u09F3", " ", (totalPrice -
                                                    discount +
                                                    (((_b = deliveryCharges[deliveryRadio]) === null || _b === void 0 ? void 0 : _b.value)
                                                        ? deliveryCharges[deliveryRadio].value
                                                        : 0)).toFixed(2)] }))] }))] }))] })) })) : (_jsx(_Fragment, { children: _jsx(Navigate, { to: navigateStr }) }))] }));
}
export default MainOrder;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Typography, Button, Checkbox, Dialog } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { setCheckout } from '../../../Redux/features/productCartSlice';
import ProductCard from '../../../Components/ProductCard';
import { getWishlistByUser } from '../../../ApiGateways/wishlist';
var CheckoutDetails = function (props) {
    var totalQuantity = props.totalQuantity, totalPrice = props.totalPrice;
    var navigate = useNavigate();
    return (_jsxs("article", __assign({ className: "bg-rose-100 shadow-xl", style: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
            padding: '1rem',
        } }, { children: [_jsx(Typography, __assign({ variant: 'h6', className: " text-center" }, { children: _jsx("u", { children: "Subtotal (".concat(totalQuantity, " items)") }) })), _jsx(Typography, __assign({ variant: 'h6', className: " text-center" }, { children: _jsx("b", { children: "\u09F3 ".concat(totalPrice) }) })), _jsx(Button, __assign({ onClick: function () { return navigate("/order"); }, size: 'medium', sx: {
                    marginTop: "1rem"
                }, variant: 'contained' }, { children: "Proceed to checkout" }))] })));
};
var ShoppingCart = function () {
    var myCart = useSelector(function (state) { return state.cartState; });
    var dispatch = useDispatch();
    var _a = useState(0), totalQuantity = _a[0], setTotalQuantity = _a[1];
    var _b = useState(0), totalPrice = _b[0], setTotalPrice = _b[1];
    var _c = useState({}), cartState = _c[0], setCartState = _c[1];
    var _d = useState({}), removedState = _d[0], setRemovedState = _d[1];
    var _e = useState(false), open = _e[0], setOpen = _e[1];
    var _f = useState([]), allWishListId = _f[0], setAllWishListId = _f[1];
    var _g = useState(false), reset = _g[0], setReset = _g[1];
    var handleChangeCheckbox = function (event, productId) {
        if (event.target.checked) {
            var newCartState = __assign({}, cartState);
            newCartState[productId] = myCart[productId];
            var newRemovedState = __assign({}, removedState);
            delete newRemovedState[productId];
            setCartState(newCartState);
            setRemovedState(newRemovedState);
        }
        else {
            var newCartState = __assign({}, cartState);
            delete newCartState[productId];
            var newRemovedState = __assign({}, removedState);
            newRemovedState[productId] = myCart[productId];
            setCartState(newCartState);
            setRemovedState(newRemovedState);
        }
        dispatch(setCheckout(productId));
    };
    useEffect(function () {
        getWishlistByUser(function (data) {
            var _a, _b;
            var allId = (_b = ((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items)) === null || _b === void 0 ? void 0 : _b.map(function (item, i) { return item === null || item === void 0 ? void 0 : item.id; });
            setAllWishListId(allId);
        }, function (res) {
            console.log(res);
        });
    }, [reset]);
    useEffect(function () {
        var newTotal = Object.keys(cartState).reduce(function (total, key) {
            var _a;
            var price = ((_a = cartState[key]) === null || _a === void 0 ? void 0 : _a.subtotal) || 0;
            return total + price;
        }, 0);
        var newQuantity = Object.keys(cartState).reduce(function (total, key) {
            var _a;
            var quantity = ((_a = cartState[key]) === null || _a === void 0 ? void 0 : _a.quantity) || 0;
            return total + quantity;
        }, 0);
        setTotalPrice(newTotal);
        setTotalQuantity(newQuantity);
    }, [cartState]);
    useEffect(function () {
        var updatedCart = Object.entries(myCart).reduce(function (cart, _a) {
            var key = _a[0], value = _a[1];
            if (value.isCheckout && value.quantity >= 1) {
                cart[key] = value;
            }
            return cart;
        }, {});
        var updatedRemoved = Object.entries(myCart).reduce(function (removed, _a) {
            var key = _a[0], value = _a[1];
            if (!value.isCheckout && value.quantity >= 1) {
                removed[key] = value;
            }
            return removed;
        }, {});
        setCartState(updatedCart);
        setRemovedState(updatedRemoved);
    }, [myCart]);
    console.log(myCart);
    return (_jsx(_Fragment, { children: Object.keys(myCart).length >= 1 ?
            _jsx(_Fragment, { children: _jsxs("main", __assign({ className: "grid grid-cols-12 p-4 gap-4 lg:m-8 items-center" }, { children: [_jsx(Typography, __assign({ variant: 'h4', className: "font-alegreya text-gray-700 col-span-12 lg:col-span-5" }, { children: "Shopping Cart" })), _jsx(Button, __assign({ variant: 'contained', color: 'info', className: 'col-span-5 block lg:hidden border-black w-fit bg-black text-white hover:bg-white hover:text-black', size: "small", sx: {
                                width: 'fit-content',
                                height: 'fit-content',
                            }, onClick: function () { return setOpen(true); } }, { children: "See Total" })), _jsxs("section", __assign({ className: 'col-span-12 md:col-span-9' }, { children: [Object.entries(cartState).map(function (_a, index) {
                                    var key = _a[0], value = _a[1];
                                    return (_jsxs("div", __assign({ className: "flex flex-row nowrap gap-x-2 p-4 w-fit" }, { children: [_jsx(Checkbox, { color: 'primary', sx: {
                                                    height: 'fit-content',
                                                }, checked: value.isCheckout, onChange: function (event) { return handleChangeCheckbox(event, key); }, inputProps: { 'aria-label': 'controlled' } }), _jsx(ProductCard, { item: value.product, horizontal: true, inWishlist: allWishListId.some(function (wishlistId) { var _a; return wishlistId === ((_a = value === null || value === void 0 ? void 0 : value.product) === null || _a === void 0 ? void 0 : _a.id); }), reset: reset, setReset: setReset })] }), index));
                                }), _jsx(Typography, __assign({ variant: 'h5', className: 'mt-4' }, { children: "Previously Added Items" })), Object.entries(removedState).map(function (_a, index) {
                                    var key = _a[0], value = _a[1];
                                    return (_jsxs("div", __assign({ className: "flex flex-row nowrap gap-x-2 p-4 bg-gray-100" }, { children: [_jsx(Checkbox, { color: 'primary', sx: {
                                                    height: 'fit-content',
                                                }, checked: value.isCheckout, onChange: function (event) { return handleChangeCheckbox(event, key); }, inputProps: { 'aria-label': 'controlled' } }), _jsx(ProductCard, { item: value.product, horizontal: true })] }), index));
                                })] })), _jsx("section", __assign({ className: "col-span-3 self-start hidden lg:block" }, { children: _jsx(CheckoutDetails, { totalPrice: totalPrice, totalQuantity: totalQuantity }) })), _jsx(Dialog, __assign({ open: open, onClose: function () { return setOpen(false); }, maxWidth: 'md', fullWidth: true }, { children: _jsx(CheckoutDetails, { totalPrice: totalPrice, totalQuantity: totalQuantity }) }))] })) })
            :
                _jsx(_Fragment, { children: _jsx(Navigate, { to: '/' }) }) }));
};
export default ShoppingCart;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CancelOutlined } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
function FileUploader(props) {
    var names = props.names, removeName = props.removeName, fileType = props.fileType, onFileSelect = props.onFileSelect, className = props.className, buttonClassName = props.buttonClassName, style = props.style;
    var _a = useState([]), files = _a[0], setFiles = _a[1];
    var _b = useState([]), fileNames = _b[0], setFileNames = _b[1];
    var inputFileRef = useRef(null);
    var handleFileChange = function (event) {
        var newFiles = Array.from(event.target.files || []);
        if (newFiles.length) {
            var imageFileTypes = ["image/jpeg", "image/png", "image/jpg", "image/gif"];
            var videoFileTypes = ["video/mp4", "video/mpeg4", "video/mkv", "video/wmv", "video/avi"];
            var allowedFileTypes_1 = fileType === "images" ? imageFileTypes : videoFileTypes;
            var filteredFiles = newFiles.filter(function (file) {
                return file instanceof File &&
                    allowedFileTypes_1.includes(file.type);
            });
            setFiles(__spreadArray(__spreadArray([], files, true), filteredFiles, true));
        }
    };
    useEffect(function () {
        if (files.length) {
            var names_1 = files.map(function (file) { return file.name; });
            setFileNames(names_1);
            onFileSelect(files);
        }
    }, [files]);
    return (_jsxs("div", __assign({ className: className, style: style }, { children: [_jsx("input", { ref: inputFileRef, type: "file", multiple: true, style: { display: "none" }, onChange: handleFileChange, accept: fileType === "images" ? "image/*" : "video/*" }), _jsxs(Button, __assign({ variant: "outlined", className: buttonClassName, onClick: function () { var _a; return (_a = inputFileRef.current) === null || _a === void 0 ? void 0 : _a.click(); } }, { children: ["Choose ", fileType] })), fileNames.length > 0 && (_jsx("section", __assign({ className: "flex flex-row flex-wrap gap-x-1", style: { width: "100%" } }, { children: fileNames.map(function (fileName, index) { return (_jsxs(Typography, __assign({ variant: "subtitle1" }, { children: [fileName, " ", _jsx(CancelOutlined, { onClick: function () {
                                return setFiles(files.slice(0, index).concat(files.slice(index + 1)));
                            } })] }), index)); }) }))), ((names === null || names === void 0 ? void 0 : names.length) || 0) > 0 && (_jsx("section", __assign({ className: "flex flex-row flex-wrap gap-x-1", style: { width: "100%" } }, { children: (names || []).map(function (name, index) { return (_jsxs(Typography, __assign({ variant: "subtitle1" }, { children: [name, " ", _jsx(CancelOutlined, { onClick: function () { return removeName && removeName(name); } }), " "] }), index)); }) }))), fileNames.length === 0 && ((names === null || names === void 0 ? void 0 : names.length) || 0) === 0 && (_jsxs(Typography, __assign({ variant: "subtitle1" }, { children: ["No ", fileType, " selected"] })))] })));
}
export default FileUploader;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { refreahAccessToken, getUser } from '../ApiGateways/user';
import { setIsALoggedIn } from '../Redux/features/singleStateSlice';
import { setUserState } from '../Redux/features/userSlice';
import ConfirmDialog from '../Components/ConfirmDialog';
var ManualPermissionRoute = function (_a) {
    var children = _a.children;
    var dispatch = useDispatch();
    var location = useLocation();
    var navigate = useNavigate();
    var _b = useState(false), userLoaded = _b[0], setUserLoaded = _b[1];
    var _c = useState(false), reset = _c[0], setReset = _c[1];
    var _d = useState(""), token = _d[0], setToken = _d[1];
    var _e = useState(0), count = _e[0], setCount = _e[1];
    var _f = useState(false), confirmDialog = _f[0], setConfirmDialog = _f[1];
    var _g = useState(false), showChildren = _g[0], setShowChildren = _g[1];
    useEffect(function () {
        refreahAccessToken(function (data) {
            var _a, _b;
            setToken((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.access_token);
            localStorage.setItem('access-token', (_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.access_token);
        }, function (res) { return console.log(res); });
    }, [reset]);
    useEffect(function () {
        getUser(function (data) {
            if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                dispatch(setIsALoggedIn(true));
                dispatch(setUserState(data === null || data === void 0 ? void 0 : data.data));
                setShowChildren(true);
            }
            setUserLoaded(true);
        }, function (error) {
            console.error(error);
            if (token !== '' && count < 3) {
                localStorage.setItem('access-token', token);
                setReset(!reset);
                setToken("");
            }
            else if (token === '' && count < 3) {
                setReset(!reset);
            }
            else {
                dispatch(setIsALoggedIn(false));
                setConfirmDialog(true);
                setUserLoaded(true);
            }
            setCount(count + 1);
        });
    }, [dispatch, children, reset]);
    if (!userLoaded) {
        return _jsx(Box, __assign({ sx: { display: 'flex' } }, { children: _jsx("div", __assign({ className: " w-full flex justify-center mt-[15vh]" }, { children: _jsx(CircularProgress, {}) })) }));
    }
    return (_jsx(_Fragment, { children: showChildren ?
            _jsx(_Fragment, { children: children })
            :
                _jsx(_Fragment, { children: _jsx(ConfirmDialog, { open: confirmDialog, title: "It is recommended to login for order. But, you can continue without logging in.", onClose: function () {
                            setConfirmDialog(false);
                            setShowChildren(true);
                        }, onConfirm: function () {
                            setConfirmDialog(false);
                            navigate('/signin', { state: { from: location }, replace: true });
                        }, confirmBtnText: 'Proceed to Login', cancelBtnText: 'Continue without Login' }) }) }));
};
export default ManualPermissionRoute;

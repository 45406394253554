var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import StyledTextField from '../../../../Components/StyledTextField';
var PasswordManager = function (props) {
    var previousPass = props.previousPass, setPreviousPass = props.setPreviousPass, pass = props.pass, setPass = props.setPass;
    return (_jsxs("section", __assign({ style: { display: "flex", flexDirection: "column", gap: "1rem" } }, { children: [_jsx(StyledTextField, { type: 'password', fullWidth: true, required: true, value: previousPass, borderRadius: '10px', label: "Current Password", onChange: function (e) { return setPreviousPass(e.target.value.replace(/\s/g, '')); } }), _jsx(StyledTextField, { type: 'password', fullWidth: true, required: true, value: pass === null || pass === void 0 ? void 0 : pass.newPass, borderRadius: '10px', label: "New Password", onChange: function (e) { return setPass(__assign(__assign({}, pass), { newPass: e.target.value.replace(/\s/g, '') })); } }), _jsx(StyledTextField, { type: 'password', fullWidth: true, required: true, value: pass === null || pass === void 0 ? void 0 : pass.confirmPass, borderRadius: '10px', label: "Confirm New Password", onChange: function (e) { return setPass(__assign(__assign({}, pass), { confirmPass: e.target.value.replace(/\s/g, '') })); } })] })));
};
export default PasswordManager;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { setIsALoggedIn, setIsAdminLoggedIn, } from "../../Redux/features/singleStateSlice";
import { setUserState } from "../../Redux/features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { VscGraph } from "react-icons/vsc";
import { LiaStoreSolid } from "react-icons/lia";
import { BiSliderAlt } from "react-icons/bi";
import { FaStreetView } from "react-icons/fa";
import { FaUserDoctor } from "react-icons/fa6";
import { IoIosPeople } from "react-icons/io";
import { LuBadgePercent } from "react-icons/lu";
import { FaFileInvoiceDollar } from "react-icons/fa";
import SidebarList from "../../Components/Sidebar";
import MenuAvatar from "../../Components/MenuAvatar";
import { Medication } from "@mui/icons-material";
import AdminGlobalSearch from "../../Pages/AdminAuthPages/AdminGlobalSearch";
var AdminLayout = function () {
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var user = useSelector(function (state) { return state.userState; });
    var sidebarValues = [
        { name: "Dashboard", url: "/admin", iconSrc: _jsx(VscGraph, {}), subOption: [] },
        {
            name: "Products",
            url: "/admin/product",
            iconSrc: _jsx(LiaStoreSolid, {}),
            subOption: [],
        },
        {
            name: "Customers",
            url: "/admin/customers",
            iconSrc: _jsx(FaStreetView, {}),
            subOption: [],
        },
        {
            name: "Orders",
            url: "/admin/orders",
            iconSrc: _jsx(BiSliderAlt, {}),
            subOption: [],
        },
        {
            name: "Invoice",
            url: "/admin/invoice",
            iconSrc: _jsx(FaFileInvoiceDollar, {}),
            subOption: [],
        },
        {
            name: "Employees",
            url: "/admin/employee",
            iconSrc: _jsx(IoIosPeople, {}),
            subOption: [],
        },
        {
            name: "Roles",
            url: "/admin/role",
            iconSrc: _jsx(Medication, {}),
            subOption: [],
        },
        {
            name: "Doctors",
            url: "/admin/doctors",
            iconSrc: _jsx(FaUserDoctor, {}),
            subOption: [],
        },
        {
            name: "Appointments",
            url: "/admin/appointments",
            iconSrc: _jsx(Medication, {}),
            subOption: [],
        },
        {
            name: "Promo Codes",
            url: "/admin/promo_control",
            iconSrc: _jsx(LuBadgePercent, {}),
            subOption: [],
        },
    ];
    var handleLogout = function () {
        var org = localStorage.getItem("organization");
        localStorage.clear();
        org && localStorage.setItem("organization", org);
        dispatch(setIsAdminLoggedIn(false));
        dispatch(setIsALoggedIn(false));
        dispatch(setUserState({
            email: "",
            user_type: "",
            is_active: false,
        }));
        navigate("/admin_login");
    };
    var menuItems = [{ name: "Log Out", func: handleLogout }];
    return (_jsxs("div", __assign({ className: "bg-rose-100 h-screen pl-4 overflow-hidden" }, { children: [_jsxs("section", __assign({ className: "flex justify-between items-center content-center py-4 px-4" }, { children: [_jsx(Typography, __assign({ className: "text-rose-900", variant: "h4" }, { children: "Admin Panel" })), _jsx(AdminGlobalSearch, { path: window.location.pathname }), _jsx(MenuAvatar, { label: user.email, items: menuItems, avatarClassName: "mr-4" })] })), _jsxs("section", __assign({ className: "flex gap-2 justify-between" }, { children: [_jsx(SidebarList, { options: sidebarValues, className: "w-fit" }), _jsx("main", __assign({ className: "w-[87.5vw] h-screen bg-white rounded-tl-3xl shadow-2xl overflow-y-auto" }, { children: _jsx(Outlet, {}) }))] }))] })));
};
export default AdminLayout;

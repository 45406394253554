var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginAdmin } from "../../../../ApiGateways/user";
import { setIsALoggedIn, setIsAdminLoggedIn } from "../../../../Redux/features/singleStateSlice";
import { setUserState } from "../../../../Redux/features/userSlice";
import { Button, Typography, IconButton } from "@mui/material";
import StyledTextField from '../../../../Components/StyledTextField';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import GlobalSnackbar from '../../../../Components/GlobalSnackbar';
var AdminLogin = function () {
    var dispatch = useDispatch();
    var _a = useState(""), email = _a[0], setEmail = _a[1];
    var _b = useState(""), password = _b[0], setPassword = _b[1];
    var navigate = useNavigate();
    var _c = useState("password"), passwordState = _c[0], setPasswordState = _c[1];
    var _d = useState({
        isActive: false,
        verticalPosition: 'top',
        horizontalPosition: 'center',
        message: '',
        alertType: 'success'
    }), snackbarState = _d[0], setSnackbarState = _d[1];
    var handleLogin = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            event.preventDefault();
            loginAdmin({ email: email, password: password }, function (data) {
                var _a, _b, _c, _d, _e;
                if ((data === null || data === void 0 ? void 0 : data.success) === true && ((_b = (_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.user_type) === "admin") {
                    console.log(data);
                    dispatch(setIsAdminLoggedIn(true));
                    dispatch(setIsALoggedIn(true));
                    dispatch(setUserState((_c = data === null || data === void 0 ? void 0 : data.data) === null || _c === void 0 ? void 0 : _c.user));
                    localStorage.setItem('access-token', (_d = data.data) === null || _d === void 0 ? void 0 : _d.access_token);
                    localStorage.setItem('refresh-token', (_e = data.data) === null || _e === void 0 ? void 0 : _e.refresh_token);
                    navigate('/admin');
                    setSnackbarState({
                        isActive: true,
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                        message: 'Login successful!',
                        alertType: 'success'
                    });
                }
            }, function (res) { return __awaiter(void 0, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, res.json()];
                        case 1:
                            data = _a.sent();
                            console.log(data);
                            setSnackbarState({
                                isActive: true,
                                verticalPosition: 'top',
                                horizontalPosition: 'center',
                                message: 'Invalid credentials. Please try again.',
                                alertType: 'error'
                            });
                            navigate('/admin_login');
                            return [2 /*return*/];
                    }
                });
            }); });
            return [2 /*return*/];
        });
    }); };
    return (_jsxs("form", __assign({ onSubmit: handleLogin, className: "grid text-center bg-rose-100 py-14 px-8 shadow-lg rounded-md my-[10%] mx-[25%]" }, { children: [_jsx(Typography, __assign({ variant: 'h3', className: 'mb-8' }, { children: "Log In" })), _jsx(StyledTextField, { fullWidth: true, value: email, required: true, variant: "outlined", label: "Email", onChange: function (e) { return setEmail(e.target.value.replace(/\s/g, '')); }, className: 'my-2 text-lg' }), _jsx(StyledTextField, { fullWidth: true, value: password, required: true, variant: "outlined", label: "Password", onChange: function (e) { return setPassword(e.target.value.replace(/\s/g, '')); }, className: 'my-2 text-lg', type: passwordState, InputProps: {
                    endAdornment: (_jsx(IconButton, { children: passwordState === 'password' ?
                            _jsx(Typography, __assign({ onClick: function () { return setPasswordState('text'); } }, { children: _jsx(FaEyeSlash, {}) }))
                            :
                                _jsx(Typography, __assign({ onClick: function () { return setPasswordState('password'); } }, { children: _jsx(FaEye, {}) })) })),
                } }), _jsxs("section", __assign({ className: "my-4 flex justify-between" }, { children: [_jsx(Button, __assign({ type: "submit", className: "bg-rose-900 text-lg", variant: "contained", size: 'large' }, { children: "Login" })), _jsx(Button, __assign({ className: 'text-rose-900' }, { children: _jsx(Typography, { children: "Forgot Password ?" }) }))] })), snackbarState.isActive && (_jsx(GlobalSnackbar, { verticalPosition: snackbarState.verticalPosition, horizontalPosition: snackbarState.horizontalPosition, message: snackbarState.message, alertType: snackbarState.alertType, onfinish: function () {
                    setSnackbarState(__assign(__assign({}, snackbarState), { isActive: false }));
                } }))] })));
};
export default AdminLogin;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { AiOutlineHome } from "react-icons/ai";
import { FiShoppingBag } from "react-icons/fi";
import { TfiWrite } from "react-icons/tfi";
import { GiHealthPotion } from "react-icons/gi";
import InfoIcon from '@mui/icons-material/Info';
import SecurityIcon from '@mui/icons-material/Security';
import { AppBar, Badge, Toolbar, Typography, Avatar, Button, Drawer, List, ListItemButton, ListItemIcon, ListItemText, } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from '@mui/icons-material/Person';
import { useSelector, useDispatch } from "react-redux";
import { setIsALoggedIn, } from "../../../Redux/features/singleStateSlice";
import ProfileMenu from "../ProfileMenu";
import { stringToColor } from "../../../Utils";
import FloatingCart from "../../FloatingCart";
import { AccountCircle, Logout, ShoppingBasket, ShoppingCart, ShoppingCartOutlined, Medication } from "@mui/icons-material";
import Logo from "../../../Static/nihonsei-logo.png";
import { Context } from "../../../State/Provider";
import SearchBar from "../SearchBar";
import { setUserState } from "../../../Redux/features/userSlice";
import ReviewsIcon from "@mui/icons-material/Reviews";
import FavoriteIcon from "@mui/icons-material/Favorite";
var Header = function () {
    var user = useSelector(function (state) { return state.userState; });
    var myCart = useSelector(function (state) { return state.cartState; });
    var isLoggedIn = useSelector(function (state) { return state.singleState.isLoggedIn; });
    var dispatch = useDispatch();
    var _a = useContext(Context), openProfile = _a.openProfile, setOpenProfile = _a.setOpenProfile, searchDrawer = _a.searchDrawer, setSearchDrawer = _a.setSearchDrawer, setAllWish = _a.setAllWish;
    var _b = useState(false), isMenu = _b[0], setIsMenu = _b[1];
    var _c = useState(null), profileAnchorEl = _c[0], setProfileAnchorEl = _c[1];
    var _d = useState(null), cartAnchorEl = _d[0], setCartAnchorEl = _d[1];
    var cartOpen = Boolean(cartAnchorEl);
    var totalQuantity = Object.keys(myCart).reduce(function (total, key) {
        var _a;
        var quantity = ((_a = myCart[key]) === null || _a === void 0 ? void 0 : _a.quantity) || 0;
        return total + quantity;
    }, 0);
    var toggleDrawer = function (newOpen) { return function () {
        setSearchDrawer(newOpen);
    }; };
    var handleLogout = function () {
        var org = localStorage.getItem("organization");
        localStorage.clear();
        org && localStorage.setItem("organization", org);
        setAllWish([]);
        dispatch(setIsALoggedIn(false));
        dispatch(setUserState({
            email: "",
            user_type: "",
            is_active: false,
        }));
        navigate("/");
    };
    var tabBar = [
        { name: "HOME", icon: _jsx(AiOutlineHome, {}), url: "/" },
        { name: "SHOP", icon: _jsx(FiShoppingBag, {}), url: "/product" },
        { name: "BLOG", icon: _jsx(TfiWrite, {}), url: "/blog" },
        // { name: "DISCOVER", icon: <GiMaterialsScience />, url: "/" },
        { name: "SKINCARE ASSIST", icon: _jsx(GiHealthPotion, {}), url: "/scincare" },
        { name: "ABOUT US", icon: _jsx(InfoIcon, {}), url: "/about_us" },
        { name: "PRIVACY POLICY", icon: _jsx(SecurityIcon, {}), url: "/privacy_policy" },
        // { name: "SALE", icon: <FcSalesPerformance />, url: "/" },
    ];
    var navigate = useNavigate();
    var menuControl = function (event) {
        if (isLoggedIn) {
            if (window.innerWidth < 768) {
                setOpenProfile(true);
                event.stopPropagation();
            }
            else {
                setProfileAnchorEl(event.currentTarget);
            }
        }
        else {
            navigate("/signin");
        }
    };
    var handleOpenCart = function (event) {
        if (Object.keys(myCart).length > 0 &&
            window.location.pathname !== "/order" &&
            window.location.pathname !== "/shopping_cart") {
            setCartAnchorEl(event.currentTarget);
        }
    };
    return (_jsxs(React.Fragment, { children: [_jsxs(AppBar, __assign({ className: "sticky z-2000" }, { children: [_jsx("section", { className: "block md:hidden" }), _jsxs(Toolbar, __assign({ className: "hidden md:flex flex-row gap-12 justify-between content-center items-center bg-white" }, { children: [_jsx("section", __assign({ className: "flex gap-1 items-center md:w-[20rem] lg:w-[15rem]" }, { children: _jsx("img", { className: " hover:cursor-pointer", src: Logo, onClick: function () { return navigate("/"); } }) })), _jsx(SearchBar, {}), _jsxs("section", __assign({ className: "flex gap-8 justify-center content-center items-center" }, { children: [_jsx(Badge, __assign({ badgeContent: totalQuantity, color: "secondary", showZero: true }, { children: _jsx(ShoppingCartOutlined, { fontSize: "large", className: "text-rose-900 hover:cursor-pointer", onClick: handleOpenCart }) })), _jsx(Avatar, __assign({ className: "w-10 h-10 hover:cursor-pointer", onClick: function (event) {
                                            menuControl(event);
                                            event.stopPropagation();
                                        }, style: {
                                            backgroundColor: (user === null || user === void 0 ? void 0 : user.email) ? stringToColor(user === null || user === void 0 ? void 0 : user.email) : "#881337",
                                        } }, { children: user.email ? user.email[0].toUpperCase() : _jsx(PersonIcon, {}) }))] }))] })), _jsxs(Toolbar, __assign({ className: "flex md:hidden gap-1 items-center justify-between content-center bg-white" }, { children: [_jsx(Typography, __assign({ className: "text-rose-900 text-2xl lg:text-3xl font-bold hover:cursor-pointer md:hidden", onClick: function () { return setIsMenu(true); } }, { children: _jsx(AiOutlineMenu, {}) })), _jsx("section", __assign({ className: "flex gap-[0.1rem] min-[380px]:gap-1 items-center md:w-[20rem] lg:w-[15rem]" }, { children: _jsx("img", { src: Logo, className: " hover:cursor-pointer", style: { maxHeight: 50 }, onClick: function () { return navigate("/"); } }) })), _jsxs("section", __assign({ className: " flex gap-3 justify-between" }, { children: [_jsx(SearchIcon, { fontSize: "large", sx: { color: "#881337", padding: "0.25rem 0 0 0.5rem" }, onClick: function () { return setSearchDrawer(true); } }), _jsx("div", { children: _jsx(Badge, __assign({ badgeContent: totalQuantity, color: "secondary", className: "mt-1", showZero: true }, { children: _jsx(ShoppingCartOutlined, { sx: { fontSize: "1.8rem" }, className: "text-rose-900", onClick: handleOpenCart }) })) }), _jsx(Drawer, __assign({ anchor: "top", open: searchDrawer, onClose: toggleDrawer(false) }, { children: _jsx("div", __assign({ style: { padding: "1rem 0.4rem 1rem 0.4rem" } }, { children: _jsx(SearchBar, {}) })) })), _jsx(Avatar, __assign({ className: "w-8 h-8", onClick: function (event) { return menuControl(event); }, style: {
                                            backgroundColor: (user === null || user === void 0 ? void 0 : user.email) ? stringToColor(user === null || user === void 0 ? void 0 : user.email) : "#881337",
                                        } }, { children: user.email ? user.email[0].toUpperCase() : _jsx(PersonIcon, {}) }))] }))] })), _jsx("section", __assign({ className: "flex gap-2 bg-amber-50 pl-4 content-center items-center hidden md:block" }, { children: tabBar === null || tabBar === void 0 ? void 0 : tabBar.map(function (item, i) { return (_jsx(Button, __assign({ className: "text-black text-md md:text-base", onClick: function () { return navigate(item === null || item === void 0 ? void 0 : item.url); } }, { children: item === null || item === void 0 ? void 0 : item.name }), i)); }) }))] })), _jsx(ProfileMenu, { anchorEl: profileAnchorEl, setAnchorEl: setProfileAnchorEl }), _jsx(FloatingCart, { open: cartOpen, anchorEl: cartAnchorEl, handleClose: function () { return setCartAnchorEl(null); } }), _jsxs(Drawer, __assign({ anchor: "left", open: isMenu, onClose: function () { return setIsMenu(false); } }, { children: [_jsx("section", __assign({ style: { height: "3.5rem", backgroundColor: "rgb(255 251 235)" } }, { children: _jsxs(Typography, __assign({ style: { color: "black", padding: "15px" } }, { children: [" ", "Hello, ", user.email, " "] })) })), _jsx(List, { children: tabBar === null || tabBar === void 0 ? void 0 : tabBar.map(function (item, i) { return (_jsxs(ListItemButton, __assign({ onClick: function () { return [navigate(item === null || item === void 0 ? void 0 : item.url), setIsMenu(false)]; } }, { children: [_jsx(ListItemIcon, { children: item === null || item === void 0 ? void 0 : item.icon }), _jsx(ListItemText, { children: item === null || item === void 0 ? void 0 : item.name })] }), i)); }) })] })), _jsxs(Drawer, __assign({ anchor: "right", open: openProfile, PaperProps: {
                    sx: { width: "80%" },
                }, onClose: function () { return setOpenProfile(false); } }, { children: [_jsx("section", __assign({ style: { backgroundColor: "rgb(255 251 235)" } }, { children: _jsxs(Typography, __assign({ style: { color: "black", padding: "15px" } }, { children: [" ", "Hello, ", user.email, " "] })) })), _jsxs(List, __assign({ className: "flex flex-col min-h-screen" }, { children: [_jsxs(ListItemButton, __assign({ onClick: function () { return [navigate("/user_profile"), setOpenProfile(false)]; } }, { children: [_jsx(ListItemIcon, { children: _jsx(AccountCircle, { fontSize: "small" }) }), _jsx(ListItemText, { children: "My Account" })] })), _jsxs(ListItemButton, __assign({ onClick: function () { return [
                                    navigate("/user_profile/my_orders"),
                                    setOpenProfile(false),
                                ]; } }, { children: [_jsx(ListItemIcon, { children: _jsx(ShoppingBasket, { fontSize: "small" }) }), _jsx(ListItemText, { children: "My Orders" })] })), _jsxs(ListItemButton, __assign({ onClick: function () { return [navigate("/shopping_cart"), setOpenProfile(false)]; } }, { children: [_jsx(ListItemIcon, { children: _jsx(ShoppingCart, { fontSize: "small" }) }), _jsxs(ListItemText, { children: ["My Cart ", _jsx(Badge, { style: { paddingLeft: "1rem" }, badgeContent: totalQuantity, color: "secondary" }), " "] })] })), _jsxs(ListItemButton, __assign({ onClick: function () { return [navigate("/user_profile/to_review"), setOpenProfile(false)]; } }, { children: [_jsx(ListItemIcon, { children: _jsx(ReviewsIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "My Reviews" })] })), _jsxs(ListItemButton, __assign({ onClick: function () { return [navigate("/user_profile/my_wishlist"), setOpenProfile(false)]; } }, { children: [_jsx(ListItemIcon, { children: _jsx(FavoriteIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "My Wishlist" })] })), _jsxs(ListItemButton, __assign({ onClick: function () { return [navigate("/user_profile/my_appiontment"), setOpenProfile(false)]; } }, { children: [_jsx(ListItemIcon, { children: _jsx(Medication, { fontSize: "small" }) }), _jsx(ListItemText, { children: "My Appointment" })] })), _jsxs(ListItemButton, __assign({ onClick: function () {
                                    handleLogout();
                                    setOpenProfile(false);
                                } }, { children: [_jsx(ListItemIcon, { children: _jsx(Logout, { fontSize: "small" }) }), _jsx(ListItemText, { children: "Logout" })] }))] }))] }))] }));
};
export default Header;

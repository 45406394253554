var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography, Button } from "@mui/material";
import TrendingImage from "../../../../Static/makeup-uscaled.png";
import { useNavigate } from 'react-router-dom';
var Trending = function () {
    var navigate = useNavigate();
    return (_jsx("div", __assign({ style: {
            backgroundImage: "url(".concat(TrendingImage, ")"),
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            // width: "100vw",
        } }, { children: _jsx("section", __assign({ className: 'py-[5rem] px-[2.5rem] md:py-[10rem] md:px-[10rem]' }, { children: _jsxs("article", __assign({ style: {
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                }, className: 'flex flex-col gap-8 py-16 px-8 items-center rounded-xl' }, { children: [_jsx(Typography, __assign({ variant: 'h4', className: 'text-gray-700 font-alegreya', sx: { textAlign: "center" } }, { children: "BRING OUT THE HIDDEN BEAUTY" })), _jsx(Typography, __assign({ variant: 'h6', className: 'text-gray-700', sx: { textAlign: "center" } }, { children: "Discover the latest trends in beauty with our exclusive cosmetic collection. Featuring top-rated skincare, luxurious makeup, and innovative hair care products." })), _jsx(Button, __assign({ onClick: function () { return navigate("/product"); }, variant: 'outlined', className: 'bg-black text-white hover:bg-white hover:text-black border-black', size: 'large' }, { children: "Trending Items" }))] })) })) })));
};
export default Trending;

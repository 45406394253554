var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { RemoveOutlined, AddOutlined } from "@mui/icons-material";
import { Button, IconButton, InputAdornment } from "@mui/material";
import { useState } from "react";
import StyledTextField from "../StyledTextField";
import { useSelector, useDispatch } from "react-redux";
import { increaseCartItem, decreaseCartItem, addToCart, insertToCart } from "../../Redux/features/productCartSlice";
import GlobalSnackbar from "../GlobalSnackbar";
var AddCartButton = function (props) {
    var _a;
    var data = props.data;
    var _b = useState(false), alert = _b[0], setAlert = _b[1];
    var myCart = useSelector(function (state) { return state.cartState; });
    var dispatch = useDispatch();
    var handleIncreaseQuantity = function (productId, product, quantity) {
        var _a, _b;
        if (((_a = myCart[String(productId)]) === null || _a === void 0 ? void 0 : _a.quantity) < 1 || ((_b = myCart[String(productId)]) === null || _b === void 0 ? void 0 : _b.quantity) === undefined) {
            dispatch(addToCart({ product: product, quantity: quantity }));
        }
        else {
            dispatch(increaseCartItem(productId));
        }
    };
    var handleDecreaseQuantity = function (productId) {
        dispatch(decreaseCartItem(productId));
    };
    var handleAddToCart = function (product, quantity) {
        dispatch(addToCart({ product: product, quantity: quantity }));
    };
    var handleInsertToCart = function (product, quantity) {
        dispatch(insertToCart({ product: product, quantity: quantity }));
    };
    return (_jsxs(_Fragment, { children: [_jsx("section", __assign({ className: "mt-".concat(alert ? '14' : '0') }, { children: alert &&
                    _jsx(GlobalSnackbar, { verticalPosition: "top", horizontalPosition: "center", message: "Not enough on Inventory", alertType: "error", onfinish: function () { return setAlert(false); } }) })), _jsx(StyledTextField, { borderRadius: '0', value: ((_a = myCart[String(data._id || data.id)]) === null || _a === void 0 ? void 0 : _a.quantity) || 0, onChange: function (event) { return handleInsertToCart(data, Number(event.target.value)); }, type: 'number', variant: 'outlined', textAlign: 'right', sx: __assign({ margin: "0 0 0" }, props.sx), InputProps: {
                    startAdornment: (_jsx(InputAdornment, __assign({ position: "start" }, { children: _jsx(IconButton, __assign({ className: "hidden min-[420px]:block", onClick: function () { return handleDecreaseQuantity(data._id || data.id); } }, { children: _jsx(RemoveOutlined, {}) })) }))),
                    endAdornment: (_jsxs(InputAdornment, __assign({ position: "end" }, { children: [_jsx(IconButton, __assign({ className: "block min-[420px]:hidden", onClick: function () { return handleDecreaseQuantity(data._id || data.id); } }, { children: _jsx(RemoveOutlined, {}) })), _jsx(IconButton, __assign({ onClick: function () { return handleIncreaseQuantity(data._id || data.id, data, 1); } }, { children: _jsx(AddOutlined, {}) })), _jsx(Button, __assign({ onClick: function () { return handleAddToCart(data, 1); }, className: "bg-yellow-700 ml-4 hidden min-[420px]:block", variant: "contained" }, { children: "Add to Cart" }))] })))
                } })] }));
};
export default AddCartButton;

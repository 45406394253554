var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TableContainer, Table, TableHead, TableRow, TableCell, Typography, TableBody, TableFooter, TablePagination } from "@mui/material";
function GenericTable(props) {
    var columns = props.columns, data = props.data, total = props.total, rowsPerPage = props.rowsPerPage, page = props.page, onChangePage = props.onChangePage, onChangeRowsPerPage = props.onChangeRowsPerPage;
    return (_jsx(TableContainer, __assign({ className: "px-4" }, { children: _jsxs(Table, __assign({ className: "border-solid border-slate-300 border-2" }, { children: [_jsx(TableHead, { children: _jsx(TableRow, { children: Object.entries(columns).map(function (_a, index) {
                            var column = _a[0], col_vals = _a[1];
                            return (_jsx(TableCell, __assign({ sx: { width: col_vals.width } }, { children: _jsx(Typography, __assign({ className: "font-semibold text-center" }, { children: column })) }), index));
                        }) }) }), _jsx(TableBody, { children: data }), _jsx(TableFooter, { children: _jsx(TableRow, { children: _jsx(TablePagination, { rowsPerPageOptions: [2, 3, 5, 10], count: total, rowsPerPage: rowsPerPage, page: page, onPageChange: onChangePage, onRowsPerPageChange: onChangeRowsPerPage }) }) })] })) })));
}
export default GenericTable;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { AccountCircle, Medication, ShoppingBasket } from "@mui/icons-material";
import SidebarList from "../../Components/Sidebar";
import ReviewsIcon from "@mui/icons-material/Reviews";
import FavoriteIcon from "@mui/icons-material/Favorite";
var UserLayout = function () {
    var user = useSelector(function (state) { return state.userState; });
    var sidebarValues = [
        {
            name: "My Account",
            url: "/user_profile",
            iconSrc: _jsx(AccountCircle, {}),
            subOption: [],
        },
        {
            name: "My Orders",
            url: "/user_profile/my_orders",
            iconSrc: _jsx(ShoppingBasket, {}),
            subOption: [],
        },
        {
            name: "My Reviews",
            url: "/user_profile/to_review",
            iconSrc: _jsx(ReviewsIcon, {}),
            subOption: [],
        },
        {
            name: "My Wishlist",
            url: "/user_profile/my_wishlist",
            iconSrc: _jsx(FavoriteIcon, {}),
            subOption: [],
        },
        {
            name: "My Appointment",
            url: "/user_profile/my_appiontment",
            iconSrc: _jsx(Medication, {}),
            subOption: [],
        },
    ];
    return (_jsxs("div", __assign({ className: "bg-blue-50 p-4 lg:pr-0" }, { children: [_jsxs(Typography, __assign({ className: "text-gray-700 p-4", variant: "h4" }, { children: ["Hello", " ", "".concat((user === null || user === void 0 ? void 0 : user.firstname) === null || (user === null || user === void 0 ? void 0 : user.lastname) === null
                        ? user === null || user === void 0 ? void 0 : user.email
                        : "".concat(user === null || user === void 0 ? void 0 : user.firstname, " ").concat(user === null || user === void 0 ? void 0 : user.lastname))] })), _jsxs("section", __assign({ className: "grid grid-cols-12 gap-2 justify-between" }, { children: [_jsx(SidebarList, { options: sidebarValues, className: "col-span-2 hidden lg:block bg-white rounded-3xl" }), _jsx("main", __assign({ className: "col-span-12 lg:col-span-10 h-fit bg-white rounded-3xl shadow-2xl overflow-y-auto" }, { children: _jsx(Outlet, {}) }))] }))] })));
};
export default UserLayout;

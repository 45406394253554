var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from 'react';
import { deleteRole, getPaginatedRoles } from '../../../ApiGateways/role';
import { Button, TableRow, Typography, TableCell, IconButton, Collapse } from '@mui/material';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import GenericTable from '../../../Components/GenericTable';
import InsertRole from './Form';
import ConfirmDialog from '../../../Components/ConfirmDialog';
import { Context } from '../../../State/Provider';
function Row(props) {
    var _a;
    var row = props.row, colLen = props.colLen, reset = props.reset, setReset = props.setReset, setDeleteRoleData = props.setDeleteRoleData, index = props.index, setDeleteDialog = props.setDeleteDialog, updateProductData = props.updateProductData;
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    return (_jsxs(_Fragment, { children: [_jsxs(TableRow, { children: [_jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: row === null || row === void 0 ? void 0 : row.name })), _jsxs(TableCell, __assign({ sx: { textAlign: "center" } }, { children: [_jsx(IconButton, __assign({ "aria-label": "expand row", size: "small", onClick: function () { return setOpen(!open); } }, { children: open ? _jsx(KeyboardArrowUpIcon, {}) : _jsx(KeyboardArrowDownIcon, {}) })), Object.entries(row === null || row === void 0 ? void 0 : row.permissions).length] })), _jsxs(TableCell, __assign({ sx: { textAlign: "center" } }, { children: [_jsx(Button, __assign({ color: "info", onClick: function () {
                                    setDeleteRoleData({
                                        id: row === null || row === void 0 ? void 0 : row.id,
                                        index: index
                                    });
                                    updateProductData(row);
                                } }, { children: _jsx(EditIcon, {}) })), _jsx(Button, __assign({ color: "error", onClick: function () {
                                    setDeleteRoleData({
                                        id: row === null || row === void 0 ? void 0 : row.id,
                                        index: index
                                    });
                                    setDeleteDialog(true);
                                } }, { children: _jsx(DeleteIcon, {}) }))] }))] }), _jsx(TableRow, { children: _jsx(TableCell, __assign({ style: { paddingBottom: 0, paddingTop: 0 }, colSpan: colLen }, { children: _jsx(Collapse, __assign({ in: open, timeout: "auto", unmountOnExit: true }, { children: _jsx("div", __assign({ className: 'grid grid-cols-12' }, { children: (_a = Object.entries(row === null || row === void 0 ? void 0 : row.permissions)) === null || _a === void 0 ? void 0 : _a.map(function (_a) {
                                var key = _a[0], value = _a[1];
                                return (_jsx("div", __assign({ className: ' col-span-3 border-solid border-2 border-gray p-4 m-4' }, { children: _jsxs("div", __assign({ className: 'flex gap-2 text-center' }, { children: [_jsx(Typography, __assign({ className: ' font-semibold text-slate-700' }, { children: "".concat(key, ":") })), _jsx(Typography, { children: value === null || value === void 0 ? void 0 : value.map(function (item, index) { return (_jsxs("span", __assign({ className: ' flex gap-1' }, { children: [" ", "".concat(item)] }))); }) })] })) })));
                            }) })) })) })) })] }));
}
var Roles = function () {
    var searchStr = useContext(Context).searchStr;
    var _a = useState(), debounceTimeout = _a[0], setDebounceTimeout = _a[1];
    var _b = useState(false), showModal = _b[0], setShowModal = _b[1];
    var _c = useState(0), page = _c[0], setPage = _c[1];
    var _d = React.useState(false), open = _d[0], setOpen = _d[1];
    var _e = useState([]), roleData = _e[0], setRoleData = _e[1];
    var _f = useState(0), totalRole = _f[0], setTotalRole = _f[1];
    var _g = useState(10), rowsPerPage = _g[0], setRowsPerPage = _g[1];
    var _h = useState(false), reset = _h[0], setReset = _h[1];
    var _j = useState([]), modules = _j[0], setModules = _j[1];
    var _k = useState({
        id: "",
        index: 0,
    }), deleteRoleData = _k[0], setDeleteRoleData = _k[1];
    var _l = useState(false), deleteDialog = _l[0], setDeleteDialog = _l[1];
    var roleFormInitialState = {
        id: "",
        name: "",
        permissions: {},
        admin: false,
    };
    var _m = useState(roleFormInitialState), formData = _m[0], setFormData = _m[1];
    var columns = {
        "Role Name": { width: 180 },
        "Total Permissions": { width: 100 },
        "Action": { width: 180 },
    };
    useEffect(function () {
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        var timeoutId = setTimeout(function () {
            getPaginatedRoles(page + 1, rowsPerPage, searchStr === null || searchStr === void 0 ? void 0 : searchStr.role, function (data) {
                var _a, _b, _c;
                setRoleData((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.roles);
                setTotalRole((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.total);
                setModules((_c = data === null || data === void 0 ? void 0 : data.data) === null || _c === void 0 ? void 0 : _c.modules);
            }, function (res) { return console.log(res); });
        }, 1000);
        setDebounceTimeout(timeoutId);
        return function () {
            clearTimeout(timeoutId);
        };
    }, [reset, searchStr === null || searchStr === void 0 ? void 0 : searchStr.role]);
    var handleChangePage = function (event, newPage) {
        getPaginatedRoles(newPage + 1, rowsPerPage, searchStr === null || searchStr === void 0 ? void 0 : searchStr.role, function (data) {
            var _a, _b;
            setRoleData((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.roles);
            setTotalRole((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.total);
        }, function (res) { return console.log(res); });
        setPage(newPage);
    };
    var handleChangeRowsPerPage = function (event) {
        getPaginatedRoles(1, +event.target.value, searchStr === null || searchStr === void 0 ? void 0 : searchStr.role, function (data) {
            var _a, _b;
            setRoleData((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.roles);
            setTotalRole((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.total);
        }, function (res) { return console.log(res); });
        setRowsPerPage(+event.target.value);
    };
    var createTableRows = function (roles) { return (roles === null || roles === void 0 ? void 0 : roles.map(function (row, index) { return (_jsx(Row, { row: row, colLen: Object.keys(columns).length, reset: reset, setReset: setReset, setDeleteRoleData: setDeleteRoleData, index: index, setDeleteDialog: setDeleteDialog, updateProductData: updateProductData }, index)); })); };
    var updateProductData = function (role) {
        setFormData(role);
        setShowModal(true);
    };
    var handleDeleteRole = function (id, index) {
        deleteRole(id, function (data) {
            if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                var temp = __spreadArray([], roleData, true);
                temp.splice(index, 1);
                setRoleData(temp);
            }
        }, function (res) { return console.log(res); });
    };
    return (_jsxs("main", __assign({ className: ' mb-24' }, { children: [_jsx(Typography, __assign({ className: "text-white text-center text-xl bg-rose-900 shadow-2xl rounded-tl-3xl py-3" }, { children: "Roles" })), _jsx("section", __assign({ className: "grid gap-y-4 mt-5" }, { children: _jsx("div", __assign({ className: "flex gap-4 px-4" }, { children: _jsx(Button, __assign({ onClick: function () { return setShowModal(true); }, variant: "contained", className: "rounded-lg text-lg normal-case text-white bg-sky-800 w-fit" }, { children: "Add Role" })) })) })), _jsx("section", __assign({ className: "mt-5" }, { children: _jsx(GenericTable, { columns: columns, data: createTableRows(roleData), total: totalRole, rowsPerPage: rowsPerPage, page: page, onChangePage: handleChangePage, onChangeRowsPerPage: handleChangeRowsPerPage }) })), _jsxs("section", { children: [_jsx(InsertRole, { open: showModal, sx: { "& .MuiPaper-root": { maxWidth: "70vw", width: "50vw" } }, onClose: function () {
                            setShowModal(false);
                            setFormData(roleFormInitialState);
                        }, updateRole: function (data) {
                            var temp = __spreadArray([], roleData, true);
                            temp.splice(deleteRoleData === null || deleteRoleData === void 0 ? void 0 : deleteRoleData.index, 1);
                            setRoleData(__spreadArray([data], temp, true));
                        }, createRole: function (data) { setRoleData(__spreadArray([data], roleData, true)); }, formData: formData, setFormData: setFormData, reset: reset, setReset: setReset, modules: modules }), _jsx(ConfirmDialog, { open: deleteDialog, title: "Do you want to delete this Role ?", onClose: function () {
                            setDeleteDialog(false);
                            setDeleteRoleData({
                                id: "",
                                index: 0,
                            });
                        }, onConfirm: function () {
                            handleDeleteRole(deleteRoleData === null || deleteRoleData === void 0 ? void 0 : deleteRoleData.id, deleteRoleData === null || deleteRoleData === void 0 ? void 0 : deleteRoleData.index);
                            setDeleteRoleData({
                                id: "",
                                index: 0,
                            });
                            setDeleteDialog(false);
                        } })] })] })));
};
export default Roles;

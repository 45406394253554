var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from "react";
import { Typography, TableCell, TableRow, Button } from "@mui/material";
import { deleteProduct, getAllProduct, getProductView, } from "../../../ApiGateways/product";
import ProductForm from "./Form";
import GenericTable from "../../../Components/GenericTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../../../Components/ConfirmDialog";
import { Context } from "../../../State/Provider";
var Product = function () {
    var _a = useState(), debounceTimeout = _a[0], setDebounceTimeout = _a[1];
    var searchStr = useContext(Context).searchStr;
    var columns = {
        Id: { width: 50 },
        Title: { width: 200 },
        Price: { width: 100 },
        Inventory: { width: 100 },
        Preview: { width: 150 },
        Action: { width: 100 },
    };
    var navigate = useNavigate();
    var _b = useState(["pc"]), units = _b[0], setUnits = _b[1];
    var _c = useState(["pc"]), subunits = _c[0], setSubunits = _c[1];
    var _d = useState([]), categories = _d[0], setCategories = _d[1];
    var _e = useState({}), filters = _e[0], setFilters = _e[1];
    var initialFormState = {
        id: "",
        product_id: "",
        name: "",
        summary: "",
        description: {},
        categories: [],
        filters: {},
        unit: "pc",
        multiplier: 0,
        subunit: "pc",
        price: {
            value: 0,
            unit: "pc",
            currency: "BDT",
        },
        quantity: {
            value: 0,
            unit: "pc",
        },
        restock_alert: {
            value: 0,
            unit: "pc",
        },
        images: [],
        videos: [],
        ratings: 0,
        ratings_given: 0,
    };
    var _f = useState(initialFormState), formData = _f[0], setFormData = _f[1];
    var _g = useState([]), allProduct = _g[0], setAllProduct = _g[1];
    var _h = useState(0), totalProduct = _h[0], setTotalProduct = _h[1];
    var _j = useState(0), page = _j[0], setPage = _j[1];
    var _k = useState(10), rowsPerPage = _k[0], setRowsPerPage = _k[1];
    var _l = useState(false), showFormModal = _l[0], setShowFormModal = _l[1];
    var _m = useState(false), reset = _m[0], setReset = _m[1];
    var _o = useState(false), showUploadModal = _o[0], setShowUploadModal = _o[1];
    var _p = useState(false), deleteDialog = _p[0], setDeleteDialog = _p[1];
    var _q = useState({
        id: "",
        index: 0,
    }), deleteProductData = _q[0], setDeleteProductData = _q[1];
    useEffect(function () {
        getProductView(function (data) {
            if (data.data.filters) {
                setCategories(data.data.filters.categories);
                setFilters(data.data.filters.filters);
            }
            if (data.data.units)
                setUnits(__spreadArray(__spreadArray([], units, true), data.data.units, true));
            if (data.data.subunits)
                setSubunits(__spreadArray(__spreadArray([], subunits, true), data.data.subunits, true));
        }, function (res) { return console.log(res); });
    }, [reset]);
    useEffect(function () {
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        var timeoutId = setTimeout(function () {
            getAllProduct(page + 1, rowsPerPage, searchStr === null || searchStr === void 0 ? void 0 : searchStr.product, function (data) {
                var _a, _b;
                setTotalProduct((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.total);
                setAllProduct((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.products);
            }, function (res) { return console.log(res); });
        }, 1000);
        setDebounceTimeout(timeoutId);
        return function () {
            clearTimeout(timeoutId);
        };
    }, [searchStr === null || searchStr === void 0 ? void 0 : searchStr.product]);
    var sanitizeDescriptionValues = function (descriptionObject) {
        var sanitizedDescription = {};
        for (var _i = 0, _a = Object.entries(descriptionObject); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            var sanitizedValue = value.replace(/(<p><br><\/p>\s*)+$/, "");
            sanitizedDescription[key] = sanitizedValue;
        }
        return sanitizedDescription;
    };
    var handleChangePage = function (event, newPage) {
        getAllProduct(newPage + 1, rowsPerPage, searchStr === null || searchStr === void 0 ? void 0 : searchStr.product, function (data) {
            var _a, _b;
            // console.log(data);
            setTotalProduct((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.total);
            setAllProduct((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.products);
        }, function (res) { return console.log(res); });
        setPage(newPage);
    };
    var handleChangeRowsPerPage = function (event) {
        getAllProduct(1, +event.target.value, searchStr === null || searchStr === void 0 ? void 0 : searchStr.product, function (data) {
            var _a, _b;
            setTotalProduct((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.total);
            setAllProduct((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.products);
        }, function (res) { return console.log(res); });
        setRowsPerPage(+event.target.value);
    };
    var updateProduct = function (product) {
        setFormData(function (prevFormData) { return (__assign(__assign(__assign({}, prevFormData), product), { description: sanitizeDescriptionValues(product === null || product === void 0 ? void 0 : product.description) })); });
        setShowFormModal(true);
    };
    var handleDeleteProduct = function (id, index) {
        deleteProduct(id, function (data) {
            var temp = __spreadArray([], allProduct, true);
            temp.splice(index, 1);
            setAllProduct(temp);
        }, function (res) { return console.log(res); });
    };
    var createTableRows = function (products) {
        return products === null || products === void 0 ? void 0 : products.map(function (product, index) { return (_jsxs(TableRow, { children: [_jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: product.product_id })), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: product.name })), _jsxs(TableCell, __assign({ sx: { textAlign: "center" } }, { children: [product.price.currency, " ", product.price.value, " / ", product.price.unit] })), _jsxs(TableCell, __assign({ sx: { textAlign: "center" } }, { children: [product.quantity.value, " ", product.quantity.unit] })), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: _jsx(Button, __assign({ variant: "contained", onClick: function () {
                            return window.open("/product_details/".concat(product.id), "_blank");
                        } }, { children: "View Product Details" })) })), _jsxs(TableCell, __assign({ sx: { textAlign: "center" } }, { children: [_jsx(Button, __assign({ color: "info", onClick: function () {
                                setDeleteProductData({
                                    id: product === null || product === void 0 ? void 0 : product.id,
                                    index: index,
                                });
                                updateProduct(product);
                            } }, { children: _jsx(EditIcon, {}) })), _jsx(Button, __assign({ color: "error", onClick: function () {
                                setDeleteProductData({
                                    id: product === null || product === void 0 ? void 0 : product.id,
                                    index: index,
                                });
                                setDeleteDialog(true);
                            } }, { children: _jsx(DeleteIcon, {}) }))] }))] }, index)); });
    };
    return (_jsx("main", __assign({ className: "mb-24" }, { children: _jsxs("div", __assign({ className: "grid gap-y-4" }, { children: [_jsx(Typography, __assign({ className: "text-white text-center text-xl bg-rose-900 shadow-2xl rounded-tl-3xl py-3" }, { children: "Products" })), _jsxs("section", __assign({ className: "flex gap-4 px-4" }, { children: [_jsx(Button, __assign({ onClick: function () { return setShowFormModal(true); }, className: "rounded-lg text-lg normal-case text-white bg-sky-800 w-fit", variant: "contained" }, { children: "Insert Product" })), _jsx(Button, __assign({ onClick: function () { return setShowUploadModal(true); }, className: "rounded-lg text-lg normal-case text-white bg-amber-600 w-fit", variant: "contained" }, { children: "Upload CSV" }))] })), _jsx(ProductForm, { open: showFormModal, onClose: function () {
                        // setReset(!reset);
                        setShowFormModal(false);
                        setFormData(initialFormState);
                    }, updateProduct: function (data) {
                        var temp = __spreadArray([], allProduct, true);
                        temp.splice(deleteProductData === null || deleteProductData === void 0 ? void 0 : deleteProductData.index, 1);
                        setAllProduct(__spreadArray([data], temp, true));
                    }, createProduct: function (data) {
                        setAllProduct(__spreadArray([data], allProduct, true));
                    }, units: units, subunits: subunits, categories: categories, filters: filters, sx: { "& .MuiPaper-root": { maxWidth: "70vw", width: "70vw" } }, formData: formData, setFormData: setFormData, initialFormState: initialFormState, reset: reset, setReset: setReset }), _jsx(ConfirmDialog, { open: deleteDialog, title: "Do you want to delete this Product ?", onClose: function () {
                        setDeleteDialog(false);
                        setDeleteProductData({
                            id: "",
                            index: 0,
                        });
                    }, onConfirm: function () {
                        handleDeleteProduct(deleteProductData === null || deleteProductData === void 0 ? void 0 : deleteProductData.id, deleteProductData === null || deleteProductData === void 0 ? void 0 : deleteProductData.index);
                        setDeleteProductData({
                            id: "",
                            index: 0,
                        });
                        setDeleteDialog(false);
                    } }), _jsx(GenericTable, { columns: columns, data: createTableRows(allProduct), total: totalProduct, rowsPerPage: rowsPerPage, page: page, onChangePage: handleChangePage, onChangeRowsPerPage: handleChangeRowsPerPage })] })) })));
};
export default Product;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { searchProduct } from '../../../ApiGateways/product';
import { Autocomplete, InputAdornment, IconButton } from '@mui/material';
import { url } from '../../../config';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import StyledTextField from '../../StyledTextField';
import { setProducts } from '../../../Redux/features/productsSlice';
import { useDispatch } from 'react-redux';
var SearchBar = function () {
    var dispatch = useDispatch();
    var _a = useState([]), searchResults = _a[0], setSearchResults = _a[1];
    var _b = useState(null), retrieved = _b[0], setRetrieved = _b[1];
    var navigate = useNavigate();
    var debounceTimeout;
    var searchWithDelay = function (key) {
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(function () {
            searchProduct(String(key), function (data) {
                console.log(data);
                if (key) {
                    setSearchResults(__spreadArray([{
                            id: '',
                            name: String(key),
                            product_id: '',
                            categories: [],
                            images: [],
                        }], data === null || data === void 0 ? void 0 : data.data, true));
                }
                else {
                    setSearchResults(data === null || data === void 0 ? void 0 : data.data);
                }
            }, function (res) { return console.log(res); });
        }, 500);
    };
    var searchByCategory = function (key) {
        searchProduct(String(key), function (data) {
            dispatch(setProducts(data === null || data === void 0 ? void 0 : data.data));
        }, function (res) { return console.log(res); });
    };
    return (_jsx("div", __assign({ style: { width: "100%" } }, { children: _jsx(Autocomplete, { value: retrieved, options: searchResults, fullWidth: true, freeSolo: true, onChange: function (event, newValue) {
                setRetrieved(newValue === null || newValue === void 0 ? void 0 : newValue.name);
                (newValue === null || newValue === void 0 ? void 0 : newValue.id) && navigate("/product_details/".concat(newValue === null || newValue === void 0 ? void 0 : newValue.id));
                (newValue === null || newValue === void 0 ? void 0 : newValue.id) === '' && searchByCategory(newValue === null || newValue === void 0 ? void 0 : newValue.name);
            }, autoHighlight: true, getOptionLabel: function (option) {
                if (typeof option === 'string')
                    return option;
                else
                    return option.name + " " + option.categories.join(" ");
            }, renderOption: function (props, option) { return (_jsx("li", __assign({}, props, { children: _jsxs("span", __assign({ style: { display: "flex", gap: "2rem", alignItems: "center" } }, { children: [(option === null || option === void 0 ? void 0 : option.id) ?
                            _jsx(_Fragment, { children: _jsx("img", { style: { width: "1.5rem", height: "1.5rem" }, src: (option === null || option === void 0 ? void 0 : option.images.length) > 0 ? "".concat(url, "/product/file/").concat(option.images[0]) : "", alt: "" }) })
                            :
                                _jsx(_Fragment, { children: _jsx(SearchIcon, {}) }), option === null || option === void 0 ? void 0 : option.name] })) }))); }, sx: {
                "& .MuiAutocomplete-inputRoot": {
                    padding: 0,
                    paddingLeft: "0.5rem"
                },
                "& .MuiAutocomplete-input": {
                    padding: 0,
                    paddingLeft: "0.5rem"
                },
            }, renderInput: function (params) { return (_jsx(StyledTextField, __assign({}, params, { fullWidth: true, onChange: function (e) { searchWithDelay(e.target.value); }, InputProps: __assign(__assign({}, params.InputProps), { endAdornment: (_jsxs(InputAdornment, __assign({ position: 'end' }, { children: [_jsx(IconButton, __assign({ "aria-label": 'filter' }, { children: _jsx(TuneIcon, { className: "w-6 h-6" }) })), _jsx(IconButton, __assign({ "aria-label": 'search' }, { children: _jsx(SearchIcon, { className: "w-6 h-6" }) }))] }))) }) }))); } }) })));
};
export default SearchBar;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useContext } from 'react';
import NewAddress, { NewAddressContext } from '../../../../Components/NewAddress';
import BasicInfo from '../../../../Components/BasicInfo';
import { Typography, FormControl, Select, InputLabel, MenuItem, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { getAddressByLabel, getAllAddress } from '../../../../ApiGateways/address';
var Information = function () {
    var _a;
    var setShipping = useContext(NewAddressContext).setShipping;
    var _b = useState(10), lastPage = _b[0], setLastPage = _b[1];
    var _c = useState({}), addLabel = _c[0], setAddLabel = _c[1];
    var _d = useState(''), selectedLabel = _d[0], setSelectedLabel = _d[1];
    useEffect(function () {
        getAllAddress(1, lastPage, function (data) {
            var _a, _b, _c;
            if (((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.total) !== lastPage) {
                setLastPage((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.total);
            }
            var uniqueLabels = (((_c = data === null || data === void 0 ? void 0 : data.data) === null || _c === void 0 ? void 0 : _c.items) || []).reduce(function (acc, item) {
                var label = item === null || item === void 0 ? void 0 : item.addressLabel;
                if (label) {
                    acc[label] = label;
                }
                return acc;
            }, {});
            setAddLabel(__assign({}, uniqueLabels));
        }, function (res) { return console.log(res); });
    }, [lastPage]);
    var getAddByLabel = function (label) {
        getAddressByLabel(label, function (data) {
            var _a;
            // const { postcode, state, suburb, city, ...restData } = data?.data?.items[0];
            setShipping(__assign({}, (_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items[0]));
            // setShipping({ ...restData });
        }, function (res) { return console.log(res); });
    };
    return (_jsx("main", { children: _jsxs("form", __assign({ className: 'flex flex-col gap-y-8' }, { children: [_jsxs("section", { children: [_jsx(Typography, __assign({ variant: 'h5', className: "mb-5" }, { children: "Basic Information" })), _jsx(BasicInfo, {})] }), _jsxs("section", __assign({ className: 'grid grid-cols-5 gap-3' }, { children: [_jsx(Typography, __assign({ variant: 'h5', className: 'mb-5 col-span-5 md:col-span-2' }, { children: "Enter Shipping Address" })), _jsx("div", __assign({ className: "col-span-5 md:col-span-3" }, { children: _jsxs(FormControl, __assign({ fullWidth: true }, { children: [_jsx(InputLabel, __assign({ id: "demo-simple-select-label" }, { children: "Select Address" })), _jsx(Select, __assign({ sx: { borderRadius: "10px" }, labelId: "demo-simple-select-label", id: "demo-simple-select", label: "Select Address", value: selectedLabel, onChange: function (e) {
                                            var selectedValue = e.target.value;
                                            setSelectedLabel(selectedValue);
                                            getAddByLabel(selectedValue);
                                        } }, { children: (_a = Object.keys(addLabel)) === null || _a === void 0 ? void 0 : _a.map(function (key, index) { return (_jsx(MenuItem, __assign({ value: key }, { children: key }), index)); }) }))] })) })), _jsxs(Accordion, __assign({ className: 'col-span-5 block md:hidden bg-rose-100' }, { children: [_jsx(AccordionSummary, { children: "Enter New Address" }), _jsx(AccordionDetails, { children: _jsx(NewAddress, {}) })] })), _jsx("div", __assign({ className: 'col-span-5 hidden md:block' }, { children: _jsx(NewAddress, {}) }))] }))] })) }));
};
export default Information;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Outlet } from "react-router-dom";
import Header from "../../Components/Navbar/Header";
import Footer from '../../Components/Footer';
import BottomNavigator from '../../Components/BottomNavigator';
var UserLayout = function () {
    return (_jsxs("main", __assign({ className: "flex flex-col min-h-screen" }, { children: [_jsx(Header, {}), _jsx("section", __assign({ className: "flex-grow" }, { children: _jsx(Outlet, {}) })), _jsx(Footer, {}), _jsx(BottomNavigator, {})] })));
};
export default UserLayout;

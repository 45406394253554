import { configureStore } from "@reduxjs/toolkit";
import singleStateReducer from "../features/singleStateSlice";
import userStateReducer from "../features/userSlice";
import cartStateReducer from "../features/productCartSlice";
import productStateReducer from "../features/productsSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
var persistConfig = {
    key: "root",
    version: 1,
    storage: storage
};
var reducer = combineReducers({
    singleState: singleStateReducer,
    userState: userStateReducer,
    cartState: cartStateReducer,
    productState: productStateReducer,
});
var persistedReducer = persistReducer(persistConfig, reducer);
export var store = configureStore({
    reducer: persistedReducer
});

var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {};
var cartSlice = createSlice({
    name: 'cart',
    initialState: initialState,
    reducers: {
        addToCart: function (state, action) {
            var _a = action.payload, product = _a.product, quantity = _a.quantity;
            var productId = product._id || product.id;
            var subtotal = product.price.value * quantity;
            if (state[productId]) {
                state[productId].quantity += quantity;
                state[productId].subtotal += subtotal;
            }
            else {
                state[productId] = {
                    product: product,
                    quantity: quantity,
                    subtotal: subtotal,
                    isCheckout: true,
                };
            }
        },
        insertToCart: function (state, action) {
            var _a = action.payload, product = _a.product, quantity = _a.quantity;
            var productId = product._id || product.id;
            var subtotal = product.price.value * quantity;
            if (quantity <= 0) {
                delete state[productId];
            }
            else if (state[productId]) {
                state[productId].quantity = quantity;
                state[productId].subtotal = subtotal;
            }
            else {
                state[productId] = {
                    product: product,
                    quantity: quantity,
                    subtotal: subtotal,
                    isCheckout: true,
                };
            }
        },
        increaseCartItem: function (state, action) {
            var productId = action.payload;
            if (state[productId]) {
                state[productId].quantity += 1;
                state[productId].subtotal += state[productId].product.price.value;
            }
        },
        decreaseCartItem: function (state, action) {
            var productId = action.payload;
            if (state[productId]) {
                state[productId].quantity -= 1;
                state[productId].subtotal -= state[productId].product.price.value;
                if (state[productId].quantity < 1) {
                    delete state[productId];
                }
            }
        },
        removeFromCart: function (state, action) {
            delete state[action.payload];
        },
        clearCart: function (state) {
            return initialState;
        },
        setCheckout: function (state, action) {
            var productId = action.payload;
            state[productId].isCheckout = !state[productId].isCheckout;
        },
        resetCheckout: function (state) {
            for (var productId in state) {
                state[productId].isCheckout = false;
            }
        },
    },
});
export var addToCart = (_a = cartSlice.actions, _a.addToCart), insertToCart = _a.insertToCart, removeFromCart = _a.removeFromCart, increaseCartItem = _a.increaseCartItem, decreaseCartItem = _a.decreaseCartItem, clearCart = _a.clearCart, setCheckout = _a.setCheckout, resetCheckout = _a.resetCheckout;
export default cartSlice.reducer;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, Typography, DialogTitle, DialogContent, Rating, Button, } from "@mui/material";
import React, { useState } from "react";
import StyledTextField from "../../../../../Components/StyledTextField";
import FileUploader from "../../../../../Components/FileUploader";
import { createReview, updateReview } from "../../../../../ApiGateways/review";
var EditReview = function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var open = props.open, setOpen = props.setOpen, formData = props.formData, setFormData = props.setFormData, extraFormData = props.extraFormData, handleClose = props.handleClose;
    var _h = React.useState(2), star = _h[0], setStar = _h[1];
    var _j = useState([]), images = _j[0], setImages = _j[1];
    var addReview = function (event) {
        event.preventDefault();
        var submitImages = __spreadArray([], images, true);
        var data = __assign({ product_id: (extraFormData === null || extraFormData === void 0 ? void 0 : extraFormData.product_id) || null, order_id: (extraFormData === null || extraFormData === void 0 ? void 0 : extraFormData.order_id) || null, review_id: (extraFormData === null || extraFormData === void 0 ? void 0 : extraFormData.review_id) || null }, formData);
        var submitFormData = new FormData();
        submitFormData.append("form", JSON.stringify(data));
        submitImages.map(function (image, idx) { return submitFormData.append("images", image); });
        if (extraFormData === null || extraFormData === void 0 ? void 0 : extraFormData.review_id) {
            updateReview(submitFormData, function (data) {
                if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                    formClose();
                }
            }, function (res) { return console.log(res); });
        }
        else if ((extraFormData === null || extraFormData === void 0 ? void 0 : extraFormData.order_id) && (extraFormData === null || extraFormData === void 0 ? void 0 : extraFormData.product_id)) {
            createReview(submitFormData, function (data) {
                if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                    formClose();
                }
            }, function (res) { return console.log(res); });
        }
    };
    var noNegative = function (value) {
        if (value > 0)
            return Number(value);
        else
            return 0;
    };
    var formClose = function () {
        setImages([]);
        handleClose();
    };
    return (_jsx("div", { children: _jsxs(Dialog, __assign({ open: open, onClose: formClose, fullWidth: true }, { children: [_jsx(DialogTitle, { children: _jsx(Typography, __assign({ variant: "h4" }, { children: "Write A Review" })) }), _jsx(DialogContent, { children: _jsxs("form", __assign({ onSubmit: addReview }, { children: [_jsxs("section", __assign({ style: {
                                    display: "flex",
                                    gap: "0 1rem",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                } }, { children: [_jsx(Typography, __assign({ variant: "h6" }, { children: "Rating:" })), _jsx(Rating, { name: "simple-controlled", value: formData === null || formData === void 0 ? void 0 : formData.rating, precision: 0.5, onChange: function (event, newValue) {
                                            setFormData(__assign(__assign({}, formData), { rating: Number(newValue) }));
                                        } })] })), _jsx(StyledTextField, { label: "Summary", value: (_a = formData === null || formData === void 0 ? void 0 : formData.review_msg) === null || _a === void 0 ? void 0 : _a.title, onChange: function (event) {
                                    return setFormData(__assign(__assign({}, formData), { review_msg: __assign(__assign({}, formData.review_msg), { title: event.target.value }) }));
                                }, required: true, fullWidth: true, multiline: true, sx: { margin: "0.5rem 0" } }), _jsx(StyledTextField, { label: "Description", value: (_b = formData === null || formData === void 0 ? void 0 : formData.review_msg) === null || _b === void 0 ? void 0 : _b.summary, onChange: function (event) {
                                    return setFormData(__assign(__assign({}, formData), { review_msg: __assign(__assign({}, formData.review_msg), { summary: event.target.value }) }));
                                }, required: true, fullWidth: true, multiline: true, rows: 2, sx: { margin: "0.5rem 0" } }), _jsx("section", __assign({ style: {
                                    display: "flex",
                                    gap: "0 1rem",
                                    alignItems: "center",
                                    marginTop: "2rem",
                                    marginBottom: "1rem",
                                } }, { children: _jsx(Typography, __assign({ variant: "h6" }, { children: "Select Filters" })) })), _jsx("div", { children: _jsxs("section", __assign({ style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "1rem",
                                    } }, { children: [_jsx(StyledTextField, { label: "Skin Concern", sx: { margin: "0.5rem 0" }, fullWidth: true, value: (_c = formData === null || formData === void 0 ? void 0 : formData.review_msg) === null || _c === void 0 ? void 0 : _c.skin_concern, onChange: function (event) {
                                                return setFormData(__assign(__assign({}, formData), { review_msg: __assign(__assign({}, formData.review_msg), { skin_concern: event.target.value }) }));
                                            } }), _jsx(StyledTextField, { label: "Gender", sx: { margin: "0.5rem 0" }, fullWidth: true, value: (_d = formData === null || formData === void 0 ? void 0 : formData.review_msg) === null || _d === void 0 ? void 0 : _d.gender, onChange: function (event) {
                                                return setFormData(__assign(__assign({}, formData), { review_msg: __assign(__assign({}, formData.review_msg), { gender: event.target.value }) }));
                                            } }), _jsx(StyledTextField, { label: "Age", sx: { margin: "0.5rem 0" }, fullWidth: true, type: "number", value: (_e = formData === null || formData === void 0 ? void 0 : formData.review_msg) === null || _e === void 0 ? void 0 : _e.age, onChange: function (event) {
                                                return setFormData(__assign(__assign({}, formData), { review_msg: __assign(__assign({}, formData.review_msg), { age: noNegative(Number(event.target.value)) }) }));
                                            } }), _jsx(StyledTextField, { label: "Shade Group", sx: { margin: "0.5rem 0" }, fullWidth: true, value: (_f = formData === null || formData === void 0 ? void 0 : formData.review_msg) === null || _f === void 0 ? void 0 : _f.shade_group, onChange: function (event) {
                                                return setFormData(__assign(__assign({}, formData), { review_msg: __assign(__assign({}, formData.review_msg), { shade_group: event.target.value }) }));
                                            } }), _jsx(StyledTextField, { label: "Skin Type", sx: { margin: "0.5rem 0" }, fullWidth: true, value: (_g = formData === null || formData === void 0 ? void 0 : formData.review_msg) === null || _g === void 0 ? void 0 : _g.skin_type, onChange: function (event) {
                                                return setFormData(__assign(__assign({}, formData), { review_msg: __assign(__assign({}, formData.review_msg), { skin_type: event.target.value }) }));
                                            } }), _jsx(FileUploader, { style: {
                                                display: "flex",
                                                gap: "0 1rem",
                                                alignItems: "center",
                                                margin: "0.5rem 0",
                                            }, fileType: "images", names: formData.images, removeName: function (name) {
                                                return setFormData(__assign(__assign({}, formData), { images: formData.images.filter(function (image) { return image !== name; }) }));
                                            }, onFileSelect: function (files) { return setImages(files); } }), _jsxs("section", __assign({ style: {
                                                display: "flex",
                                                gap: "0 1rem",
                                                alignItems: "center",
                                                width: "100%",
                                                justifyContent: "space-between",
                                            } }, { children: [_jsx("article", __assign({ style: {
                                                        display: "flex",
                                                        gap: "0 1rem",
                                                        alignItems: "center",
                                                    } }, { children: _jsx(Button, __assign({ variant: "contained", color: "info", type: "submit" }, { children: "Submit" })) })), _jsx(Button, __assign({ variant: "contained", onClick: formClose }, { children: "Close" }))] }))] })) })] })) })] })) }));
};
export default EditReview;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Dialog, Typography, DialogTitle, DialogContent, DialogActions, Snackbar, Alert, } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import BasicInfo, { BasicInfoContext } from "../../../../Components/BasicInfo";
import { validateAll, validateAllAllowSpace, } from "../../../../Utils";
import NewAddress, { NewAddressContext, } from "../../../../Components/NewAddress";
import { AddCircleOutline } from "@mui/icons-material";
import AddressBook from "./AddressBook";
import StyledTextField from "../../../../Components/StyledTextField";
import PasswordManager from "./PasswordManager";
import { UpdateUserData, changeAccountPassword, } from "../../../../ApiGateways/user";
import { updateUserAddrerss, createUserAddrerss, deleteAddress, } from "../../../../ApiGateways/address";
import GlobalSnackbar from "../../../../Components/GlobalSnackbar";
import ConfirmDialog from "../../../../Components/ConfirmDialog";
var ManageAccount = function () {
    var user = useSelector(function (state) { return state.userState; });
    var _a = useState(false), openAddress = _a[0], setOpenAddress = _a[1];
    var _b = useState(false), openPassword = _b[0], setOpenPassword = _b[1];
    var _c = useState(false), isUpdate = _c[0], setIsUpdate = _c[1];
    var _d = useState(""), addressId = _d[0], setAddressId = _d[1];
    var _e = useState(false), alert = _e[0], setAlert = _e[1];
    var _f = useState([]), allAddress = _f[0], setAllAddress = _f[1];
    var _g = useState(false), openPassChangeAlert = _g[0], setOpenPassChangeAlert = _g[1];
    var _h = useState({
        status: "info",
        message: "",
    }), passChangeMsg = _h[0], setPassChangeMsg = _h[1];
    var _j = useState({
        isActive: false,
        verticalPosition: "top",
        horizontalPosition: "center",
        message: "",
        alertType: "success",
    }), snackbarState = _j[0], setSnackbarState = _j[1];
    var _k = useState({
        firstname: (user === null || user === void 0 ? void 0 : user.firstname) || "",
        lastname: (user === null || user === void 0 ? void 0 : user.lastname) || "",
        email: (user === null || user === void 0 ? void 0 : user.email) || "",
        phone: (user === null || user === void 0 ? void 0 : user.phone) || "",
        dob: user === null || user === void 0 ? void 0 : user.date_of_birth,
        gender: user === null || user === void 0 ? void 0 : user.gender,
    }), basicInfo = _k[0], setBasicInfo = _k[1];
    var _l = useState(""), addressName = _l[0], setAddressName = _l[1];
    var _m = useState(""), previousPass = _m[0], setPreviousPass = _m[1];
    var _o = useState({
        newPass: "",
        confirmPass: "",
    }), pass = _o[0], setPass = _o[1];
    var _p = useState(""), passErrMsg = _p[0], setPassErrMsg = _p[1];
    var _q = useState({
        addressLine1: "",
        addressLine2: "",
        landmark: "",
        country: {
            label: "",
            code: "",
            phone: "",
        },
        state: "",
        city: "",
        suburb: "",
        postcode: "",
    }), shippingAddress = _q[0], setShippingAddress = _q[1];
    var _r = useState(false), reset = _r[0], setReset = _r[1];
    var _s = useState({
        id: "",
        index: 0,
    }), deleteaddressData = _s[0], setDeleteaddressData = _s[1];
    var _t = useState(false), deleteDialog = _t[0], setDeleteDialog = _t[1];
    var handleAddressClose = function () {
        setAddressId("");
        setOpenAddress(false);
        setIsUpdate(false);
        setShippingAddress({
            addressLine1: "",
            addressLine2: "",
            landmark: "",
            country: {
                label: "",
                code: "",
                phone: "",
            },
            state: "",
            city: "",
            suburb: "",
            postcode: "",
        });
        setAddressName("");
    };
    var handlePasswordClose = function () {
        setOpenPassword(false);
    };
    var addNewAddress = function (event) {
        event.preventDefault();
        var body = __assign({ addressLabel: addressName }, shippingAddress);
        createUserAddrerss(JSON.stringify(body), function (data) {
            setAllAddress(__spreadArray([data === null || data === void 0 ? void 0 : data.data], allAddress, true));
            handleAddressClose();
        }, function (res) { return console.log(res); });
    };
    var changePassword = function (event) {
        event.preventDefault();
        setPassErrMsg("");
        if (previousPass === pass.newPass) {
            setPassErrMsg("Your Current Password Cannot be your New Password");
        }
        else if ((pass === null || pass === void 0 ? void 0 : pass.newPass) !== (pass === null || pass === void 0 ? void 0 : pass.confirmPass)) {
            setPassErrMsg("Confirm Password not matched");
        }
        else {
            var body = {
                old_password: previousPass,
                new_password: pass === null || pass === void 0 ? void 0 : pass.confirmPass,
            };
            changeAccountPassword(JSON.stringify(body), function (data) {
                setPassChangeMsg(__assign(__assign({}, passChangeMsg), { status: "success", message: "Password is successfully updated" }));
                setOpenPassChangeAlert(true);
                setOpenPassword(false);
            }, function (res) {
                if ((res === null || res === void 0 ? void 0 : res.success) === false) {
                    setPassChangeMsg(__assign(__assign({}, passChangeMsg), { status: "error", message: "Password didn't matched" }));
                    setOpenPassChangeAlert(true);
                    setOpenPassword(false);
                }
            });
        }
    };
    var getAddress = function (address, index) {
        setDeleteaddressData({ id: address === null || address === void 0 ? void 0 : address.id, index: index });
        setAddressId(address === null || address === void 0 ? void 0 : address.id);
        setIsUpdate(true);
        setAddressName(address === null || address === void 0 ? void 0 : address.addressLabel);
        setShippingAddress(function (prevData) { return (__assign(__assign({}, prevData), address)); });
        setOpenAddress(true);
    };
    var addressDelete = function (id, index) {
        setDeleteaddressData({ id: id, index: index });
        setDeleteDialog(true);
    };
    var handleDeleteAddress = function (id, index) {
        deleteAddress(id, function (data) {
            if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                var temp = __spreadArray([], allAddress, true);
                temp.splice(index, 1);
                setAllAddress(temp);
            }
        }, function (res) { return console.log(res); });
    };
    var updateAddress = function (event) {
        event.preventDefault();
        var body = {
            addressLabel: addressName,
            addressLine1: shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.addressLine1,
            addressLine2: shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.addressLine2,
            landmark: shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.landmark,
            country: shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.country,
            state: shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.state,
            city: shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.city,
            suburb: shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.suburb,
            postcode: shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.postcode,
        };
        updateUserAddrerss(JSON.stringify(body), addressId, function (data) {
            console.log(data);
            var temp = __spreadArray([], allAddress, true);
            temp.splice(deleteaddressData === null || deleteaddressData === void 0 ? void 0 : deleteaddressData.index, 1);
            setAllAddress(__spreadArray([data === null || data === void 0 ? void 0 : data.data], temp, true));
            // setReset(!reset);
            setAddressId("");
            handleAddressClose();
        }, function (res) { return console.log(res); });
    };
    var updateUserInfo = function (event) {
        event.preventDefault();
        var data = {
            firstname: basicInfo === null || basicInfo === void 0 ? void 0 : basicInfo.firstname,
            lastname: basicInfo === null || basicInfo === void 0 ? void 0 : basicInfo.lastname,
            phone: basicInfo === null || basicInfo === void 0 ? void 0 : basicInfo.phone,
            email: basicInfo === null || basicInfo === void 0 ? void 0 : basicInfo.email,
            gender: null,
            date_of_birth: null,
            username: null
        };
        UpdateUserData(JSON.stringify(data), function (value) {
            if ((value === null || value === void 0 ? void 0 : value.success) === true) {
                setSnackbarState({
                    isActive: true,
                    verticalPosition: "top",
                    horizontalPosition: "center",
                    message: "Information updated succesfully !",
                    alertType: "success"
                });
            }
        }, function (res) {
            setSnackbarState({
                isActive: true,
                verticalPosition: "top",
                horizontalPosition: "center",
                message: String(res === null || res === void 0 ? void 0 : res.message),
                alertType: "error"
            });
        });
    };
    return (_jsxs("div", __assign({ className: "p-8" }, { children: [_jsx(Snackbar, __assign({ open: openPassChangeAlert, autoHideDuration: 6000, onClose: function () { return setOpenPassChangeAlert(false); } }, { children: _jsx(Alert, __assign({ onClose: function () { return setOpenPassChangeAlert(false); }, severity: (passChangeMsg === null || passChangeMsg === void 0 ? void 0 : passChangeMsg.status) === "success" ? "success" : "error", sx: { width: "100%" } }, { children: passChangeMsg === null || passChangeMsg === void 0 ? void 0 : passChangeMsg.message })) })), (snackbarState === null || snackbarState === void 0 ? void 0 : snackbarState.isActive) && (_jsx(GlobalSnackbar, { verticalPosition: snackbarState === null || snackbarState === void 0 ? void 0 : snackbarState.verticalPosition, horizontalPosition: snackbarState === null || snackbarState === void 0 ? void 0 : snackbarState.horizontalPosition, message: snackbarState === null || snackbarState === void 0 ? void 0 : snackbarState.message, alertType: snackbarState === null || snackbarState === void 0 ? void 0 : snackbarState.alertType, onfinish: function () {
                    setSnackbarState(__assign(__assign({}, snackbarState), { isActive: false }));
                } })), _jsx(Typography, __assign({ className: "text-gray-700", variant: "h4", sx: { textAlign: "center" } }, { children: "Manage Account" })), _jsxs("div", __assign({ className: "flex flex-col gap-8 mt-5" }, { children: [_jsx("form", __assign({ onSubmit: updateUserInfo }, { children: _jsxs("section", { children: [_jsx(BasicInfoContext.Provider, __assign({ value: {
                                        basicInfo: basicInfo,
                                        setBasicInfo: function (info) { return setBasicInfo(__assign({}, info)); },
                                    } }, { children: _jsx(BasicInfo, {}) })), _jsx(Button, __assign({ variant: "outlined", type: "submit", className: " border-rose-900 mt-2 hover:bg-red-900 hover:text-white" }, { children: "Update" }))] }) })), _jsx("section", __assign({ className: "bg-rose-900 text-white p-6 cursor-pointer", onClick: function () { return setOpenPassword(true); } }, { children: _jsx(Typography, __assign({ variant: "h5" }, { children: "Change Password" })) })), _jsxs("section", __assign({ className: "flex gap-4 items-center justify-between lg:justify-none" }, { children: [_jsx(Typography, __assign({ variant: "h5" }, { children: "Address Book" })), _jsx(Button, __assign({ variant: "outlined", className: "text-gray-700 bg-gray-50 border-gray-900 hover:bg-gray-50 hidden lg:block", onClick: function () { return setOpenAddress(true); } }, { children: "Add New Address" })), _jsx(AddCircleOutline, { onClick: function () { return setOpenAddress(true); }, className: "lg:hidden text-rose-900 cursor-pointer" })] })), _jsx(AddressBook, { updateAddress: getAddress, addressDelete: addressDelete, allAddress: allAddress, setAllAddress: setAllAddress })] })), _jsxs(Dialog, __assign({ disableScrollLock: true, fullWidth: true, open: openAddress, onClose: handleAddressClose }, { children: [_jsx(DialogTitle, { children: "Address" }), _jsx(DialogContent, { children: _jsx("section", __assign({ style: { marginTop: "1rem" } }, { children: _jsx(NewAddressContext.Provider, __assign({ value: {
                                    shipping: __assign({}, shippingAddress),
                                    setShipping: function (address) { return setShippingAddress(__assign({}, address)); },
                                } }, { children: _jsxs("form", __assign({ onSubmit: isUpdate ? updateAddress : addNewAddress }, { children: [_jsx(StyledTextField, { sx: { marginBottom: "1rem" }, value: addressName, required: true, fullWidth: true, label: "Address Label", placeholder: "Save Address Under This Name", borderRadius: "10px", InputLabelProps: {
                                                shrink: true,
                                            }, onChange: function (e) {
                                                return setAddressName(validateAllAllowSpace(e.target.value));
                                            }, onBlur: function (e) { return setAddressName(validateAll(e.target.value)); } }), _jsx(NewAddress, {}), _jsx(Button, __assign({ style: { marginTop: "1rem" }, type: "submit", variant: "contained" }, { children: "".concat(isUpdate ? "Update Address" : "Add New Address") }))] })) })) })) }), _jsx(DialogActions, { children: _jsx(Button, __assign({ variant: "contained", color: "error", onClick: handleAddressClose }, { children: "Close" })) })] })), _jsxs(Dialog, __assign({ disableScrollLock: true, fullWidth: true, open: openPassword, onClose: handlePasswordClose }, { children: [_jsx(DialogTitle, { children: "Change Password" }), _jsx(DialogContent, { children: _jsx("section", __assign({ style: { marginTop: "1rem" } }, { children: _jsxs("form", __assign({ onSubmit: changePassword }, { children: [_jsx(PasswordManager, { previousPass: previousPass, setPreviousPass: setPreviousPass, pass: pass, setPass: setPass }), passErrMsg && (_jsx(Typography, __assign({ color: "error" }, { children: passErrMsg }))), _jsx(Button, __assign({ style: { marginTop: "1rem" }, type: "submit", variant: "contained", color: "success" }, { children: "Set Password" }))] })) })) }), _jsx(DialogActions, { children: _jsx(Button, __assign({ variant: "contained", color: "error", onClick: handlePasswordClose }, { children: "Close" })) })] })), _jsx("section", { children: _jsx(ConfirmDialog, { open: deleteDialog, title: "Do you want to delete this Address ?", onClose: function () {
                        setDeleteDialog(false);
                        setDeleteaddressData({
                            id: "",
                            index: 0,
                        });
                    }, onConfirm: function () {
                        handleDeleteAddress(deleteaddressData === null || deleteaddressData === void 0 ? void 0 : deleteaddressData.id, deleteaddressData === null || deleteaddressData === void 0 ? void 0 : deleteaddressData.index);
                        setDeleteaddressData({
                            id: "",
                            index: 0,
                        });
                        setDeleteDialog(false);
                    } }) })] })));
};
export default ManageAccount;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Button, Typography, TableCell, TableRow } from '@mui/material';
import GenericTable from '../../../../Components/GenericTable';
import { getAllAddress } from '../../../../ApiGateways/address';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
var AddressBook = function (props) {
    var updateAddress = props.updateAddress, addressDelete = props.addressDelete, allAddress = props.allAddress, setAllAddress = props.setAllAddress;
    var columns = {
        "Name": { width: 60 },
        "Address": { width: 250 },
        "Country": { width: 60 },
        "Landmark": { width: 100 },
        "State": { width: 70 },
        "City": { width: 70 },
        "Area": { width: 70 },
        "Postcode": { width: 40 },
        "Actions": { width: 60 },
    };
    var _a = useState(0), totalAddress = _a[0], setTotalAddress = _a[1];
    var _b = useState(0), page = _b[0], setPage = _b[1];
    var _c = useState(10), rowsPerPage = _c[0], setRowsPerPage = _c[1];
    var handleChangePage = function (event, newPage) {
        getAllAddress(newPage + 1, rowsPerPage, function (data) {
            var _a, _b;
            setAllAddress((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items);
            setTotalAddress((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.total);
        }, function (res) { return console.log(res); });
        setPage(newPage);
    };
    var handleChangeRowsPerPage = function (event) {
        getAllAddress(1, +event.target.value, function (data) {
            var _a, _b;
            setAllAddress((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items);
            setTotalAddress((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.total);
        }, function (res) { return console.log(res); });
        setRowsPerPage(+event.target.value);
    };
    useEffect(function () {
        getAllAddress(page + 1, rowsPerPage, function (data) {
            var _a, _b;
            setAllAddress((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items);
            setTotalAddress((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.total);
        }, function (res) { return console.log(res); });
    }, []);
    var createTableRows = function (addresses) { return (addresses === null || addresses === void 0 ? void 0 : addresses.map(function (address, index) {
        var _a;
        return (_jsx(_Fragment, { children: _jsxs(TableRow, { children: [_jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: address === null || address === void 0 ? void 0 : address.addressLabel })), _jsxs(TableCell, { children: [_jsxs(Typography, { children: [_jsx("span", __assign({ className: " font-semibold" }, { children: "Addess-1:" })), address === null || address === void 0 ? void 0 : address.addressLine1] }), _jsxs(Typography, { children: [_jsx("span", __assign({ className: " font-semibold" }, { children: "Addess-2:" })), address === null || address === void 0 ? void 0 : address.addressLine2] })] }), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: (_a = address === null || address === void 0 ? void 0 : address.country) === null || _a === void 0 ? void 0 : _a.label })), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: address === null || address === void 0 ? void 0 : address.landmark })), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: address === null || address === void 0 ? void 0 : address.state })), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: address === null || address === void 0 ? void 0 : address.city })), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: address === null || address === void 0 ? void 0 : address.suburb })), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: address === null || address === void 0 ? void 0 : address.postcode })), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: _jsxs("span", __assign({ style: { display: "flex" } }, { children: [_jsx(Button, __assign({ onClick: function () { return updateAddress(address, index); } }, { children: _jsx(EditIcon, {}) })), _jsx(Button, __assign({ onClick: function () {
                                        addressDelete(address === null || address === void 0 ? void 0 : address.id, index);
                                    } }, { children: _jsx(DeleteIcon, {}) }))] })) }))] }, index) }));
    })); };
    return (_jsx("div", { children: _jsx(GenericTable, { columns: columns, data: createTableRows(allAddress), total: totalAddress, rowsPerPage: rowsPerPage, page: page, onChangePage: handleChangePage, onChangeRowsPerPage: handleChangeRowsPerPage }) }));
};
export default AddressBook;

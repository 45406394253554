var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Typography } from "@mui/material";
import ProductCard from "../../../../Components/ProductCard";
import { setCheckout } from "../../../../Redux/features/productCartSlice";
import { getWishlistByUser } from "../../../../ApiGateways/wishlist";
var MainCart = function () {
    var myCart = useSelector(function (state) { return state.cartState; });
    var dispatch = useDispatch();
    var _a = useState([]), allWishListId = _a[0], setAllWishListId = _a[1];
    var _b = useState(false), reset = _b[0], setReset = _b[1];
    var handleChangeCheckbox = function (event, productId) {
        dispatch(setCheckout(productId));
    };
    useEffect(function () {
        getWishlistByUser(function (data) {
            var _a, _b;
            var allId = (_b = ((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items)) === null || _b === void 0 ? void 0 : _b.map(function (item, i) { return item === null || item === void 0 ? void 0 : item.id; });
            setAllWishListId(allId);
        }, function (res) {
            console.log(res);
        });
    }, [reset]);
    return (_jsxs("div", { children: [_jsx(Typography, __assign({ variant: 'h5', className: "mb-5" }, { children: "Shopping Cart" })), _jsx("section", __assign({ className: 'mt-2 md:mt-8' }, { children: Object.entries(myCart).map(function (_a, index) {
                    var key = _a[0], value = _a[1];
                    return (_jsxs("div", __assign({ className: "flex flex-col md:flex-row nowrap gap-x-2 p-4  w-fit items-start" }, { children: [_jsx(Checkbox, { color: 'primary', sx: {
                                    height: 'fit-content',
                                }, checked: value.isCheckout, onChange: function (event) { return handleChangeCheckbox(event, key); }, inputProps: { 'aria-label': 'controlled' } }), _jsx(ProductCard, { item: value.product, inWishlist: allWishListId.some(function (wishlistId) { var _a; return wishlistId === ((_a = value === null || value === void 0 ? void 0 : value.product) === null || _a === void 0 ? void 0 : _a.id); }), horizontal: true, reset: reset, setReset: setReset })] }), index));
                }) }))] }));
};
export default MainCart;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Avatar, Button, Typography, Rating, Divider } from "@mui/material";
import { getProductById } from "../../../ApiGateways/product";
import { url } from "../../../config";
import { addToCart } from "../../../Redux/features/productCartSlice";
import { useDispatch, useSelector } from "react-redux";
import AddCartButton from "../../../Components/AddCartButton";
import ReactQuill from "react-quill";
import "./index.scss";
import Review from "../Review/Review";
import { getReviewById } from "../../../ApiGateways/review";
var ProductDetails = function (props) {
    var snackbarState = props.snackbarState, setSnackbarState = props.setSnackbarState;
    var id = useParams().id;
    var myCart = useSelector(function (state) { return state.cartState; });
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var _a = useState(), productData = _a[0], setProductData = _a[1];
    var _b = useState(0), numFiles = _b[0], setNumFiles = _b[1];
    var _c = useState(0), currentFileIdx = _c[0], setCurrentFileIdx = _c[1];
    var _d = useState(0), videoIndex = _d[0], setVideoIndex = _d[1];
    var _e = useState(false), inStock = _e[0], setInstock = _e[1];
    var _f = useState(true), showImage = _f[0], setShowImage = _f[1];
    var _g = useState(false), showVideo = _g[0], setShowVideo = _g[1];
    var _h = useState(1), zoom = _h[0], setZoom = _h[1];
    var _j = useState({ x: 0, y: 0 }), position = _j[0], setPosition = _j[1];
    var _k = useState([]), allReview = _k[0], setAllReview = _k[1];
    var handleMouseMove = function (e) {
        var _a = e.currentTarget.getBoundingClientRect(), left = _a.left, top = _a.top, width = _a.width, height = _a.height;
        var x = (e.clientX - left) / width;
        var y = (e.clientY - top) / height;
        setPosition({ x: x, y: y });
    };
    var quillModule = {
        toolbar: false,
    };
    var buyNow = function (product, quantity) {
        dispatch(addToCart({ product: product, quantity: quantity }));
        navigate("/order");
        if (setSnackbarState) {
            setSnackbarState({
                isActive: true,
                verticalPosition: "top",
                horizontalPosition: "center",
                message: "Product has been added to cart.",
                alertType: "success"
            });
        }
    };
    useEffect(function () {
        getProductById(id, function (data) {
            var _a, _b, _c, _d;
            setProductData(data === null || data === void 0 ? void 0 : data.data);
            setNumFiles((((_b = (_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.images) === null || _b === void 0 ? void 0 : _b.length) || 0) + (((_d = (_c = data === null || data === void 0 ? void 0 : data.data) === null || _c === void 0 ? void 0 : _c.videos) === null || _d === void 0 ? void 0 : _d.length) || 0));
        }, function (res) { return console.log(res); });
    }, [id]);
    useEffect(function () {
        getReviewById(id, function (data) {
            var _a;
            setAllReview((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items);
            console.log(data);
        }, function (res) { return console.log(res); });
    }, [id]);
    useEffect(function () {
        if ((productData === null || productData === void 0 ? void 0 : productData.quantity.unit) === (productData === null || productData === void 0 ? void 0 : productData.restock_alert.unit)) {
            setInstock(((productData === null || productData === void 0 ? void 0 : productData.quantity.value) || 0) >
                ((productData === null || productData === void 0 ? void 0 : productData.restock_alert.value) || 0));
        }
        else if ((productData === null || productData === void 0 ? void 0 : productData.quantity.unit) === (productData === null || productData === void 0 ? void 0 : productData.unit) &&
            (productData === null || productData === void 0 ? void 0 : productData.restock_alert.unit) === (productData === null || productData === void 0 ? void 0 : productData.subunit)) {
            setInstock(((productData === null || productData === void 0 ? void 0 : productData.quantity.value) || 0) * ((productData === null || productData === void 0 ? void 0 : productData.multiplier) || 0) >
                ((productData === null || productData === void 0 ? void 0 : productData.restock_alert.value) || 0));
        }
        else if ((productData === null || productData === void 0 ? void 0 : productData.quantity.unit) === (productData === null || productData === void 0 ? void 0 : productData.subunit) &&
            (productData === null || productData === void 0 ? void 0 : productData.restock_alert.unit) === (productData === null || productData === void 0 ? void 0 : productData.unit)) {
            setInstock(((productData === null || productData === void 0 ? void 0 : productData.quantity.value) || 0) >
                ((productData === null || productData === void 0 ? void 0 : productData.restock_alert.value) || 0) *
                    ((productData === null || productData === void 0 ? void 0 : productData.multiplier) || 0));
        }
    }, [
        productData === null || productData === void 0 ? void 0 : productData.quantity,
        productData === null || productData === void 0 ? void 0 : productData.restock_alert,
        productData === null || productData === void 0 ? void 0 : productData.unit,
        productData === null || productData === void 0 ? void 0 : productData.subunit,
        productData === null || productData === void 0 ? void 0 : productData.multiplier,
    ]);
    return (_jsxs("main", { children: [_jsxs("div", __assign({ className: "mx-4 my-8 bg-gradient-to-tr from-transparent to-rose-200 rounded-xl flex flex-col md:flex-row flex-wrap gap-x-8", style: { padding: "2rem" } }, { children: [_jsxs("section", __assign({ className: "flex flex-wrap justify-center md:justify-around md:flex-col gap-4 md:w-[4rem] mb-5 md:mb-0" }, { children: [productData === null || productData === void 0 ? void 0 : productData.images.map(function (image, index) { return (_jsx(Avatar, { className: "shadow-xl border-slate-200 border-solid border-2", alt: "".concat(image), src: "".concat(url, "/product/file/").concat(image), sx: { width: "4rem", height: "4rem" }, onClick: function () {
                                    setCurrentFileIdx(index);
                                    setShowVideo(false);
                                    setShowImage(true);
                                }, onMouseEnter: function () {
                                    setCurrentFileIdx(index);
                                    setShowVideo(false);
                                    setShowImage(true);
                                }, onMouseLeave: function () {
                                    setCurrentFileIdx(index);
                                    setShowVideo(false);
                                    setShowImage(true);
                                } }, index)); }), productData === null || productData === void 0 ? void 0 : productData.videos.map(function (video, index) { return (_jsx(Avatar, { className: "shadow-xl border-slate-200 border-solid border-2", alt: "".concat(video), src: "".concat(url, "/product/file/").concat(video), sx: { width: "4rem", height: "4rem" }, onClick: function () {
                                    setVideoIndex(index);
                                    setShowImage(false);
                                    setShowVideo(true);
                                }, onMouseEnter: function () {
                                    setVideoIndex(index);
                                    setShowImage(false);
                                    setShowVideo(true);
                                }, onMouseLeave: function () {
                                    setVideoIndex(index);
                                    setShowImage(false);
                                    setShowVideo(true);
                                } }, index)); })] })), numFiles > 0 && (_jsxs("section", __assign({ className: "md:w-[calc((100%-5rem)*0.4)] mb-5 md:mb-0" }, { children: [showImage && (_jsx("div", __assign({ className: "magnify-image-container", onMouseMove: handleMouseMove, onMouseEnter: function () { return setZoom(2); }, onMouseLeave: function () { return setZoom(1); } }, { children: _jsx("img", { src: "".concat(url, "/product/file/").concat(productData === null || productData === void 0 ? void 0 : productData.images[currentFileIdx]), alt: "product-image", style: {
                                        height: "40rem",
                                        objectFit: "contain",
                                        alignSelf: "center",
                                        transform: "scale(".concat(zoom, ")"),
                                        transformOrigin: "".concat(position.x * 100, "% ").concat(position.y * 100, "%"),
                                    } }) }))), showVideo && (_jsx("video", { style: {
                                    height: "40rem",
                                    objectFit: "contain",
                                    alignSelf: "center",
                                }, src: "".concat(url, "/product/file/").concat(productData === null || productData === void 0 ? void 0 : productData.videos[videoIndex]), controls: true }))] }))), _jsxs("div", __assign({ className: "flex flex-col gap-y-4 w-full md:w-[calc((100%-5rem)*0.5)]" }, { children: [_jsx(Typography, __assign({ className: "font-alegreya text-3xl md:text-4xl " }, { children: productData === null || productData === void 0 ? void 0 : productData.name })), _jsxs("section", __assign({ className: "flex gap-x-1 items-center" }, { children: [_jsx(Rating, { name: "read_only", value: productData === null || productData === void 0 ? void 0 : productData.ratings, precision: 0.5, readOnly: true }), _jsx(Typography, __assign({ sx: { display: "inline", color: "#881337", marginLeft: "1rem" }, variant: "h6" }, { children: _jsx("u", { children: productData === null || productData === void 0 ? void 0 : productData.ratings_given }) }))] })), _jsxs("section", __assign({ className: "flex gap-x-2 items-center" }, { children: [_jsxs(Typography, __assign({ variant: "h5" }, { children: ["\u09F3 ", productData === null || productData === void 0 ? void 0 : productData.price.value] })), _jsx(Typography, __assign({ variant: "h6", style: {
                                            backgroundColor: inStock ? "#4d7c0f" : "#c2410c",
                                            padding: "0.15rem 1rem",
                                            borderRadius: "0.5rem",
                                            color: "white",
                                        } }, { children: inStock ? "In Stock" : "Out of Stock" }))] })), _jsx(Typography, __assign({ className: " text-xl text-gray-900 font-semibold" }, { children: productData === null || productData === void 0 ? void 0 : productData.summary })), (productData === null || productData === void 0 ? void 0 : productData.filters) &&
                                Object.entries(productData.filters).map(function (_a, index) {
                                    var key = _a[0], values = _a[1];
                                    return (_jsxs("div", __assign({ className: "grid grid-cols-12 items-center" }, { children: [_jsx(Typography, __assign({ className: "col-span-5" }, { children: _jsx("b", { children: Boolean(key)
                                                        ? key
                                                            .split(" ")
                                                            .map(function (k) {
                                                            return k ? k[0].toUpperCase() + k.slice(1) : "";
                                                        })
                                                            .join(" ") + ": "
                                                        : "" }) })), _jsx(Typography, __assign({ className: "col-span-7" }, { children: values
                                                    .map(function (v) {
                                                    return v
                                                        .split(" ")
                                                        .map(function (k) { return (k ? k[0].toUpperCase() + k.slice(1) : ""); })
                                                        .join(" ");
                                                })
                                                    .join(", ") }))] }), index));
                                }), _jsx(Divider, { sx: { borderWidth: "0.1rem", borderColor: "#AAAAAA" } }), inStock &&
                                _jsxs("section", __assign({ className: " flex flex-col md:flex-row md:items-center flex-wrap gap-4" }, { children: [_jsx("div", { children: _jsx(Button, __assign({ className: "  bg-red-500 text-white hover:bg-red-700 border-black bg-opacity-80 py-3 px-3", variant: "contained", onClick: function () { return buyNow(productData, 1); }, sx: {
                                                    height: "fit-content",
                                                } }, { children: "Buy Now" })) }), _jsx("div", { children: productData && _jsx(AddCartButton, { data: productData }) })] }))] }))] })), _jsx("div", __assign({ className: " grid grid-cols-12 gap-4 mx-4" }, { children: (productData === null || productData === void 0 ? void 0 : productData.description) &&
                    Object.entries(productData.description).map(function (_a, index) {
                        var key = _a[0], values = _a[1];
                        return (_jsxs("div", __assign({ className: "col-span-12 md:col-span-6" }, { children: [_jsx(Typography, __assign({ className: " text-center text-xl" }, { children: _jsx("b", { children: Boolean(key)
                                            ? key
                                                .split(" ")
                                                .map(function (k) {
                                                return k ? k[0].toUpperCase() + k.slice(1) : "";
                                            })
                                                .join(" ") + ": "
                                            : "" }) })), _jsx(ReactQuill, { modules: quillModule, value: values, readOnly: true, theme: "snow", className: "no-border" })] }), index));
                    }) })), _jsx(Review, { reviewData: allReview, setReviewData: setAllReview })] }));
};
export default ProductDetails;

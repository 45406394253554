var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useContext } from 'react';
import HighlightedCard from "./HighlightedCard";
import Trending from "./Trending";
import ScienceExperts from "./ScienceExperts";
import { getBestProducts } from '../../../ApiGateways/product';
import { getDiscountedProducts } from '../../../ApiGateways/promo';
import { getWishlistByUser } from '../../../ApiGateways/wishlist';
import Carousel from '../../../Components/Carousel';
import { Context } from '../../../State/Provider';
var Home = function () {
    var _a = useState([]), bestProducts = _a[0], setBestProducts = _a[1];
    var _b = useState([]), discountedProducts = _b[0], SetDiscountedProducts = _b[1];
    var _c = useState([]), allWishListId = _c[0], setAllWishListId = _c[1];
    var _d = useState(false), reset = _d[0], setReset = _d[1];
    var setAllWish = useContext(Context).setAllWish;
    var images = [
        'https://i.ibb.co/0fW9rDT/cr-1.png',
        'https://i.ibb.co/Lvh4cwq/cr-2.png',
        'https://i.ibb.co/McGWF7f/cr-3.png',
    ];
    var carouselData = [
        { imgUrl: "https://i.ibb.co/tmrPb1s/b-1.jpg", path: "/product", buttonText: "Shop Now",
            carouselText: "Elevate Your Beauty With Our Cosmetics"
        },
        { imgUrl: "https://i.ibb.co/Pxcx7WT/b-2.jpg", path: "/product", buttonText: "Shop Now",
            carouselText: "Discover Your Beauty Potential"
        },
        { imgUrl: "https://i.ibb.co/gjbjmbK/b-3.jpg", path: "/product", buttonText: "Shop Now",
            carouselText: "Unleash Your Inner Glamour"
        },
    ];
    useEffect(function () {
        getBestProducts(function (data) {
            console.log(data === null || data === void 0 ? void 0 : data.data["Best Products"]);
            setBestProducts(data === null || data === void 0 ? void 0 : data.data["Best Products"]);
        }, function (res) {
            // const newRes = res.json()
            console.log(res);
        });
    }, []);
    useEffect(function () {
        getWishlistByUser(function (data) {
            var _a, _b, _c;
            setAllWish((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items);
            var allId = (_c = ((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.items)) === null || _c === void 0 ? void 0 : _c.map(function (item, i) { return item === null || item === void 0 ? void 0 : item.id; });
            setAllWishListId(allId);
        }, function (res) {
            setAllWish([]);
            console.log(res);
        });
    }, [reset]);
    useEffect(function () {
        getDiscountedProducts(function (data) {
            SetDiscountedProducts(data === null || data === void 0 ? void 0 : data.data);
        }, function (res) { return console.log(res); });
    }, []);
    return (_jsxs("div", __assign({ className: 'flex flex-col gap-12 mb-8' }, { children: [_jsx(Carousel, { items: carouselData }), _jsx(HighlightedCard, { message: "Best Sellings", data: bestProducts, allWishListId: allWishListId, reset: reset, setReset: setReset }), _jsx(Trending, {}), _jsx(HighlightedCard, { message: "Discounts", data: discountedProducts, allWishListId: allWishListId, reset: reset, setReset: setReset }), _jsx(ScienceExperts, {})] })));
};
export default Home;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography, Button, Backdrop, CircularProgress } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StyledTextField from '../../../../Components/StyledTextField';
import { sendPasswordResetMail } from '../../../../ApiGateways/mail_sending';
import GlobalSnackbar from '../../../../Components/GlobalSnackbar';
import Logo from '../../../../Static/logo_vertical_red_and_blue.png';
var ForgotPassword = function () {
    var navigate = useNavigate();
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState({
        isActive: false,
        verticalPosition: "top",
        horizontalPosition: "center",
        message: "",
        alertType: "success",
    }), snackbarState = _b[0], setSnackbarState = _b[1];
    var _c = useState(''), email = _c[0], setEmail = _c[1];
    var sendResetUrl = function (event) {
        event.preventDefault();
        var body = {
            email: email
        };
        setLoading(true);
        sendPasswordResetMail(JSON.stringify(body), function (data) {
            if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                setLoading(false);
                setSnackbarState({
                    isActive: true,
                    verticalPosition: "top",
                    horizontalPosition: "center",
                    message: "Check Your Email !",
                    alertType: "success"
                });
            }
        }, function (res) {
            setLoading(false);
            setSnackbarState({
                isActive: true,
                verticalPosition: "top",
                horizontalPosition: "center",
                message: "User Not Found !",
                alertType: "error"
            });
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Backdrop, __assign({ sx: { color: "#fff", zIndex: function (theme) { return theme.zIndex.drawer + 1; } }, open: loading, onClick: function () { } }, { children: _jsxs("div", __assign({ style: { display: 'flex', gap: '2rem' } }, { children: [_jsx(CircularProgress, { color: "inherit" }), _jsx(Typography, __assign({ className: " text-center" }, { children: "Please wait" }))] })) })), (snackbarState === null || snackbarState === void 0 ? void 0 : snackbarState.isActive) && (_jsx(GlobalSnackbar, { verticalPosition: snackbarState === null || snackbarState === void 0 ? void 0 : snackbarState.verticalPosition, horizontalPosition: snackbarState === null || snackbarState === void 0 ? void 0 : snackbarState.horizontalPosition, message: snackbarState === null || snackbarState === void 0 ? void 0 : snackbarState.message, alertType: snackbarState === null || snackbarState === void 0 ? void 0 : snackbarState.alertType, onfinish: function () {
                    setSnackbarState(__assign(__assign({}, snackbarState), { isActive: false }));
                } })), _jsxs("form", __assign({ onSubmit: sendResetUrl, className: "grid text-center bg-white py-14 pb-8 px-8 shadow-xl rounded-md md:my-[10%] md:mx-[25%]" }, { children: [_jsx("div", __assign({ className: 'flex justify-center mb-5' }, { children: _jsx("img", { className: 'w-40', src: Logo, alt: "" }) })), _jsx(Typography, __assign({ className: 'mb-8 text-4xl' }, { children: "Reset Password" })), _jsx(StyledTextField, { fullWidth: true, required: true, value: email, variant: "outlined", label: "Email", type: 'email', onChange: function (e) { return setEmail(e.target.value.replace(/\s/g, '')); }, className: 'my-2 text-lg ' }), _jsxs("section", __assign({ className: "my-4 flex justify-between" }, { children: [_jsx(Button, __assign({ type: "submit", className: "text-lg bg-black hover:bg-white hover:text-black opacity-80", variant: "contained", size: 'large' }, { children: "Send Email" })), _jsx(Button, __assign({ className: 'text-rose-900', onClick: function () { return navigate('/signin'); } }, { children: _jsx(Typography, { children: "Return to Login" }) }))] }))] }))] }));
};
export default ForgotPassword;

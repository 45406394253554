var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, TableCell, TableRow, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { deleteDoctor, getAllDoctors } from "../../../ApiGateways/consult";
import GenericTable from "../../../Components/GenericTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { convertToLocalTime } from "../../../Utils";
import InsertDoctor from "./Form";
import ConfirmDialog from "../../../Components/ConfirmDialog";
import { Context } from "../../../State/Provider";
var Doctors = function () {
    var searchStr = useContext(Context).searchStr;
    var _a = useState(), debounceTimeout = _a[0], setDebounceTimeout = _a[1];
    var _b = useState(false), showModal = _b[0], setShowModal = _b[1];
    var _c = useState([]), doctorData = _c[0], setDoctorData = _c[1];
    var _d = useState(0), totalDoctor = _d[0], setTotalDoctor = _d[1];
    var _e = useState(0), page = _e[0], setPage = _e[1];
    var _f = useState(10), rowsPerPage = _f[0], setRowsPerPage = _f[1];
    var _g = useState(false), reset = _g[0], setReset = _g[1];
    var doctorFormInitialState = {
        id: "",
        name: "",
        designation: "",
        speciality: "",
        educational_background: "",
        fee: null,
        schedule: {},
    };
    var _h = useState(doctorFormInitialState), formData = _h[0], setFormData = _h[1];
    var _j = useState({
        id: "",
        index: 0,
    }), deleteDoctorData = _j[0], setDeleteDoctorData = _j[1];
    var _k = useState(false), deleteDialog = _k[0], setDeleteDialog = _k[1];
    var columns = {
        "Name": { width: 120 },
        "Designation": { width: 100 },
        "Speciality": { width: 100 },
        "Degree": { width: 100 },
        "Schedule": { width: 100 },
        "Visiting Fee": { width: 100 },
        "Action": { width: 100 },
    };
    useEffect(function () {
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        var timeoutId = setTimeout(function () {
            getAllDoctors(page + 1, rowsPerPage, searchStr === null || searchStr === void 0 ? void 0 : searchStr.doctor, function (data) {
                var _a, _b;
                console.log(data);
                setDoctorData((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items);
                setTotalDoctor((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.total);
            }, function (res) { return console.log(res); });
        }, 1000);
        setDebounceTimeout(timeoutId);
        return function () {
            clearTimeout(timeoutId);
        };
    }, [reset, searchStr === null || searchStr === void 0 ? void 0 : searchStr.doctor]);
    var handleChangePage = function (event, newPage) {
        getAllDoctors(newPage + 1, rowsPerPage, searchStr === null || searchStr === void 0 ? void 0 : searchStr.doctor, function (data) {
            var _a, _b;
            setDoctorData((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items);
            setTotalDoctor((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.total);
        }, function (res) { return console.log(res); });
        setPage(newPage);
    };
    var handleChangeRowsPerPage = function (event) {
        getAllDoctors(1, +event.target.value, searchStr === null || searchStr === void 0 ? void 0 : searchStr.doctor, function (data) {
            var _a, _b;
            setDoctorData((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.items);
            setTotalDoctor((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.total);
        }, function (res) { return console.log(res); });
        setRowsPerPage(+event.target.value);
    };
    var handleDeleteDoctor = function (doctorId, index) {
        deleteDoctor(String(doctorId), function (data) {
            if ((data === null || data === void 0 ? void 0 : data.success) === true) {
                var temp = __spreadArray([], doctorData, true);
                temp.splice(index, 1);
                setDoctorData(temp);
                console.log(data);
            }
        }, function (res) { return console.log(res); });
    };
    // const reformatSchedule = (scheduleObject: Schedule) => {
    //   const convertedSchedule: any = {};
    //   for (const [key, value] of Object.entries(scheduleObject)) {
    //     const reformedValue = {
    //       value_from: value?.value_from ? dayjs(`1970-01-01T${value?.value_from}`) : null,
    //       value_to: value?.value_to ? dayjs(`1970-01-01T${value?.value_to}`) : null
    //     }
    //     convertedSchedule[key] = reformedValue;
    //   }
    //   return convertedSchedule;
    // };
    var editDoctor = function (item) {
        setFormData(function (prevFormData) { return (__assign(__assign({}, prevFormData), item)); });
        setShowModal(true);
    };
    var createTableRows = function (doctors) {
        return doctors === null || doctors === void 0 ? void 0 : doctors.map(function (doctor, index) {
            var _a;
            return (_jsxs(TableRow, { children: [_jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: "Dr. ".concat(doctor === null || doctor === void 0 ? void 0 : doctor.name) })), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: doctor === null || doctor === void 0 ? void 0 : doctor.designation })), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: doctor === null || doctor === void 0 ? void 0 : doctor.speciality })), _jsx(TableCell, __assign({ sx: { textAlign: "center" } }, { children: doctor === null || doctor === void 0 ? void 0 : doctor.educational_background })), _jsx(TableCell, __assign({ sx: { textAlign: "" } }, { children: (_a = Object.keys(doctor === null || doctor === void 0 ? void 0 : doctor.schedule)) === null || _a === void 0 ? void 0 : _a.map(function (day, i) {
                            var _a, _b;
                            return (_jsxs("div", __assign({ className: "mb-3" }, { children: [_jsx(Typography, __assign({ className: " font-semibold text-gray-500" }, { children: day.charAt(0).toUpperCase() + day.slice(1) })), _jsxs(Typography, { children: ["From:", _jsx("span", __assign({ className: " font-semibold" }, { children: convertToLocalTime(((_a = doctor === null || doctor === void 0 ? void 0 : doctor.schedule[day]) === null || _a === void 0 ? void 0 : _a.value_from) || "").split(", ")[1] }))] }), _jsxs(Typography, { children: ["To:", _jsx("span", __assign({ className: " font-semibold" }, { children: convertToLocalTime(((_b = doctor === null || doctor === void 0 ? void 0 : doctor.schedule[day]) === null || _b === void 0 ? void 0 : _b.value_to) || "").split(", ")[1] }))] })] })));
                        }) })), _jsxs(TableCell, __assign({ sx: { textAlign: "center" } }, { children: [doctor === null || doctor === void 0 ? void 0 : doctor.fee, " BDT"] })), _jsxs(TableCell, __assign({ sx: { textAlign: "center" } }, { children: [_jsx(Button, __assign({ color: "info", onClick: function () { editDoctor(doctor); } }, { children: _jsx(EditIcon, {}) })), _jsx(Button, __assign({ color: "error", onClick: function () { setDeleteDoctorData({ id: doctor === null || doctor === void 0 ? void 0 : doctor.id, index: index, }); setDeleteDialog(true); } }, { children: _jsx(DeleteIcon, {}) }))] }))] }, index));
        });
    };
    return (_jsxs("main", __assign({ className: "mb-24" }, { children: [_jsx(Typography, __assign({ className: "text-white text-center text-xl bg-rose-900 shadow-2xl rounded-tl-3xl py-3" }, { children: "Doctors" })), _jsx("section", __assign({ className: "grid gap-y-4 mt-5" }, { children: _jsx("div", __assign({ className: "flex gap-4 px-4" }, { children: _jsx(Button, __assign({ onClick: function () { return setShowModal(true); }, variant: "contained", className: "rounded-lg text-lg normal-case text-white bg-sky-800 w-fit" }, { children: "Add Doctor" })) })) })), _jsxs("section", { children: [_jsx(InsertDoctor, { open: showModal, sx: { "& .MuiPaper-root": { maxWidth: "70vw", width: "50vw" } }, onClose: function () {
                            setShowModal(false);
                            setFormData(doctorFormInitialState);
                        }, formData: formData, setFormData: setFormData, reset: reset, setReset: setReset }), _jsx(ConfirmDialog, { open: deleteDialog, title: "Do you want to delete this Doctor ?", onClose: function () {
                            setDeleteDialog(false);
                            setDeleteDoctorData({
                                id: "",
                                index: 0,
                            });
                        }, onConfirm: function () {
                            handleDeleteDoctor(deleteDoctorData === null || deleteDoctorData === void 0 ? void 0 : deleteDoctorData.id, deleteDoctorData === null || deleteDoctorData === void 0 ? void 0 : deleteDoctorData.index);
                            setDeleteDoctorData({
                                id: "",
                                index: 0,
                            });
                            setDeleteDialog(false);
                        } })] }), _jsx("section", __assign({ className: "mt-5" }, { children: _jsx(GenericTable, { columns: columns, data: createTableRows(doctorData), total: totalDoctor, rowsPerPage: rowsPerPage, page: page, onChangePage: handleChangePage, onChangeRowsPerPage: handleChangeRowsPerPage }) }))] })));
};
export default Doctors;

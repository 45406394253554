var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import ProductCard from '../../../../Components/ProductCard';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
var HighlightedCard = function (props) {
    var _a = props.message, message = _a === void 0 ? "" : _a, _b = props.data, data = _b === void 0 ? [] : _b, _c = props.allWishListId, allWishListId = _c === void 0 ? [] : _c, reset = props.reset, setReset = props.setReset;
    var navigate = useNavigate();
    var slideLeft = function () {
        var slider = document.getElementById('slider');
        if (slider) {
            slider.scrollLeft = slider.scrollLeft - 500;
        }
    };
    var slideRight = function () {
        var slider = document.getElementById('slider');
        if (slider) {
            slider.scrollLeft = slider.scrollLeft + 500;
        }
    };
    return (_jsxs("div", __assign({ className: " bg-gray-50 pt-6 md:px-6 lg:px-12 mx-6 rounded-3xl relative pb-6" }, { children: [_jsxs("section", { children: [_jsx(Typography, __assign({ variant: 'h4', className: "absolute mt-[-3rem] font-alegreya" }, { children: message })), _jsx(Button, __assign({ onClick: function () { return navigate('/product'); }, size: 'large', className: "bg-transparent text-gray-700 mb-8" }, { children: _jsx("u", { children: "Shop Now" }) }))] }), _jsxs("section", __assign({ className: "flex items-center overflow-hidden" }, { children: [_jsx(Typography, { children: _jsx(MdChevronLeft, { className: 'opacity-50 cursor-pointer hover:opacity-100', onClick: slideLeft, size: 40 }) }), _jsx("style", { children: "\n          .hide-scrollbar::-webkit-scrollbar {\n            display: none;\n          }\n\n          .hide-scrollbar {\n            scrollbar-width: thin;\n            scrollbar-color: transparent transparent;\n          }\n\n        " }), _jsx("div", __assign({ id: 'slider', className: " overflow-x-scroll scroll-smooth hide-scrollbar" }, { children: _jsx("div", __assign({ className: "flex items-center" }, { children: data.length > 0 && data.map(function (item, id) { return (_jsx("div", __assign({ className: "flex-none w-72" }, { children: _jsx(ProductCard, { item: item, inWishlist: allWishListId.some(function (wishlistId) { return wishlistId === (item === null || item === void 0 ? void 0 : item.id) || (item === null || item === void 0 ? void 0 : item._id); }), reset: reset, setReset: setReset }) }), id)); }) })) })), _jsx(Typography, { children: _jsx(MdChevronRight, { className: 'opacity-50 cursor-pointer hover:opacity-100', onClick: slideRight, size: 40 }) })] }))] })));
};
export default HighlightedCard;

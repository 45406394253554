var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Menu } from '@mui/material';
import { useSelector } from 'react-redux';
import OrderSummary from '../OrderSummary';
var FloatingCart = function (props) {
    var open = props.open, anchorEl = props.anchorEl, handleClose = props.handleClose;
    var myCart = useSelector(function (state) { return state.cartState; });
    var navigate = useNavigate();
    var _a = useState(0), totalPrice = _a[0], setTotalPrice = _a[1];
    var _b = useState({}), finalCart = _b[0], setFinalCart = _b[1];
    var header = (_jsxs("section", __assign({ style: {
            display: "flex",
            gap: "0 0.5rem",
            backgroundColor: "black",
            color: "white",
            padding: "0.5rem 1rem"
        } }, { children: [_jsx(Typography, __assign({ variant: 'h6' }, { children: "Grand Total: " })), _jsx(Typography, __assign({ variant: 'h6' }, { children: _jsxs("b", { children: ["\u09F3 ", totalPrice] }) }))] })));
    useEffect(function () {
        var newCart = Object.entries(myCart).reduce(function (cart, _a) {
            var key = _a[0], value = _a[1];
            if (value.isCheckout) {
                cart[key] = value;
            }
            return cart;
        }, {});
        setFinalCart(newCart);
    }, [myCart]);
    useEffect(function () {
        var newTotal = Object.keys(myCart).reduce(function (total, key) {
            var _a;
            var price = ((_a = myCart[key]) === null || _a === void 0 ? void 0 : _a.subtotal) || 0;
            return total + price;
        }, 0);
        setTotalPrice(newTotal);
    }, [myCart]);
    return (_jsxs(Menu, __assign({ open: open, anchorEl: anchorEl, onClose: handleClose, MenuListProps: { disablePadding: true }, disableScrollLock: true }, { children: [_jsx(OrderSummary, { header: header, cart: myCart }), _jsx(Typography, __assign({ variant: 'h6', width: "100%", textAlign: "center", sx: { backgroundColor: "rgb(229 231 235)", padding: "0.5rem 0", cursor: "pointer" }, onClick: function () {
                    handleClose();
                    navigate("/shopping_cart");
                } }, { children: "View Cart" })), _jsx(Typography, __assign({ variant: 'h6', width: "100%", textAlign: "center", sx: { backgroundColor: "#881337", color: "white", padding: "0.5rem 0", cursor: "pointer" }, onClick: function () {
                    handleClose();
                    navigate("/order");
                } }, { children: "Checkout" }))] })));
};
export default FloatingCart;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { AreaChart, Area, XAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
var data = [
    { name: "January", Total: 1200 },
    { name: "February", Total: 2100 },
    { name: "March", Total: 800 },
    { name: "April", Total: 1600 },
    { name: "May", Total: 900 },
    { name: "June", Total: 1700 },
];
var Featured = function () {
    return (_jsxs("div", __assign({ className: "p-2  w-full shadow-xl  rounded-xl " }, { children: [_jsx(Typography, __assign({ variant: 'h6', className: ' mb-3' }, { children: " Last 6 Months (Revenue)" })), _jsx(ResponsiveContainer, __assign({ width: "99%", height: 500 }, { children: _jsxs(AreaChart, __assign({ width: 730, height: 250, data: data, margin: { top: 10, right: 30, left: 0, bottom: 0 } }, { children: [_jsx("defs", { children: _jsxs("linearGradient", __assign({ id: "total", x1: "0", y1: "0", x2: "0", y2: "1" }, { children: [_jsx("stop", { offset: "5%", stopColor: "#8884d8", stopOpacity: 0.8 }), _jsx("stop", { offset: "95%", stopColor: "#8884d8", stopOpacity: 0 })] })) }), _jsx(XAxis, { dataKey: "name", stroke: 'gray' }), _jsx(CartesianGrid, { strokeDasharray: "3 3", stroke: 'lightgray' }), _jsx(Tooltip, {}), _jsx(Area, { type: "monotone", dataKey: "Total", stroke: "#8884d8", fillOpacity: 1, fill: "url(#total)" })] })) }))] })));
};
export default Featured;
